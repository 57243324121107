import { Component, OnInit } from '@angular/core';
import { AccountSettingsService } from 'src/app/services/account-settings/account-settings.service';

// Services
import { AppHeaderService } from 'src/app/services/app-header/app-header.service';
import { BillingService } from 'src/app/services/billing/billing.service';
import { BrowserNotificationService } from 'src/app/services/browser-notification/browser-notification.service';
import { LanguageChangeService } from 'src/app/services/language-change/language-change.service';
import { MobileService } from 'src/app/services/mobile/mobile.service';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: 'app-header.component.html',
  styleUrls: ['app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {

  constructor(
    public accountSettings: AccountSettingsService,
    public appHeader: AppHeaderService,
    public billing: BillingService,
    public languageChangeService: LanguageChangeService,
    public mobile: MobileService,
    public organization: OrganizationService,
    public profile: ProfileService,
    public user: UserService,
    private browserNotification: BrowserNotificationService,
  ) {}

  async ngOnInit() {
    // Only request permissions if the user is logged in
    // And we are on a page where the header is visible
    // This prevents the browser from asking for permission
    // When already potentially asking permissions for mic/camera in a room.
    await this.user.isReady();
    if (!this.user.user.isAnonymous && this.appHeader.isHeaderVisible) {
      this.browserNotification.requestPermission();
    }
  }
}
