import { Injectable } from '@angular/core';

// Services
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ContactListService {

  public contacts = [];
  public contactsType = [];
  public orgMemberData = [];
  public orgMembers = [];

  public loading: boolean;
  public activeNav: string;
  public filteredContacts: Array<any> = undefined;
  public adminUser: boolean;
  public memberUser: boolean;
  public searchValue: string;

  constructor(
    private localStorage: LocalStorageService,
  ) {}

  // TODO - Document possible value for `contactType`
  // And type the parameter
  public setContactType(contactType: string) {
    this.loading = false;
    this.activeNav = contactType;
    this.localStorage.setItem('showType', contactType);
    if (contactType === 'crewdle') {
      this.contactsType = this.orgMembers;
      this.resetSearch();
      if (this.searchValue) {
        this.search(null, this.searchValue);
      }
    } else {
      this.contactsType = this.contacts;
      this.resetSearch();
      if (this.searchValue) {
        this.search(null, this.searchValue);
      }
    }
  }

  public resetSearch() {
    this.filteredContacts = undefined;
    this.adminUser = false;
    this.memberUser = false;
  }

  public search(event, value) {
    this.resetSearch();
    const searchTerm = value || event?.target.value.toLowerCase();
    this.searchValue = value || event?.target.value;
    this.filteredContacts = this.contactsType.filter(
      (contact) => contact.name.trim().toLowerCase().indexOf(searchTerm.trim()) > -1
    );
    this.adminUser = !this.filteredContacts.find(
      (data) => this.getMemberRole(data.id) === 'admin'
    );
    this.memberUser = !this.filteredContacts.find(
      (data) => this.getMemberRole(data.id) === 'member'
    );
  }

  public getMemberRole(userId) {
    const memberData = this.orgMemberData.find((data) => data.id === userId);
    if (memberData) {
      return memberData.role;
    }
  }

}
