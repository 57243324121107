import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'firebase/auth';
import { serverTimestamp, Unsubscribe } from 'firebase/firestore';
import { environment } from 'src/environments/environment';

// Models
import { UserData } from 'src/app/models/user-data/user-data';

// Services
import { AnalyticEventService } from 'src/app/services/analytic-event/analytic-event.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { LoggingChannels, LoggingService } from 'src/app/services/logging/logging.service';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { IntervalService } from '../interval/interval.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { OnlineStatusService } from 'src/app/services/online-status/online-status.service';
import { CelloService } from '../cello/cello.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public loading: boolean;
  public user: User;
  public data: UserData;
  private dataSub: Unsubscribe;

  constructor(
    private analytics: AnalyticEventService,
    private onlineStatus: OnlineStatusService,
    private organization: OrganizationService,
    private translate: TranslateService,
    private router: Router,
    private logging: LoggingService,
    private firebase: FirebaseService,
    private interval: IntervalService,
    private notification: NotificationService,
    private cello: CelloService,
  ) {
    this.loading = true;
    this.loadAuthUser();
  }

  public async isReady(): Promise<boolean> {
    if (this.user && this.data) {
      return true;
    }
    await this.interval.sleep();
    return this.isReady();
  }

  public logout(url?: string) {
    const organizationBaseUrl = this.organization.data?.marketplace?.baseUrl;
    this.organization.unloadOrganization();
    this.unloadUser();
    this.firebase.auth.signOut().then(() => {
      if (url) {
        this.router.navigate([url]);
      } else if (organizationBaseUrl) {
        window.location.href = organizationBaseUrl;
      } else {
        // Temporary fix to force services to clear their state by forcing a reload
        window.location.href = '/';
      }
    });
  }

  public eraseNewSubscription() {
    return this.firebase.firestore.docUpdate(`users/${this.user.uid}`, { newSubscription: this.firebase.firestore.deleteField() });
  }

  public eraseNeedOrganization() {
    return this.firebase.firestore.docUpdate(`users/${this.user.uid}`, { needOrganization: this.firebase.firestore.deleteField() });
  }

  public updateLastOnline(): Promise<void> {
    const isInAMeeting = this.router.url.includes('/room/');
    return this.onlineStatus.updateOnlineStatus(this.user.uid, !isInAMeeting);
  }

  private unloadUser() {
    this.data = null;
    this.user = null;
    if (this.dataSub) {
      this.dataSub();
      this.dataSub = null;
    }

    this.interval.clearInterval('update-user-last-online');
  }

  private loadAuthUser() {
    const sub = this.firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.logging.log('UserService: Loaded auth user', LoggingChannels.Authentication, this.user.uid);

        user.getIdTokenResult().then((result) => {
          if ((new Date().getTime() / 1000 - new Date(result.issuedAtTime).getTime() / 1000) > 3600) {
            this.notification.display('common.errors.time_is_off', 'danger');
            sub();
            this.router.navigate(['/support']);
          }
        });

        this.cello.boot();

        this.loadUserData();

        this.firebase.functions.call('subscribeEmail', {
          email: user.email,
          name: user.displayName,
          language: this.translate.currentLang,
          uid: user.uid,
          created_at: new Date(user.metadata.creationTime).getTime() / 1000,
          app_version: environment.version,
        }).catch(() => {});
      } else {
        this.loading = false;
        this.cello.shutdown();
      }
    }, () => {
      this.loading = false;
      this.cello.shutdown();
    });
  }

  private fillValue(value, fallback) {
    if (value) {
      return value;
    }

    return fallback;
  }

  private loadUserData() {
    if (this.dataSub) {
      this.dataSub();
      this.dataSub = null;
    }

    this.dataSub = this.firebase.firestore.docListen(`users/${this.user.uid}`, (userDoc) => {
      this.logging.log('UserService: Loaded user data', LoggingChannels.Authentication, this.user.uid, userDoc.data(), userDoc.exists());
      if (userDoc.exists()) {
        const userData: any = userDoc.data();
        // Sometimes, the returned document is missing all the user data, but still exists.
        // userData.language should always be set on a valid user document, so we make sure it is
        // before we set the data on the user.
        if (userData && userData.language) {
          this.data = new UserData();
          this.data.language = this.fillValue(userData.language, 'en');
          this.data.name = this.fillValue(userData.name, null);
          this.data.organization = this.fillValue(userData.organization, null);
          this.data.roomId = this.fillValue(userData.roomId, null);
          this.data.setEmail(userData.email);
          this.data.usageTime = this.fillValue(userData.usageTime, 0);
          this.data.needOrganization = this.fillValue(userData.needOrganization, false);
          this.data.newSubscription = this.fillValue(userData.newSubscription, false);
          this.data.subscription = this.fillValue(userData.subscription, null);
          this.data.status = this.fillValue(userData.activity?.status, null);
        }

        this.loading = false;

        if (this.data && this.data.organization) {
          this.organization.loadOrganization(this.user, this.data.organization);
        } else {
          this.organization.loading = false;
        }
      } else {
        const userFillingData = {
          name: this.user.displayName || ' ',
          email: this.user.email || '',
          language: this.translate.currentLang || 'en',
          resetOn: serverTimestamp(),
        };

        const isNewUser = Date.now() - new Date(this.user.metadata.creationTime).getTime() > 60 * 1000;
        const doesNeedLogging = !this.user.isAnonymous && !isNewUser;
        if (!doesNeedLogging) {
          delete userFillingData.resetOn;
        }

        this.firebase.firestore.docSet(`users/${this.user.uid}`, userFillingData, { merge: true });

        // Don't log an error if the user is anonymous
        // Or the user was created less than a minute ago
        // We do this to prevent logging cases where this is not an error
        if (!doesNeedLogging) {
          return;
        }

        this.analytics.logEvent('user_data_reset_bug');
        this.logging.error('UserService: USER_DATA_ERASED_BUG - Data for user not found', this.user,
                            'USER_DATA_ERASED_BUG - User Filling Data provided', userFillingData,
                            'USER_DATA_ERASED_BUG - User Doc retrieved from firebase', userDoc);
      }

    }, (err) => {
      this.logging.log('UserService: Error loading user data', LoggingChannels.Authentication, {
        userId: this.user.uid,
        err,
      });
      this.loading = false;
    });

    this.startOnlineInterval();
  }

  private async startOnlineInterval() {
    this.interval.setInterval('update-user-last-online', this.updateLastOnline.bind(this), 30 * 1000);
    return await this.updateLastOnline();
  }
}
