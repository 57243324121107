import { Injectable } from '@angular/core';
import { arrayRemove, DocumentData, DocumentReference, Transaction } from 'firebase/firestore';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { Participant } from '../models';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { ContactsService } from '../../contacts/contacts.service';
import { UserService } from '../../user/user.service';
import { documentId, where } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class MessagingParticipantService {
  constructor(
    private contactsService: ContactsService,
    private firebase: FirebaseService,
    private logging: LoggingService,
    private user: UserService,
  ) {}

  public async fetchParticipants(conversationId: string): Promise<Participant[]> {
    try {
      const participantsDoc = await this.firebase.firestore.collectionGet(
        `conversations/${conversationId}/participants`
      );

      const participants = participantsDoc.docs.map(doc =>
        this.mapParticipant(doc.id, doc.data())
      );

      return participants;
    } catch (error) {
      this.logging.error(
        'ParticipantService: Fetching participants',
        error,
        conversationId
      );

      throw error;
    }
  }

  public async fetchParticipantsWithIds(conversationId: string, userIds: string[], participants: Participant[] = []): Promise<Participant[]> {
    const currentIds = userIds.slice(0, 10);
    const remainingIds = userIds.slice(10);
    try {
      const querySnapshot = await this.firebase.firestore.collectionGet(
        `conversations/${conversationId}/participants/`,
        where(documentId(), 'in', currentIds),
      );

      const newParticipants = querySnapshot.docs.map(doc => this.mapParticipant(doc.id, doc.data()));

      if (remainingIds.length > 0) {
        return this.fetchParticipantsWithIds(conversationId, remainingIds, [...participants, ...newParticipants]);
      }

      return [...participants, ...newParticipants];
    } catch (error) {
      this.logging.error(
        'ParticipantService: Fetching participant',
        error,
        conversationId,
        userIds
      );

      throw error;
    }
  }

  public async fetchParticipant(conversationId: string, userId: string): Promise<Participant> {
    try {
      const doc = await this.firebase.firestore.docGet(
        `conversations/${conversationId}/participants/${userId}`
      );

      const participant = this.mapParticipant(doc.id, doc.data());

      return participant;
    } catch (error) {
      this.logging.error(
        'ParticipantService: Fetching participant',
        error,
        conversationId,
        userId
      );

      throw error;
    }
  }

  public async addParticipants(
    conversationId: string,
    participants: Participant[],
    updateUserIdsOnConversation = false
  ): Promise<void> {
    try {
      const contacts = await this.contactsService.contactsLoaded;
      participants.forEach((participant) => {
        if (this.user.user.uid === participant.userId) {
          return;
        }
        const name = contacts.find((contact) => contact.id === participant.userId)?.name;
        participant.name = name;
      });

      await this.firebase.firestore.runTransaction(async (transaction) => {
        const [existingParticipants, conversationRef] = updateUserIdsOnConversation
          ? await this.getExistingParticipants(transaction, conversationId)
          : [];

        const participantRefs = participants.map((participant) =>
          this.firebase.firestore.doc(
            `conversations/${conversationId}/participants/${participant.userId}`
          )
                                                );

        participantRefs.forEach((participantRef, index) => {
          const participant = participants[index];
          transaction.set(participantRef, participant);
        });

        if (updateUserIdsOnConversation) {
          const participantsArray = [...existingParticipants, ...participants.map(participant => participant.userId)];
          transaction.update(conversationRef, { userIds: participantsArray });
        }
      });
    } catch (error) {
      this.logging.error(
        'ParticipantService: Adding participants',
        error,
        conversationId,
        participants
      );
      throw error;
    }
  }

  public async removeParticipants(conversationId: string, userIds: string[], updateUserIdsOnConversation = false): Promise<void> {
    try {
      await this.firebase.firestore.runTransaction(async (transaction) => {
        const [existingUserIds, conversationRef] = updateUserIdsOnConversation
          ? await this.getExistingParticipants(transaction, conversationId)
          : [];



        const participantRefs = userIds.map((userId) =>
          this.firebase.firestore.doc(`conversations/${conversationId}/participants/${userId}`)
                                           );

        participantRefs.forEach((participantRef) => {
          transaction.delete(participantRef);
        });

        if (updateUserIdsOnConversation) {
          const updatedUserIds = existingUserIds.filter((id) => !userIds.includes(id));
          transaction.update(conversationRef, { userIds: updatedUserIds });
        }
      });
    } catch (error) {
      this.logging.error(
        'ParticipantService: Removing participants',
        error,
        conversationId,
        userIds
      );
      throw error;
    }
  }

  public async removeParticipant(conversationId: string, userId: string): Promise<void> {
    try {
      await this.firebase.firestore.runTransaction(async (transaction) => {
        const conversationRef = this.firebase.firestore.doc(`conversations/${conversationId}`);
        const participantRef = this.firebase.firestore.doc(`conversations/${conversationId}/participants/${userId}`);

        transaction.update(conversationRef, {
          userIds: arrayRemove(userId),
        });

        transaction.delete(participantRef);
      });
    } catch (error) {
      this.logging.error('MessagingService: Removing participant', error, conversationId, userId);
      throw error;
    }
  }

  public async updateParticipant(conversationId: string, userId: string, participant: Partial<Participant>): Promise<void> {
    const firebaseProps = ['lastMessageRead', 'isTyping'];
    const participantUpdater = Object.keys(participant).filter(key => firebaseProps.includes(key)).reduce((noNullPropsObject, key) =>
      participant[key] != null ? {...noNullPropsObject, [key]: participant[key]} : noNullPropsObject, {});
    this.firebase.firestore.docUpdate(`conversations/${conversationId}/participants/${userId}`, participantUpdater);
  }

  private mapParticipant(id: string, participantData: DocumentData): Participant {
    return {
      userId: id,
      lastMessageRead: participantData.lastMessageRead || null,
      isTyping: participantData.isTyping || false,
      name: participantData.name || null,
    };
  }

  private async getExistingParticipants(transaction: Transaction, conversationId: string): Promise<[string[], DocumentReference]> {
    const conversationRef = this.firebase.firestore.doc(`conversations/${conversationId}`);
    const existingParticipantsDoc = await transaction.get(conversationRef);

    return [
      existingParticipantsDoc.data()?.participants as string[] || [],
      conversationRef,
    ];
  }
}
