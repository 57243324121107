import { Injectable } from '@angular/core';

import { FeatureName, SupportService } from '../support/support.service';
import { CallStatsService } from '../../services/call-stats/call-stats.service';
import { MicStream } from '../../models/mic-stream/mic-stream';

import { Participant } from '../../models/participant/participant';
import { AnalyticEventService } from '../analytic-event/analytic-event.service';
import { LoggingChannels, LoggingService } from '../logging/logging.service';

import { IssueDetectionService } from 'src/app/services/issue-detection/issue-detection.service';
import { CallIssueType } from 'src/app/models/call-issue/call-issue';
import { ParticipantsListService } from '../participants-list/participants-list.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { IssueRemediationService } from '../issue-remediation/issue-remediation.service';
import { VirtualBackgroundService } from '../virtual-background/virtual-background.service';
import { NotificationService } from '../notification/notification.service';
import { Subscription } from 'rxjs';
import { VolumeStreamEvents } from 'src/app/models/volume-stream-events/volume-stream-events';

declare global {
  interface HTMLVideoElement {
    setSinkId: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class StreamService {
  public fullStream: MediaStream;
  public myStream: MediaStream;
  public micStream: MicStream;
  public mainStream: MediaStream;
  public shareVideoTrack: MediaStreamTrack;
  public shareAudioTrack: MediaStreamTrack;
  public shareLoading: boolean;
  public videoInputId: string;
  public audioInputId: string;
  public audioOutputId: string;
  public audioSwitch: boolean;
  public videoSwitch: boolean;
  public sharing: boolean;
  public mainStreamId: string;
  public streamLoaded: boolean;
  public pinned: string;
  public hasVideoDevice: boolean;
  public hasAudioDevice: boolean;
  public videoMissing = false;
  public talkingOnMute = false;

  private userId: string;
  private roomId: string;
  private publishCb: CallableFunction;
  private unpublishAllCb: CallableFunction;
  private unpublishSharingCb: CallableFunction;
  private mediaStreamSource: MediaStreamAudioSourceNode;
  private makingNoise: number;
  private volumeSubscription: Subscription;
  private timeRefresh: number;
  private handlerId = 'stream-service';

  constructor(
    private support: SupportService,
    private callstats: CallStatsService,
    private analytics: AnalyticEventService,
    private logging: LoggingService,
    private issueDetection: IssueDetectionService,
    private issueRemediation: IssueRemediationService,
    private participantsList: ParticipantsListService,
    private localStorage: LocalStorageService,
    private background: VirtualBackgroundService,
    private notification: NotificationService,
  ) {}

  public startStream(
    userId: string,
    roomId: string,
    publishCb: CallableFunction,
    unpublishAllCb: CallableFunction,
    unpublishSharingCb: CallableFunction
  ): void {
    this.userId = userId;
    this.roomId = roomId;
    this.publishCb = publishCb;
    this.unpublishAllCb = unpublishAllCb;
    this.unpublishSharingCb = unpublishSharingCb;

    this.fullStream = new MediaStream();
    this.myStream = new MediaStream();
    this.micStream = new MicStream();
    this.mainStream = new MediaStream();

    this.videoSwitch = true;
    this.audioSwitch = true;
    this.streamLoaded = null;

    this.shareLoading = false;
    this.sharing = false;
    this.pinned = null;
    this.timeRefresh = Date.now();
  }

  public closeStream(): void {
    this.stopVideo();
    if (this.fullStream) {
      delete this.fullStream;
      this.fullStream = null;
    }
    if (this.micStream) {
      delete this.micStream;
      this.micStream = null;
    }
    if (this.mainStream) {
      delete this.mainStream;
      this.mainStream = null;
    }
    this.mediaStreamSource = null;
  }

  public loadMyVolume() {
    if (this.support.isFeatureSupported(FeatureName.AudioVolume) && this.micStream) {
      this.micStream.load();
      this.micStream.subscribeHandler(this.handlerId, (event) => this.handleVolumeStreamEvents(event));
      this.subscribeToVolumeObserver();
    }
  }

  public setupMyVolume() {
    this.loadMyVolume();
    this.connectVolumeSource();
  }

  public unloadMyVolume(): void {
    this.disconnectVolumeSource();
    if (this.micStream) {
      this.clearSubscription();
      this.micStream.unsubscribeHandler(this.handlerId);
      this.micStream.unload();
    }
  }

  public refreshVolume(id: string, volume: number, timestamp: number, remoteTimeMakingNoise?: number) {
    const collaborationMode = document.getElementById('presentation-video')?.hidden;
    const timeNow = Date.now();
    const participant = this.participantsList.participants[id];

    if (!participant) {
      return;
    }

    participant.volume = volume;

    if (this.userId !== id) {
      participant.lastSpokeTimestamp = timestamp;
      participant.timeMakingNoise = remoteTimeMakingNoise;
      if (participant.timeMakingNoise > 1) {
        participant.isActive = true;
      }
      if (participant.timeMakingNoise === 0) {
        participant.isActive = false;
      }
    } else {
      if (volume > 0.01) {
        participant.stopMakingNoise = timeNow;
        participant.timeMakingNoise += 1;
        if (participant.timeMakingNoise > 1) {
          participant.lastSpokeTimestamp = timestamp;
          participant.isActive = true;
        }
      } else if (volume < 0.005) {
        if ((timeNow - participant.stopMakingNoise) > 1500) {
          participant.timeMakingNoise = 0;
          participant.isActive = false;
        }
      }
    }

    if (timeNow - this.timeRefresh > 1000 && !collaborationMode) {
      this.setMainStream();
      this.timeRefresh = timeNow;
    }
  }

  public getAudioTrack(): MediaStreamTrack {
    const audio = this.fullStream.getAudioTracks();
    if (audio.length > 0) {
      return audio[0];
    }
    return null;
  }

  public getVideoTrack(): MediaStreamTrack {
    if (this.background.isActive) {
      return this.background.getVideoTrack();
    }
    const video = this.fullStream.getVideoTracks();
    if (video.length > 0) {
      return video[0];
    }
    return null;
  }

  public async setupDefaultIO() {
    if (this.support.isFeatureSupported(FeatureName.DeviceConfig)) {
      this.audioInputId = this.localStorage.getItem('audioInputId');
      this.audioOutputId = this.localStorage.getItem('audioOutputId');
      this.videoInputId = this.localStorage.getItem('videoInputId');

      let audioInputFound: boolean = this.audioInputId ? false : true;
      let audioOutputFound: boolean = this.audioOutputId ? false : true;
      let videoInputFound: boolean = this.videoInputId ? false : true;

      const devices = await navigator.mediaDevices.enumerateDevices();
      devices.forEach((device: MediaDeviceInfo) => {
        if (device.deviceId !== '') {
          if (device.kind === 'audioinput') {
            if (this.audioInputId === device.deviceId) {
              audioInputFound = true;
            }
          }
          if (this.support.isFeatureSupported(FeatureName.AudioOutput)) {
            if (device.kind === 'audiooutput') {
              if (this.audioOutputId === device.deviceId) {
                audioOutputFound = true;
              }
            }
          }
          if (device.kind === 'videoinput') {
            if (this.videoInputId === device.deviceId) {
              videoInputFound = true;
            }
          }
        }
      });
      if (!audioInputFound) {
        this.audioInputId = null;
        this.localStorage.removeItem('audioInputId');
      }
      if (!audioOutputFound) {
        this.audioOutputId = null;
        this.localStorage.removeItem('audioOutputId');
      }
      if (!videoInputFound) {
        this.videoInputId = null;
        this.localStorage.removeItem('videoInputId');
      }
    }
  }

  public updateIO(data: any, outputCb?: boolean): void {
    if (data.data) {
      if (this.support.isFeatureSupported(FeatureName.AudioOutput)) {
        this.localStorage.setItem('audioOutputId', data.data.audioOutputId);
        this.audioOutputId = data.data.audioOutputId;
        if (outputCb !== false) { this.connectOutput(this.audioOutputId); }
      }
      if (data.data.audioInputId !== this.audioInputId) {
        this.localStorage.setItem('audioInputId', data.data.audioInputId);
        this.audioInputId = data.data.audioInputId;
      }
      if (data.data.videoInputId !== this.videoInputId) {
        this.localStorage.setItem('videoInputId', data.data.videoInputId);
        this.videoInputId = data.data.videoInputId;
      }
    }
    this.callstats.updateUser(this.roomId, this.userId);
  }

  public setOutput(id: string, outputId?: string) {
    const video = document.getElementById('video-' + id) as HTMLVideoElement;
    if (video && video.setSinkId) {
      if (outputId || this.audioOutputId) {
        video.setSinkId(outputId || this.audioOutputId).catch(() => {});
      }
    } else {
      setTimeout(() => {
        this.setOutput(id, outputId);
      }, 1000);
    }
  }

  public async reverseCamera(): Promise<void> {
    let devices = await navigator.mediaDevices.enumerateDevices();
    devices = devices.filter(device => device.kind === 'videoinput');

    if (devices.length >= 2) {
      if (!this.videoInputId) {
        this.videoInputId = devices[1].deviceId;
      } else {
        this.videoInputId =
          (this.videoInputId === devices[0].deviceId &&
            devices[1].deviceId) ||
          devices[0].deviceId;
      }
    }
  }

  public async startSharing(): Promise<void> {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          width: {max: 1920},
          frameRate: {max: 24},
        },
        audio: {
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false,
          channelCount: {ideal: 2},
          sampleRate: {ideal: 96000},
          sampleSize: {ideal: 24},
        },
      });
      if (stream) {
        stream.getTracks().forEach((track: MediaStreamTrack) => {
          if (this.shareLoading) {
            track.stop();
          } else {
            if (track.kind === 'video') {
              this.shareVideoTrack = track;
              this.callstats.shareVideoTrackId = track.id;
              const settings = track.getSettings();
              if (settings.width === 0 || settings.height === 0 || settings.frameRate === 0) {
                this.analytics.logEvent('screenshare-track-settings-issue');
              }
              this.issueRemediation.setMaxProfile(settings.width || 1280, settings.height || 720, settings.frameRate || 24, true);
              this.issueRemediation.shareVideoTrack = track;
              this.issueRemediation.startSharing();
              this.publishCb(this.shareVideoTrack);
            }
            if (track.kind === 'audio') {
              this.shareAudioTrack = track;
              this.callstats.shareAudioTrackId = track.id;
              this.issueRemediation.shareAudioTrack = track;
              this.publishCb(this.shareAudioTrack);
            }
          }
        });

        if (!this.shareLoading) {
          this.analytics.logEvent('screenshare');
          this.sharing = true;
          this.setMainStream();
        } else {
          throw new Error('already_sharing');
        }
      }
    } catch (err) {
      if (err.message !== 'already_sharing') {
        this.issueDetection.logEvent(CallIssueType.screen_sharing_missing, null, true);
      }

      // Rethrow the error to be properly dealt with in the room page
      // Otherwise, the screen share will not get closed properly
      throw err;
    }
  }

  public stopSharing(): void {
    if (this.sharing) {
      this.unpublishSharingCb();
      if (this.shareAudioTrack) {
        this.callstats.shareAudioTrackId = null;
        this.shareAudioTrack.stop();
        this.shareAudioTrack = null;
      }
      if (this.shareVideoTrack) {
        this.callstats.shareVideoTrackId = null;
        this.shareVideoTrack.stop();
        this.shareVideoTrack = null;
      }
      this.issueRemediation.stopSharing();
      this.sharing = false;
      this.setMainStream();
    }
  }

  public setSharing(track: MediaStreamTrack): void {
    if (track.kind === 'video') {
      if (this.shareVideoTrack) {
        this.shareVideoTrack = null;
        this.setMainStream();
      }
      this.shareVideoTrack = track;
      this.callstats.shareVideoTrackId = track.id;
    }
    if (track.kind === 'audio') {
      this.shareAudioTrack = track;
      this.callstats.shareAudioTrackId = track.id;
    }
    this.setMainStream();
    this.issueRemediation.setSharingProfiles();
  }

  public unsetSharing(): void {
    if (!this.sharing) {
      if (this.shareVideoTrack) {
        this.callstats.shareVideoTrackId = null;
        this.shareVideoTrack.stop();
        this.shareVideoTrack = null;
      }
      if (this.shareAudioTrack) {
        this.callstats.shareAudioTrackId = null;
        this.shareAudioTrack.stop();
        this.shareAudioTrack = null;
      }
      this.issueRemediation.stopSharing();
      this.setMainStream();
    }
  }

  public setMainStream(force?: boolean): void {
    this.setMainStreamId();

    if (!this.mainStream) {
      return;
    }

    if (!this.shareVideoTrack) {
      this.mainStream.getTracks().forEach((track: MediaStreamTrack) => {
        this.mainStream.removeTrack(track);
      });

      return;
    }

    this.mainStreamId = null;

    const oldVideo: MediaStreamTrack[] = this.mainStream.getVideoTracks();
    if (oldVideo.length > 0 && oldVideo[0].id === this.shareVideoTrack.id) {
      return;
    }

    this.logging.log('StreamService: Reload mainstream', LoggingChannels.Stream);
    this.mainStream.getTracks().forEach((track: MediaStreamTrack) => {
      this.mainStream.removeTrack(track);
    });

    this.mainStream.addTrack(this.shareVideoTrack);
    if (!this.sharing && this.shareAudioTrack) {
      this.mainStream.addTrack(this.shareAudioTrack);
    }
  }

  public stop(kind: string): void {
    if (this.fullStream) {
      if (kind === 'audio' || kind === 'audio_video') {
        this.disconnectVolumeSource();
      }
      if (this.fullStream) {
        this.fullStream.getTracks().forEach((track: MediaStreamTrack) => {
          if (track.kind === kind || kind === 'audio_video') {
            track.stop();
            this.fullStream.removeTrack(track);
          }
        });
      }
    }
    if (this.myStream) {
      this.myStream.getTracks().forEach((track: MediaStreamTrack) => {
        if (track.kind === kind || kind === 'audio_video') {
          this.myStream.removeTrack(track);
        }
      });
    }
    if (this.micStream?.stream) {
      this.micStream.stream.getTracks().forEach((track: MediaStreamTrack) => {
        if (track.kind === kind || kind === 'audio_video') {
          track.onmute = null;
          track.onended = null;
          track.stop();
          this.micStream.stream.removeTrack(track);
        }
      });
    }
    this.logging.log('StreamService: stop', LoggingChannels.Stream,
      kind, this.fullStream.getTracks(), this.myStream.getTracks(), this.micStream.stream.getTracks());
  }

  public start(kind: string): Promise<MediaStream> {
    this.logging.log('StreamService: start', LoggingChannels.Stream, kind);
    let options: MediaStreamConstraints = {};

    if (kind === 'video' || kind === 'audio_video') {
      options.video = this.getVideoOptions();
    } else {
      options.video = false;
    }
    if (kind === 'audio' || kind === 'audio_video') {
      options.audio = this.getAudioOptions();
    } else {
      options.audio = false;
    }

    return navigator.mediaDevices
      .getUserMedia(options)
      .then((stream: MediaStream) => {
        if (stream) {
          stream.getTracks().forEach((track: MediaStreamTrack) => {
            this.processTrack(track);
          });
        }
        if (kind === 'audio' || kind === 'audio_video') {
          this.connectVolumeSource();
        }

        this.streamLoaded = true;

        this.logging.log('StreamService: start success', LoggingChannels.Stream);

        return stream;
      })
      .catch((err: any) => {
        this.failedStream();
        return null;
      });
  }

  public stopVideo(): void {
    if (this.unpublishAllCb) {
      this.unpublishAllCb();
    }
    this.disconnectVolumeSource();
    if (this.fullStream) {
      this.background.stopBlur();
      this.stopStream(this.fullStream);
    }
    if (this.myStream) {
      this.stopStream(this.myStream);
    }
    if (this.micStream) {
      this.stopStream(this.micStream.stream);
    }
  }

  public async loadVideo(): Promise<void> {
    return navigator.mediaDevices.getUserMedia({
      video: this.getVideoOptions(),
      audio: this.getAudioOptions(),
    }).then(
      (stream: MediaStream) => this.processStream(stream)
    ).catch((err) => navigator.mediaDevices.enumerateDevices().then((devices) => {
      let hasVideo = false;
      let hasAudio = false;
      devices.forEach((device) => {
        if (device.deviceId !== '' && device.kind === 'videoinput') {
          hasVideo = true;
        }
        if (device.deviceId !== '' && device.kind === 'audioinput') {
          hasAudio = true;
        }
      });
      if (hasVideo || hasAudio) {
        if (!hasVideo) {
          this.mediaDeviceMissing('video-missing');
          this.logging.log('StreamService: failed to load video', LoggingChannels.Stream, err);
          this.issueDetection.logEvent(CallIssueType.video_missing, null, true);
        }
        if (!hasAudio) {
          this.mediaDeviceMissing('audio-missing');
          this.logging.log('StreamService: failed to load audio', LoggingChannels.Stream, err);
          this.issueDetection.logEvent(CallIssueType.audio_missing, null, true);
        }
        return navigator.mediaDevices.getUserMedia({
          video: hasVideo && this.getVideoOptions() || false,
          audio: hasAudio && this.getAudioOptions() || false,
        }).then((stream: MediaStream) => {
          this.processStream(stream);
        }).catch((newErr) => {
          this.failedStream(newErr);
        });
      } else {
        this.failedStream();
      }
    }));
  }

  public replaceTrack(kind: string) {
    let track: MediaStreamTrack;
    if (kind === 'video') {
      track = this.getVideoTrack();
    }
    if (kind === 'audio') {
      track = this.getAudioTrack();
    }
    if (track) {
      this.participantsList.participantsArray.forEach((participant) => {
        let found = false;
        participant.pc.getSenders().forEach((sender) => {
          if (sender.track && sender.track.kind === kind) {
            if (
              (kind === 'video' && sender.track.id !== this.shareVideoTrack?.id) ||
              (kind === 'audio' && sender.track.id !== this.shareAudioTrack?.id)
            ) {
              found = true;
              sender.replaceTrack(track);
            }
          }
        });
        if (!found) {
          this.participantsList.participants[participant.id].tracks.push(participant.pc.addTrack(track, this.fullStream));
        }
      });
    }
  }

  private getVideoOptions(): MediaTrackConstraints {
    const videoOptions: MediaTrackConstraints = {
      width: { ideal: 1280, max: 1280 },
      aspectRatio: { ideal: 16 / 9 },
    };
    if (this.videoInputId) {
      videoOptions.deviceId = { ideal: this.videoInputId };
    }
    return videoOptions;
  }

  private getAudioOptions(): MediaTrackConstraints | boolean {
    let audioOptions: MediaTrackConstraints | boolean = true;
    if (this.audioInputId) {
      audioOptions = {};
      audioOptions.deviceId = { ideal: this.audioInputId };
    }
    return audioOptions;
  }

  private failedStream(err?: any): void {
    this.mediaDeviceMissing('audio-missing');
    this.mediaDeviceMissing('video-missing');
    this.logging.log('StreamService: failed to load audio/video', LoggingChannels.Stream, err);
    this.issueDetection.logEvent(CallIssueType.audio_missing, null, true);
    this.issueDetection.logEvent(CallIssueType.video_missing, null, true);
    this.callstats.logEvent(
      'Stream: load failed',
      err && err.message,
      this.roomId,
      this.userId
    );
    this.streamLoaded = false;
  }

  private processStream(stream: MediaStream): void {
    if (stream) {
      stream.getTracks().forEach((track: MediaStreamTrack) => {
        this.processTrack(track);
      });
    }

    this.setMainStream();

    this.streamLoaded = true;

    if (this.fullStream.getAudioTracks().length > 0) {
      this.setupMyVolume();
    }

    this.logging.log('StreamService: loadVideo success', LoggingChannels.Stream);
  }

  private processTrack(track: MediaStreamTrack): void {
    if (track.kind === 'audio') {
      const clonedTrack = this.createClonedTrack(track);
      if (this.audioSwitch) {
        clonedTrack.enabled = true;
      } else {
        clonedTrack.enabled = false;
      }
      this.fullStream.getAudioTracks().forEach((audioTrack: MediaStreamTrack) => {
        audioTrack.stop();
        this.fullStream.removeTrack(audioTrack);
      });
      this.fullStream.addTrack(clonedTrack);
      this.micStream.stream.getAudioTracks().forEach((audioTrack: MediaStreamTrack) => {
        audioTrack.stop();
        this.micStream.stream.removeTrack(audioTrack);
      });
      this.micStream.stream.addTrack(track);
    }
    if (track.kind === 'video') {
      if (this.videoSwitch) {
        const settings = track.getSettings();
        if (settings.width === 0 || settings.height === 0 || settings.frameRate === 0) {
          this.analytics.logEvent('camera-track-settings-issue');
        }
        this.issueRemediation.setMaxProfile(settings.width || 1280, settings.height || 720, 24);
        track.enabled = true;
      } else {
        track.enabled = false;
      }
      this.fullStream.getVideoTracks().forEach((videoTrack: MediaStreamTrack) => {
        videoTrack.stop();
        this.fullStream.removeTrack(videoTrack);
      });
      this.fullStream.addTrack(track);
      this.myStream.getVideoTracks().forEach((videoTrack: MediaStreamTrack) => {
        videoTrack.stop();
        this.myStream.removeTrack(videoTrack);
      });
      this.myStream.addTrack(track);
    }
    track.onmute = () => {
      this.logging.log('StreamService: track muted', LoggingChannels.Stream);
      this.callstats.logEvent(
        'Stream: track muted',
        { kind: track.kind },
        this.roomId,
        this.userId
      );
    };
    track.onended = () => {
      this.logging.log('StreamService: track ended', LoggingChannels.Stream);
      this.callstats.logEvent(
        'Stream: track ended',
        { kind: track.kind },
        this.roomId,
        this.userId
      );
    };
    try {
      this.callstats.logEvent(
        'Settings: ' + track.kind,
        track.getSettings(),
        this.roomId,
        this.userId
      );
    } catch (err) {}
  }

  private connectVolumeSource(): void {
    if (this.support.isFeatureSupported(FeatureName.AudioVolume)) {
      this.micStream.connect();
    }
  }

  private disconnectVolumeSource(): void {
    if (this.support.isFeatureSupported(FeatureName.AudioVolume) && this.micStream) {
      this.micStream.disconnect();
    }
    if (this.mediaStreamSource) {
      this.mediaStreamSource.disconnect();
      this.mediaStreamSource = null;
    }
  }

  private stopStream(stream: MediaStream): void {
    this.logging.log('StreamService: stopStream', LoggingChannels.Stream);
    if (stream) {
      stream.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
        stream.removeTrack(track);
      });
    }
  }

  private createClonedTrack(track: MediaStreamTrack): MediaStreamTrack {
    return track.clone();
  }

  private connectOutput(outputId: string) {
    const participants = this.participantsList.participantsArray;
    participants.forEach((participant) => {
      this.setOutput(participant.id, outputId);
    });
  }

  private setMainStreamId() {
    let lastSpokeTimestamp = 0;
    if (!this.mainStreamId) {
      this.mainStreamId = this.userId;
    }
    if (this.mainStreamId === this.userId || !this.participantsList.participants[this.mainStreamId]?.isActive) {
      const participants: Participant[] = this.participantsList.participantsArray;
      participants.forEach((participant: Participant) => {
        if (
          ((participant.isActive || this.mainStreamId === this.userId) &&
          participant.lastSpokeTimestamp >= lastSpokeTimestamp &&
          participant.id !== this.userId) ||
          participant.id === this.pinned
        ) {
          lastSpokeTimestamp = participant.lastSpokeTimestamp;
          this.mainStreamId = participant.id;
        }
      });
    }
    if (!this.participantsList.participants[this.mainStreamId]) {
      this.mainStreamId = this.userId;
    }
  }

  private mediaDeviceMissing(type) {
    this.logging.log('StreamService: missing func got called', LoggingChannels.Stream, type);
    if (type === 'video-missing') {
      this.videoMissing = true;
      this.cameraNotFoundState();
    } else if (type === 'audio-missing') {
      this.audioSwitch = false;
    }
  }

  private cameraNotFoundState() {
    this.videoSwitch = false;
    this.videoMissing = true;
  }

  private onMicVolume(volume: number) {
    if (!this.audioSwitch) {
      if (volume > 0.01) {
        this.makingNoise += 1;
        if (this.makingNoise > 5) {
          this.talkingOnMute = true;
          this.notification.display('room.talking_on_mute_message', 'danger', 'top', 2000, [], 'mic-off-toast');
        }
      } else if (volume < 0.005) {
        this.talkingOnMute = false;
        this.makingNoise = 0;
      }
    } else {
      this.talkingOnMute = false;
      this.makingNoise = 0;
    }
  }

  private handleVolumeStreamEvents(event: string) {
    switch (event) {
      case VolumeStreamEvents.Connected:
        return this.subscribeToVolumeObserver();
      case VolumeStreamEvents.Disconnected:
        this.clearSubscription();
        return;
      case VolumeStreamEvents.Restarted:
        this.clearSubscription();
        return this.subscribeToVolumeObserver();
    }
  }

  private subscribeToVolumeObserver() {
    if (!this.micStream.connected) {
      return;
    }

    if (this.volumeSubscription) {
      return;
    }

    this.micStream.getVolumePromise().then(() => {
      this.volumeSubscription = this.micStream.getUnbufferedVolumeObserver().subscribe(volume => {
        try {
          this.onMicVolume(volume);
          this.refreshVolume(this.userId, this.audioSwitch && volume || 0, Date.now());
        } catch (err) {
          this.logging.error('StreamService: Error processing volume inputs', volume, err);
        }
      });
    });
  }

  private clearSubscription() {
    if (this.volumeSubscription) {
      this.volumeSubscription.unsubscribe();
      this.volumeSubscription = null;
    }
  }

}
