import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sign-up-toolbar',
  templateUrl: './sign-up-toolbar.component.html',
  styleUrls: ['./sign-up-toolbar.component.scss'],
})
export class SignUpToolbarComponent implements OnInit {
  @Input() deviceWidth: boolean;
  @Input() value: string;
  @Input() className: string;
  @Input() toolBoxClass: string;
  @Input() logoTitleClass: string;
  @Input() toolPosition: string;
  @Input() toolWidth: string;
  @Input() showBackIcon: boolean;
  @Input() iconName: string;
  @Input() iconClass: string;
  @Input() otherLogin: boolean;
  @Input() otherIcon: string;
  @Input() mobileLogo: boolean;
  @Input() logoTextClass: string;
  @Input() logoClass: string;
  @Input() showLeafIcon: boolean;
  @Input() iconContainer: string;
  @Output() iconClicked = new EventEmitter();
  constructor(public translate: TranslateService) {}

  ngOnInit() {
  }

  public clicked() {
    this.iconClicked.emit();
  }
}
