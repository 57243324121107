import { Injectable } from '@angular/core';
import { LoggingService } from '../logging/logging.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(
    private logging: LoggingService,
  ) { }

  public getItem(key: string): string {
    if (window.localStorage) {
      try {
        return window.localStorage.getItem(key);
      } catch(err) {
        this.logging.warning('LocalStorageService: getItem failed', err.message);
      }
    }
    return null;
  }

  public setItem(key: string, value: string): void {
    if (window.localStorage) {
      try {
        window.localStorage.setItem(key, value);
      } catch (err) {
        this.logging.warning('LocalStorageService: setItem failed', err.message);
      }
    }
  }

  public removeItem(key: string): void {
    if (window.localStorage) {
      try {
        window.localStorage.removeItem(key);
      } catch (err) {
        this.logging.warning('LocalStorageService: removeItem failed', err.message);
      }
    }
  }
}
