import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

interface NavItem {
  id: string;
  text: string;
}
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Input() className: string;
  @Input() activeClass: string;
  @Input() navPage: string;
  @Input() active: NavItem['id'];
  @Input() navList: NavItem[];
  @Output() navClick = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.setActiveTab();
  }

  setActiveTab() {
    if (!this.active) {
      this.active = this.getDefaultActive();
    }
  }

  public getDefaultActive() {
    return this.navList && this.navList[0].id;
  }

  public removeActiveNav() {
    this.active = null;
  }

  public clicked(id) {
    this.active = id;
    this.navClick.emit(id);
  }
}
