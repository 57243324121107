import { AudioStreamInitializer } from '../audio-stream-initializer/audio-stream-initializer';
import { VolumeStreamEvents } from 'src/app/models/volume-stream-events/volume-stream-events';
import { NotificationService } from 'src/app/services/notification/notification.service';

type Handler = { id: string; handler: CallableFunction };

export abstract class AbstractVolumeStream {
  stream: MediaStream;
  volume: number;
  loaded: boolean;
  connected: boolean;

  protected source: MediaStreamAudioSourceNode;
  protected notification: NotificationService;
  protected audioStream: AudioStreamInitializer;
  protected sampleSize?: number|null;
  protected subscriberHandlers: Handler[];

  private volumePromise: Promise<void>;
  private resolve: CallableFunction;
  private reject: CallableFunction;

  constructor(
    notification?: NotificationService,
    configurations?: any,
  ) {
    this.stream = new MediaStream();
    this.volume = 0;
    this.notification = notification;
    this.loaded = false;
    this.connected = false;
    this.subscriberHandlers = [];

    if (configurations && configurations.sampleSize) {
      this.sampleSize = configurations.sampleSize;
    }
    this.initVolumePromise();
    this.audioStream = new AudioStreamInitializer(
      this.onVolumeHandler.bind(this),
      this.stream,
      this.sampleSize,
    );

  }

  load(param?: string) {
    if (this.loaded) {
      return;
    }

    this.audioStream.load();
    this.loaded = true;
    this.initVolumePromise();
    this.emitSubcriberEvent(VolumeStreamEvents.Loaded);
  }

  unload() {
    this.audioStream.unload();
    this.loaded = false;
    this.deleteVolumePromise(true);
    this.emitSubcriberEvent(VolumeStreamEvents.Unloaded);
  }

  connect() {
    if (this.connected) {
      return;
    }

    this.audioStream.connect();
    this.connected = true;
    this.emitSubcriberEvent(VolumeStreamEvents.Connected);
  }

  disconnect() {
    this.audioStream.disconnect();
    this.connected = false;

    this.emitSubcriberEvent(VolumeStreamEvents.Disconnected);
  }

  restart() {
    this.deleteVolumePromise(true);
    if (this.loaded && this.connected) {
      this.audioStream.restart();
    }

    if (this.loaded && !this.connected) {
      this.audioStream.unload();
      this.audioStream.load();
    }

    this.initVolumePromise();
    this.emitSubcriberEvent(VolumeStreamEvents.Restarted);
  }

  // `handler` should be a function that takes one argument of type SubscriberEventType
  subscribeHandler(id: string, handler: CallableFunction) {
    this.subscriberHandlers.push({
      id,
      handler,
    });
  }

  unsubscribeHandler(id: string) {
    this.subscriberHandlers = this.subscriberHandlers.filter(handler => id !== handler.id);
  }

  emitSubcriberEvent(eventType: VolumeStreamEvents) {
    this.subscriberHandlers.forEach(({handler}) => handler(eventType));
  }

  getUnbufferedVolumeObserver() {
    return this.audioStream.getUnbufferredVolumeObserver();
  }

  getVolumePromise() {
    return this.volumePromise;
  }

  protected onVolumeHandler(volume: number, volumeInputs: number[]) {
    if (!this.resolve) {
      return;
    }

    this.resolve();

    // We want to keep the promise around for subsequent handlers to be able to fire
    // immediately when listening to the promise
    this.deleteVolumePromise(false);
  }

  protected initVolumePromise() {
    if (this.volumePromise) {
      return;
    }

    this.volumePromise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  protected deleteVolumePromise(deletePromise: boolean) {
    if (deletePromise) {
      delete this.volumePromise;
    }

    delete this.resolve;
    delete this.reject;
  }

  protected checkLogs(logsEnabled: boolean, fullLogsEnabled: boolean, service: string, avgVolume: number, volumeInputs: number[]) {
    if (!logsEnabled) {
      return;
    }
    console.log(`${service}: Receiving volume event`, avgVolume);

    if (fullLogsEnabled === true) {
      console.log(`${service}: Full volume inputs`, volumeInputs);
    }
  }
}
