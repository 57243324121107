import { Observable } from 'rxjs';
import { bufferCount, share } from 'rxjs/operators';

export class AudioStreamScriptLoader {
  stream: MediaStream;
  volume: number;
  loaded: boolean;
  connected: boolean;

  private audioContext: AudioContext;
  private script: ScriptProcessorNode;
  private volumeEventObs: Observable<any>;
  private observer: Observable<any>;
  private completeObserver: CallableFunction;
  private sampleSize?: number|null;

  private readonly DEFAULT_SAMPLE_SIZE = 5;

  constructor(
    audioContext: AudioContext,
    sampleSize?: number|null,
  ) {
    this.stream = new MediaStream();
    this.volume = 0;
    this.loaded = false;
    this.connected = false;
    this.audioContext = audioContext;
    this.sampleSize = sampleSize || this.DEFAULT_SAMPLE_SIZE;
  }

  async load(resolve, reject) {
    if (this.loaded) {
      return;
    }
    this.loaded = true;
    this.script = this.audioContext.createScriptProcessor(4096, 1, 1);
    this.volumeEventObs = new Observable((subscriber) => {

      this.script.onaudioprocess = (event) => {
        const input = event.inputBuffer.getChannelData(0);
        let sum = 0.0;
        // eslint-disable-next-line
        for (let i = 0; i < input.length; ++i) {
          sum += input[i] * input[i];
        }
        this.volume = 0.3 * this.volume + 0.7 * Math.sqrt(sum / input.length);

        subscriber.next(this.volume);
      };

      this.completeObserver = subscriber.complete.bind(subscriber);
    }).pipe(share());

    this.observer = this.volumeEventObs.pipe(
      bufferCount(this.sampleSize, this.sampleSize)
    );

    resolve('AudioStreamScriptLoader: Volume Stream Observer initialized');
  }

  connect(source, destination) {
    if (this.connected) {
      return;
    }

    this.connected = true;


    return source.connect(this.script).connect(destination);
  }

  unload() {
    if (this.script) {
      this.script.onaudioprocess = null;
      this.script = null;
      delete this.script;
    }

    if (this.volumeEventObs) {
      delete this.volumeEventObs;
    }

    this.loaded = false;
  }

  disconnect() {
    if (this.audioContext && this.script) {
      this.script.disconnect();
    }

    if (this.completeObserver) {
      this.completeObserver();
      delete this.completeObserver;
    }

    this.connected = false;
  }

  getObserver() {
    return this.observer;
  }

  getUnbufferedVolumeObserver() {
    return this.volumeEventObs;
  }

}

