import { Injectable } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggingChannels, LoggingService } from 'src/app/services/logging/logging.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { FirebaseService } from '../firebase/firebase.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageChangeService {
  public lang: string;

  constructor(
    private translate: TranslateService,
    private user: UserService,
    private logging: LoggingService,
    private localStorage: LocalStorageService,
    private firebase: FirebaseService,
  ) {}

  public changeLanguage(lang) {
    this.translate.use(lang);
    this.localStorage.setItem('lang', lang);
  }

  public async authUserChangeLanguage(lang: string) {
    this.changeLanguage(lang);
    try {
      await this.firebase.firestore.docSet(`users/${this.user.user.uid}`, {
        language: lang,
      }, { merge: true });
    } catch (err) {
      this.logging.log('LanguageService: error to set the user language ', LoggingChannels.Authentication, err);
    }
  }

  public checkUserLanguage() {
    const userLang = this.user.data?.language;
    if (userLang) {
      const appLang = this.localStorage.getItem('lang') || 'en';
      if (userLang !== appLang) {
        this.authUserChangeLanguage(appLang);
      }
    }
  }
}
