import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() value: string;
  @Input() id: string;
  @Input() className: string;
  @Input() slot: string;
  @Input() hidden: any;
  @Input() disabled: boolean;
  @Input() color: string;
  @Input() fill: string;
  @Input() dataTooltip: string;
  @Output() buttonClick = new EventEmitter();
  @Output() mouseDown = new EventEmitter();
  @Output() mouseUp = new EventEmitter();
  @Output() mouseEnter = new EventEmitter();
  @Output() mouseLeave = new EventEmitter();

  constructor() {}
  ngOnInit() {}

  public clicked($event) {
    this.buttonClick.emit($event);
  }

  public mouseDownEvent($event) {
    this.mouseDown.emit($event);
  }

  public mouseUpEvent($event) {
    this.mouseUp.emit($event);
  }

  public mouseEnterEvent($event) {
    this.mouseEnter.emit($event);
  }

  public mouseLeaveEvent($event) {
    this.mouseLeave.emit($event);
  }
}
