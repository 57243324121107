
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Models
import { UserData } from 'src/app/models/user-data/user-data';

// Services
import { ProfileService, SettingsTabs } from 'src/app/services/profile/profile.service';
import { VideoCallService } from 'src/app/services/video-call/video-call.service';
import { FeatureSwitchService } from '../feature-switch/feature-switch.service';

export enum PageTitles {
  'meet' = 'home.meet',
  'recordings' = 'home.recording',
  'contacts' = 'home.people',
  'events' = 'home.events',
  'messaging' = 'messaging.tab',
}

type Titles = PageTitles | SettingsTabs;
type Pages = 'meeting' | 'contacts' | 'recordings' | 'profile' | 'billing' | 'upgrade' | 'settings' | 'messaging' | 'events';

@Injectable({
  providedIn: 'root'
})
export class AppHeaderService {
  public isHeaderVisible = false;
  public pageTitle: Titles;
  public mainNav: {
    id: string;
    text: string;
  }[];

  public activeAccount: string;
  public showOrg = true;

  private _activeTab: this['activeTab'];

  constructor(
    private featureSwitch: FeatureSwitchService,
    private profile: ProfileService,
    private router: Router,
    private videoCallService: VideoCallService,
  ) {
    this.mainNav = [
      {
        id: 'meeting',
        text: 'home.new_room',
      },
      {
        id: 'recordings',
        text: 'home.recording',
      },
      {
        id: 'messaging',
        text: 'messaging.tab',
      },
    ];

    if (featureSwitch.largeEvents) {
      this.mainNav.splice(1, 0, {
        id: 'events',
        text: 'home.events',
      });
    }

    this.videoCallService.setupVideoCallListener();
  }

  public get activeTab(): Pages {
      return this._activeTab;
    }

  public set activeTab(
    tab: Pages,
  ) {
    this._activeTab = tab;
    if (tab === 'recordings' || tab === 'events' || tab === 'messaging') {
      this.router.navigate([`/${tab}`]);
    } else {
      this.router.navigate([`/home`], { fragment: tab });
    }
  }

  public navigateToPage(page: Pages | string) {
    switch (page) {
      case 'meeting':
        return this.navigateToMeeting();
      case 'contacts':
        return this.navigateToContacts();
      case 'recordings':
        return this.navigateToRecordings();
      case 'events':
        return this.navigateToEvents();
      case 'messaging':
        return this.navigateToMessaging();
      case 'profile':
        return this.profile.navigateToProfile();
      case 'billing':
      case 'upgrade':
        return this.profile.navigateToBillingOrUpgrade();
      default:
        return this.navigateToMeeting();
    }
  }

  public navigateToMeeting() {
    this.activeTab = 'meeting';
    this.pageTitle = PageTitles.meet;
    this.renameIconTitle();
  }

  public navigateToContacts() {
    this.activeTab = 'contacts';
    this.pageTitle = PageTitles.contacts;
    this.renameIconTitle();
  }

  public navigateToRecordings() {
    this.activeTab = 'recordings';
    this.pageTitle = PageTitles.recordings;
    this.renameIconTitle();
  }

  public navigateToEvents() {
    this.activeTab = 'events';
    this.pageTitle = PageTitles.events;
    this.renameIconTitle();
  }

  public navigateToMessaging() {
    this.activeTab = 'messaging';
    this.pageTitle = PageTitles.messaging;
    this.renameIconTitle();
  }

  public showActiveTabFromHash() {
    const tabFromHash = location.hash.replace('#', '');
    if (tabFromHash === '') {
      this.navigateToPage(this.activeTab);
    } else if (this.activeTab !== tabFromHash) {
      this.navigateToPage(tabFromHash);
    }
  }

  public renameIconTitle() {
    setTimeout(() => {
      const ionicons = document.querySelectorAll('ion-icon');
      if (ionicons?.length > 0) {
        ionicons.forEach((icon) => {
          const iconTitle = icon.shadowRoot.querySelector('.icon-inner svg title');
          if (iconTitle) {
            iconTitle.innerHTML = '';
          }
        });
      }
    }, 2000);
  }

  // TODO - This is currently unused and should be removed if it is obsolete
  public refreshAvatar(userData?: UserData) {
    if (!userData || !userData.organization) {
      this.activeAccount = 'all';
      this.showOrg = false;
    }
  }
}
