import { CallIssue } from 'src/app/models/call-issue/call-issue';
import { Rule } from 'src/app/services/issue-detection/rules/rule';
import { CallProblem, CallProblemSeverity } from 'src/app/models/call-problem/call-problem';
import { UnstableConnectionService } from 'src/app/services/issue-detection/rules/unstable-connection.service';

export class UnstableConnectionRule implements Rule {
  private unstableConnectionService: UnstableConnectionService;

  public attachEvents(unstableConnectionService: UnstableConnectionService) {
    this.unstableConnectionService = unstableConnectionService;
  }

  public run(callIssues: CallIssue[], participant: string, nbParticipants: number): CallProblem {
    if (!this.unstableConnectionService) {
      return null;
    }

    if (this.unstableConnectionService.getEvents().length > 2) {
      return new CallProblem(null, 'unstable_connection.disconnect', CallProblemSeverity.warning, true);
    }

    return null;
  }

  public clear(callProblem: CallProblem, callIssues: CallIssue[]): CallIssue[] {
    return callIssues;
  }
}
