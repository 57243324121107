import { CallIssue, CallIssueType } from 'src/app/models/call-issue/call-issue';
import { CallProblem, CallProblemSeverity } from 'src/app/models/call-problem/call-problem';
import { Rule } from './rule';

export enum BandwidthProblems {
  'bandwidth_in'      = 'bandwidth.bandwidth_in',
  'bandwidth_out'     = 'bandwidth.bandwidth_out',
  'audio_jitter_in'   = 'bandwidth.audio_jitter_in',
  'audio_jitter_out'  = 'bandwidth.audio_jitter_out',
  'video_jitter_in'   = 'bandwidth.video_jitter_in',
  'video_jitter_out'  = 'bandwidth.video_jitter_out',
  'rtt'               = 'bandwidth.rtt',
}

const BANDWIDTH_IN_THRESHOLD = 3;
const BANDWIDTH_OUT_THRESHOLD = 3;
const JITTER_THRESHOLD = 3;
const RTT_THRESHOLD = 3;

export class BandwidthRule implements Rule {
  public run(callIssues: CallIssue[], participant: string, nbParticipants: number): CallProblem {
    let nbBandwidthInIssues = 0;
    let nbBandwidthOutIssues = 0;
    let nbAudioJitterInIssues = 0;
    let nbAudioJitterOutIssues = 0;
    let nbVideoJitterInIssues = 0;
    let nbVideoJitterOutIssues = 0;
    let nbRttIssues = 0;

    callIssues
      .filter((callIssue) => callIssue.participant === participant)
      .forEach((callIssue) => {
        switch (callIssue.type) {
          case CallIssueType.bandwidth_in:
            nbBandwidthInIssues++;
            break;
          case CallIssueType.bandwidth_out:
            nbBandwidthOutIssues++;
            break;
          case CallIssueType.audio_jitter_in:
            nbAudioJitterInIssues++;
            break;
          case CallIssueType.audio_jitter_out:
            nbAudioJitterOutIssues++;
            break;
          case CallIssueType.video_jitter_in:
            nbVideoJitterInIssues++;
            break;
          case CallIssueType.video_jitter_out:
            nbVideoJitterOutIssues++;
            break;
          case CallIssueType.rtt:
            nbRttIssues++;
            break;
        }
      });

    let factor = 10 / nbParticipants;
    if (factor > 1 || factor <= 0) {
      factor = 1;
    }
    if (nbBandwidthInIssues > Math.floor(BANDWIDTH_IN_THRESHOLD * factor)) {
      return new CallProblem(participant, BandwidthProblems.bandwidth_in, CallProblemSeverity.warning);
    }
    if (nbBandwidthOutIssues > Math.floor(BANDWIDTH_OUT_THRESHOLD * factor)) {
      return new CallProblem(participant, BandwidthProblems.bandwidth_out, CallProblemSeverity.warning);
    }
    if (nbAudioJitterInIssues > Math.floor(JITTER_THRESHOLD * factor)) {
      return new CallProblem(participant, BandwidthProblems.audio_jitter_in, CallProblemSeverity.warning);
    }
    if (nbAudioJitterOutIssues > Math.floor(JITTER_THRESHOLD * factor)) {
      return new CallProblem(participant, BandwidthProblems.audio_jitter_out, CallProblemSeverity.warning);
    }
    if (nbVideoJitterInIssues > Math.floor(JITTER_THRESHOLD * factor)) {
      return new CallProblem(participant, BandwidthProblems.video_jitter_in, CallProblemSeverity.warning);
    }
    if (nbVideoJitterOutIssues > Math.floor(JITTER_THRESHOLD * factor)) {
      return new CallProblem(participant, BandwidthProblems.video_jitter_out, CallProblemSeverity.warning);
    }
    if (nbRttIssues > Math.floor(RTT_THRESHOLD * factor)) {
      return new CallProblem(participant, BandwidthProblems.rtt, CallProblemSeverity.warning);
    }

    return null;
  }

  public clear(callProblem: CallProblem, callIssues: CallIssue[]): CallIssue[] {
    let filter = [];
    switch (callProblem.message) {
      case BandwidthProblems.bandwidth_in:
        filter = [
          CallIssueType.bandwidth_in,
        ];
        break;
      case BandwidthProblems.bandwidth_out:
        filter = [
          CallIssueType.bandwidth_out,
        ];
        break;
      case BandwidthProblems.audio_jitter_in:
        filter = [
          CallIssueType.audio_jitter_in,
        ];
        break;
      case BandwidthProblems.audio_jitter_out:
        filter = [
          CallIssueType.audio_jitter_out,
        ];
        break;
      case BandwidthProblems.video_jitter_in:
        filter = [
          CallIssueType.video_jitter_in,
        ];
        break;
      case BandwidthProblems.video_jitter_out:
        filter = [
          CallIssueType.video_jitter_out,
        ];
        break;
      case BandwidthProblems.rtt:
        filter = [
          CallIssueType.rtt,
        ];
        break;
    }
    return callIssues.filter((val) => !(filter.includes(val.type) && val.participant === callProblem.participant));
  }
}
