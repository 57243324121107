import { Injectable } from '@angular/core';
import { CrewdleDataChannel } from 'src/app/models/peer-connection/crewdle-data-channel';

export const DC_TIME_THRESHOLD = 10000;

@Injectable({
  providedIn: 'root'
})
export class DataChannelService {

  dcs: { [key: string]: CrewdleDataChannel };
  counts: { [key: string]: number };
  lasts: { [key: string]: number };
  callbacks: { [key: string]: { [key: string]: CallableFunction } };

  constructor() {
    this.dcs = {};
    this.callbacks = {};
    this.counts = {};
    this.lasts = {};
  }

  public updateLast(id: string) {
    this.lasts[id] = Date.now();
  }

  public register(id: string, dc: CrewdleDataChannel) {
    this.dcs[id] = dc;
    this.updateLast(id);
    this.createCallbacks(id);

    this.dcs[id].onmessage = (ev) => {
      try {
        const messages: any[] = JSON.parse(ev.data);
        messages.forEach((message) => {
          this.processMessage(id, message);
        });
      } catch (err) {
        console.error('DataChannelService: Invalid message', ev.data, err.message);
      }
    };
  }

  public send(id: string, type: string, data?: any, retry = true) {
    this.createCount(id);
    this.counts[id]++;
    this.sendMessage(id, {
      type,
      data,
    }, 0, retry);
  }

  public registerCallback(id: string, type: string, cb: CallableFunction) {
    this.createCallbacks(id);
    this.callbacks[id][type] = cb;
  }

  private createCount(id) {
    if (!this.counts[id]) {
      this.counts[id] = 0;
    }
  }

  private createCallbacks(id) {
    if (!this.callbacks[id]) {
      this.callbacks[id] = {};
    }
  }

  private processMessage(id, message) {
    if (this.callbacks[id][message.type]) {
      this.callbacks[id][message.type](message.data);
    }
  }

  private sendMessage(id: string, message: any, count = 0, retry = true) {
    if (this.dcs[id] && this.dcs[id].readyState === 'open') {
      this.dcs[id].send(JSON.stringify([message]));
    } else {
      if (count < 10 && retry) {
        setTimeout(() => this.sendMessage(id, message, count++), 1000);
      }
    }
  }
}
