import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from './app-auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  {
    path: 'home',
    title: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'recordings',
    title: 'recordings',
    loadChildren: () => import('./pages/recordings/recordings.module').then( m => m.RecordingsPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'events',
    title: 'events',
    loadChildren: () => import('./pages/events/events.module').then( m => m.EventsPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'messaging',
    title: 'messaging',
    loadChildren: () => import('./pages/messaging/messaging.module').then( m => m.MessagingPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'messaging/conversation/:id',
    title: 'conversation',
    loadChildren: () => import('./pages/conversation/conversation.module').then( m => m.ConversationPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'messaging/contacts/:contact-type/:id',
    title: 'contact',
    loadChildren: () => import('./pages/contact-details/contact-details.module').then( m => m.ContactDetailsModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'room/:id',
    title: 'room',
    loadChildren: () => import('./pages/room/room.module').then( m => m.RoomPageModule)
  },
  {
    path: 'room/:id/join',
    title: 'join_room',
    loadChildren: () => import('./pages/join/join.module').then( m => m.JoinPageModule)
  },
  {
    path: 'support',
    title: 'support',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'support/:errorType',
    title: 'support',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'register',
    title: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule),
  },
  {
    path: 'login',
    title: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToHome }
  },
  {
    path: 'register/:lang',
    title: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule),
  },
  {
    path: 'login/:lang',
    title: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToHome }
  },
  {
    path: 'appdirect/sso',
    title: 'appdirect',
    loadChildren: () => import('./pages/appdirect-sso/appdirect-sso.module').then( m => m.AppdirectSsoPageModule)
  },
  {
    path: 'slack/authorize',
    title: 'slack',
    loadChildren: () => import('./pages/slack-authorize/slack-authorize.module').then( m => m.SlackAuthorizePageModule)
  },
  {
    path: 'slack/login',
    title: 'slack',
    loadChildren: () => import('./pages/slack-login/slack-login.module').then( m => m.SlackLoginPageModule)
  },
  {
    path: 'microsoft/login',
    title: 'microsoft',
    loadChildren: () => import('./pages/microsoft-login/microsoft-login.module').then( m => m.MicrosoftLoginPageModule)
  },
  {
    path: 'google/login',
    title: 'google',
    loadChildren: () => import('./pages/google-login/google-login.module').then( m => m.GoogleLoginPageModule)
  },
  {
    path: 'webex/login',
    title: 'webex',
    loadChildren: () => import('./pages/webex-login/webex-login.module').then( m => m.WebexLoginPageModule)
  },
  {
    path: 'upgrade',
    title: 'upgrade',
    loadChildren: () => import('./pages/upgrade/upgrade.module').then( m => m.UpgradePageModule)
  },
  {
    path: 'recording/:id',
    title: 'recording',
    loadChildren: () => import('./pages/download-recording/download-recording.module').then( m => m.DownloadRecordingPageModule)
  },
  {
    // :request-type can be either 'personal' or 'organization'
    path: 'register/accept-invite/:request-type/:entity-id/:invite-id',
    title: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule),
  },
  {
    path: 'accept-invite/:request-type/:entity-id/:invite-id',
    title: 'accept_invite',
    loadChildren: () => import('./pages/accept-invite/accept-invite.module').then( m => m.AcceptInvitePageModule)
  },
  {
    path: 'login/accept-invite/:request-type/:entity-id/:invite-id',
    title: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToHome }
  },
  {
    path: '**', redirectTo: '/login', pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
