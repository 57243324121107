import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserNotificationService {

  private permission: NotificationPermission = 'default';
  constructor() { }

  // TODO - Add analytics
  public async requestPermission() {
    if (Notification.permission === 'granted') {
      this.permission = 'granted';

      return this.permission;
    }

    this.permission = await Notification.requestPermission();
  }

  public async showNotification(title: string, options: NotificationOptions): Promise<Notification|void> {
    if (this.permission === 'granted') {
      return new Notification(title, options);
    }

    if (this.permission === 'default') {
      await this.requestPermission();
      return this.showNotification(title, options);
    }
  }
}
