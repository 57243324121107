import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

// Services
import { AnalyticEventService } from './services/analytic-event/analytic-event.service';
import { AppHeaderService } from './services/app-header/app-header.service';
import { FirebaseService } from './services/firebase/firebase.service';
import { ForceUpdateService } from './services/force-update/force-update.service';
import { IntervalService } from './services/interval/interval.service';
import { LanguageChangeService } from 'src/app/services/language-change/language-change.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { LoggingChannels, LoggingService } from './services/logging/logging.service';
import { MessagingListenerService } from './services/messaging-listener/messaging-listener.service';
import { UserService } from './services/user/user.service';
import { AmplifyService } from './services/amplify/amplify.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  version: string;

  constructor(
    public appHeader: AppHeaderService,
    private amplify: AmplifyService,
    private analytics: AnalyticEventService,
    private firebase: FirebaseService,
    private forceUpdate: ForceUpdateService,
    private interval: IntervalService,
    private languageChangeService: LanguageChangeService,
    private localStorage: LocalStorageService,
    private logging: LoggingService,
    private messagingListener: MessagingListenerService,
    private router: Router,
    private title: Title,
    private translate: TranslateService,
    private user: UserService,
  ) {
    this.logging.log(`AppComponent: v${environment.version}`, LoggingChannels.Default);

    this.firebase.firestore.docListen('configs/current', (doc) => {
      if (doc.exists()) {
        const configs: any = doc.data();
        if (this.forceUpdate.requiredUpdate(environment.version, configs.version)) {
          this.logging.log('AppComponent: Need update', LoggingChannels.Default);
          this.router.navigate(['/upgrade']);
        }
      }
    });

    const oldHandler = this.router.errorHandler;
    this.router.errorHandler =  (err: any) => {
      if (err.originalStack && err.originalStack.indexOf('Error: Loading chunk') >= 0) {
        if (this.localStorage.getItem('lastChunkError') !== err.originalStack) {
          this.localStorage.setItem('lastChunkError', err.originalStack);
          location.reload();
        } else {
          console.error('We really don\'t find the chunk...');
        }
      }
      oldHandler(err);
    };

    this.interval.startInterval();

    this.amplify.configure();

    this.version = environment.version;

    const lang = this.localStorage.getItem('lang') || 'en';
    this.languageChangeService.changeLanguage(lang);
    this.translate.setDefaultLang('en');

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if isHeaderDisplayed is false and only hide the header if it is
        // We want to let the page controller decide if the header should be displayed or not
        if (!this.isHeaderDisplayed(event)) {
          this.appHeader.isHeaderVisible = false;
        }
        setTimeout(() => {
          this.setTitle();
        }, 1);
      }
    });

    this.languageChangeService.lang = this.localStorage.getItem('lang') || 'en';

    this.setupMessagingListener();
  }

  public isHeaderDisplayed(event: NavigationEnd) {
    const path = event.url.split('#')[0];

    // SHow header on /recordings, /home, and on the base page of /messaging
    return ['/recordings', '/home', '/messaging'].filter((p) => path.indexOf(p) === 0).length > 0
      && ['/conversation', '/contacts'].filter((p) => path.indexOf(p) > -1).length === 0;
  }

  private setTitle() {
    const currentTitle = this.title.getTitle();
    if (currentTitle.indexOf('Crewdle') === -1) {
      const titleString = `title.${currentTitle}`;
      const translated = this.translate.instant(titleString);
      if (translated !== titleString) {
        this.title.setTitle(translated + ' | Crewdle');
        this.analytics.logPage();
      } else {
        setTimeout(() => this.setTitle(), 300);
      }
    }
  }

  private async setupMessagingListener() {
    await this.user.isReady();
    this.messagingListener.listen();
  }
}
