export enum CallIssueType {
  'st_volatility'           = 'st_volatility',
  'dt_volatility'           = 'dt_volatility',
  'decode_time'             = 'decode_time',
  'encode_time'             = 'encode_time',
  'bandwidth_in'            = 'bandwidth_in',
  'bandwidth_out'           = 'bandwidth_out',
  'audio_jitter_in'         = 'audio_jitter_in',
  'audio_jitter_out'        = 'audio_jitter_out',
  'video_jitter_in'         = 'video_jitter_in',
  'video_jitter_out'        = 'video_jitter_out',
  'fps_in'                  = 'fps_in',
  'fps_out'                 = 'fps_out',
  'rtt'                     = 'rtt',
  'av_delay'                = 'av_delay',
  'video_missing'           = 'video_missing',
  'audio_missing'           = 'audio_missing',
  'screen_sharing_missing'  = 'screen_sharing_missing',
  'input_output_mismatch'   = 'input_output_mismatch',
  'weak_network'            = 'weak_network',
  'no_volume'               = 'no_volume',
  'missing_volume'          = 'missing_volume',
}

export class CallIssue {
  timestamp: number;
  type: CallIssueType;
  participant: string;
  value: any;

  constructor(type: CallIssueType, participant: string, value: any) {
    this.timestamp = (new Date()).getTime();
    this.type = type;
    this.participant = participant;
    this.value = value;
  }
}
