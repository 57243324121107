import { Injectable } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { BillingProviderInterface } from 'src/app/services/billing/billing-provider.interface';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class StripeBillingProvider implements BillingProviderInterface {

  constructor(
    private localStorage: LocalStorageService,
    private firebase: FirebaseService,
    private user: UserService,
  ) {}

  openCheckout(product: number) {
    const payload = {
      priceId: product,
      userId: this.user.user.uid,
      organizationId: this.user.data.organization,
    };

    this.firebase.functions.call('createCheckoutSession', payload).then(({ session }) => {
      // TODO - Standardize the localStorage management in a dedicated model
      this.localStorage.setItem('subscription', JSON.stringify({product, status: 'in progress'}));
      window.open(session.url, '_self');
    });
  }
}
