import { Injectable } from '@angular/core';
import {OnDestroy } from '@angular/core';

interface Interval {
  callback: CallableFunction;
  time: number;
  lastExecuted: number;
}

@Injectable({
  providedIn: 'root',
})
export class IntervalService  implements OnDestroy {
  public intervals: Record<string, Interval> = {};
  private intervalCollection: Array<[string, Interval]> = [];
  private mainInterval: boolean;
  private mainIntervalTime = 250;
  private audio: AudioContext;

  constructor() {
    this.mainInterval = false;
  }

  ngOnDestroy() {
    const intervalIds = Object.keys(this.intervals);
    intervalIds.map(id => this.clearInterval(id));
  }

  public setInterval(id: string, callback: CallableFunction, time: number) {
    if (this.intervals[id]) {
      this.clearInterval(id);
    }
    this.intervals[id] = { callback, time, lastExecuted: Date.now() };
    this.intervalCollection = Object.entries(this.intervals);
    this.startInterval();
  }

  public clearInterval(id: string) {
    if (!this.intervals[id]) {
      return ;
    }

    delete this.intervals[id];
    this.intervalCollection = Object.entries(this.intervals);
    this.startInterval();
  }

  public async startInterval() {
    if (!this.mainInterval) {
      this.mainInterval = true;
      setInterval(() => this.executeInterval(), this.mainIntervalTime);
    }
  }

  public startNoThrottlingHack() {
    this.audio = new AudioContext();
    const source = this.createConstantSource();
    const gainNode = this.audio.createGain();
    if (gainNode && source) {
      gainNode.gain.value = 0.001;
      source.connect(gainNode);
      gainNode.connect(this.audio.destination);
      source.start();
    }
  }

  public stopNoThrottlingHack() {
    if (this.audio) {
      this.audio.close();
      this.audio = null;
    }
  }

  public sleep(time = 100): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), time);
    });
  }

  private createConstantSource(): AudioBufferSourceNode | ConstantSourceNode {
    if (this.audio.createConstantSource) {
      return this.audio.createConstantSource();
    }

    const constantSourceNode = this.audio.createBufferSource();
    const constantBuffer = this.audio.createBuffer(1, 1, this.audio.sampleRate);
    const bufferData = constantBuffer.getChannelData(0);
    bufferData[0] = (0 * 1200) + 10;
    constantSourceNode.buffer = constantBuffer;
    constantSourceNode.loop = true;

    return constantSourceNode;
  }

  private executeInterval() {
    this.intervalCollection?.map((data) => {
      const [id, interval] = data;
      const { time, lastExecuted, callback } = interval;
      if (time < Date.now() - lastExecuted) {
        const skipped = this.getNumberOfSkippedExecution(interval);
        try {
          callback();
        } catch (e) {}

        // To prevent execution delays from compounding, we always assume the last execution was on time
        // But we make sure we don't run the interval for all skipped execution
        interval.lastExecuted = lastExecuted + (time * skipped);
      }
    });
  }

  private getNumberOfSkippedExecution({ lastExecuted, time }) {
    const currentTime = Date.now();
    const skippedCount = Math.floor((currentTime - lastExecuted) / time);

    // We make sure we at least have 1 execution counted
    return skippedCount > 0 ? skippedCount : 1;
  }
}
