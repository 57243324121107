import { Injectable } from '@angular/core';
import { billingPlan, billingPlans } from './plans';
import { User } from 'firebase/auth';
import { Unsubscribe } from 'firebase/auth';
import { FirebaseService } from '../firebase/firebase.service';
import { StripeBillingProvider } from 'src/app/services/billing/stripe.billing-provider.service';
import { QuerySnapshot } from 'firebase/firestore';
import { IntervalService } from '../interval/interval.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationService } from '../organization/organization.service';

interface SubscriptionData {
  userId: string;
  organizationId?: string;
  stripeSubscriptionId: string;
  stripeCustomerId: string;

  // TODO - Add this as an enum in ./plans.ts?
  subscription: 'free' | 'pro' | 'business';
  status: 'active' | 'completed' | 'past_due' | 'canceled' | 'other';
}

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  public currentPlan: billingPlan;
  public isMarketplace: boolean;
  private userSub: Unsubscribe;
  private orgSub: Unsubscribe;
  private subscriptionListener: Unsubscribe;
  private orgSubscriptionListener: Unsubscribe;
  private userData: any;
  private orgData: any;
  private subData?: SubscriptionData;

  constructor(
    private billingProvider: StripeBillingProvider,
    private firebase: FirebaseService,
    private interval: IntervalService,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private organization: OrganizationService,
  ) {
    this.getAuthUser();
  }

  openCheckout(product: number) {
    this.billingProvider.openCheckout(product);
  }

  public isFreePlan() {
    if (!this.currentPlan) {
      return true;
    }
    return this.currentPlan.id === 'free';
  }

  public async isReady(): Promise<boolean> {
    if (this.currentPlan) {
      return true;
    }
    await this.interval.sleep();
    return this.isReady();
  }

  public async presentUpgrade() {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('room.upgrade_header'),
      subHeader: this.translate.instant('room.upgrade_text'),
      buttons: [{
        text: this.translate.instant('common.buttons.cancel'),
        role: 'cancel',
      }, {
        text: this.translate.instant('common.buttons.upgrade'),
        handler: () => {
          if (this.organization.data && this.organization.data.marketplace) {
            Object.assign(document.createElement('a'), {
              target: '_blank',
              href: this.organization.data.marketplace.baseUrl + '/account/apps',
            }).click();
          } else {
            Object.assign(document.createElement('a'), {
              target: '_blank',
              href: '/home#upgrade',
            }).click();
          }
        },
      }],
    });

    await alert.present();
  }

  private setCurrentPlan(): void {
    this.currentPlan = null;
    if (this.userData || this.orgData || this.subData) {
      billingPlans.forEach((plan) => {
        if (this.userData?.subscription === plan.id || this.orgData?.subscription === plan.id || this.subData?.subscription === plan.id) {
          this.currentPlan = plan;
        }
        if (plan.legacyId && this.orgData?.subscription?.toLowerCase() === plan.legacyId) {
          this.currentPlan = plan;
        }
      });
      if (!this.currentPlan) {
        this.currentPlan = billingPlans[0];
      }
    }
  }

  private getAuthUser(): void {
    this.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser && !authUser.isAnonymous) {
        this.getUserData(authUser);
      } else {
        this.resetUser();
        this.currentPlan = billingPlans[0];
      }
    });
  }

  private getUserData(authUser: User): void {
    if (this.userSub) {
      this.userSub();
      this.userSub = null;
    }
    this.userSub = this.firebase.firestore.docListen(`users/${authUser.uid}`, (userDoc) => {
      if (userDoc.exists()) {
        const user: any = userDoc.data();
        this.userData = user;
        if (user) {
          if (user.organization) {
            this.getOrgData(authUser.uid, user.organization);
          } else if (this.orgSub) {
            this.orgData = null;
            this.isMarketplace = false;
            this.orgSub();
            this.orgSub = null;
            this.getSubData(authUser.uid);
          } else {
            this.getSubData(authUser.uid);
          }
        }
      }
    });
  }

  private getOrgData(userId: string, orgId: string) {
    if (this.orgSub) {
      this.orgSub();
      this.orgSub = null;
    }

    this.orgSub = this.firebase.firestore.docListen(`organizations/${orgId}`, (orgDoc) => {
      if (orgDoc.exists()) {
        const org: any = orgDoc.data();
        this.orgData = org;
        this.getSubData(userId, orgId);
        if (org.marketplace) {
          this.isMarketplace = true;
        } else {
          this.isMarketplace = false;
        }
      }
    });
  }

  private getSubData(userId: string, organizationId?: string) {
    if (this.subscriptionListener) {
      this.subscriptionListener();
      this.subscriptionListener = null;

      if (this.orgSubscriptionListener) {
        this.orgSubscriptionListener();
        this.orgSubscriptionListener = null;
      }
    }
    const forUser = this.firebase.firestore.where('userId', '==', userId);
    const withActiveSubscription = this.firebase.firestore.where('stripeSubscriptionId', '!=', false);
    this.subscriptionListener = this.firebase.firestore.collectionListen('subscriptions', [forUser, withActiveSubscription], (subDocs) => {
      this.subData = this.validateSubscriptionData(subDocs);

      // If we didn't find an active subscription for the user
      // We then look if the user has an organization
      if (!this.subData && organizationId) {
        const forOrganization = this.firebase.firestore.where('organizationId', '==', organizationId);
        this.orgSubscriptionListener = this.firebase.firestore.collectionListen('subscriptions', [forOrganization, withActiveSubscription], (subOrgDocs) => {
          this.subData = this.validateSubscriptionData(subOrgDocs);
          return this.setCurrentPlan();
        });

        return;
      }

      return this.setCurrentPlan();
    });
  }

  private validateSubscriptionData(documents: QuerySnapshot): SubscriptionData | null {
    if (documents.empty || !documents.docs[0] || !documents.docs[0].data()) {
      return null;
    }

    const data = documents.docs[0].data();
    if (!data.userId || !data.subscription || !data.stripeCustomerId || !data.stripeSubscriptionId) {
      return null;
    }

    return data as SubscriptionData;
  }

  private resetUser(): void {
    if (this.userSub) {
      this.userSub();
      this.userSub = null;
    }
    if (this.orgSub) {
      this.orgSub();
      this.orgSub = null;
    }
    if (this.subscriptionListener) {
      this.subscriptionListener();
      this.subscriptionListener = null;
    }
    this.userData = null;
    this.orgData = null;
    this.subData = null;
    this.isMarketplace = false;
    this.setCurrentPlan();
  }
}
