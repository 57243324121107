import { CallIssue, CallIssueType } from 'src/app/models/call-issue/call-issue';
import { CallProblem, CallProblemSeverity } from 'src/app/models/call-problem/call-problem';
import { Rule } from './rule';

export enum CpuProblems {
  'loop'        = 'cpu.loop',
  'decode_time' = 'cpu.decode_time',
  'encode_time' = 'cpu.encode_time',
  'av_delay'    = 'cpu.av_delay',
  'fps_in'      = 'cpu.fps_in',
  'fps_out'     = 'cpu.fps_out',
}

const ST_TRESHOLD = 10;
const DT_THESHOLD = 7;
const DECODE_TIME_THRESHOLD = 3;
const ENCODE_TIME_THRESHOLD = 3;
const AV_DELAY_THRESHOLD = 1;
const FPS_THRESHOLD = 2;

export class CpuRule implements Rule {
  public run(callIssues: CallIssue[], participant: string, nbParticipants: number): CallProblem {
    let nbStIssues = 0;
    let nbDtIssues = 0;
    let nbDecodeTimeIssues = 0;
    let nbEncodeTimeIssues = 0;
    let nbAvDelay = 0;
    let nbFpsInIssues = 0;
    let nbFpsOutIssues = 0;

    callIssues.forEach((callIssue) => {
      switch (callIssue.type) {
        case CallIssueType.st_volatility:
          nbStIssues++;
          break;
        case CallIssueType.dt_volatility:
          nbDtIssues++;
          break;
        case CallIssueType.decode_time:
          if (callIssue.participant === participant) {
            nbDecodeTimeIssues++;
          }
          break;
        case CallIssueType.encode_time:
          if (callIssue.participant === participant) {
            nbEncodeTimeIssues++;
          }
          break;
        case CallIssueType.av_delay:
          nbAvDelay++;
          break;
        case CallIssueType.fps_in:
          nbFpsInIssues++;
          break;
        case CallIssueType.fps_out:
          nbFpsOutIssues++;
          break;
      }
    });

    if (nbStIssues > ST_TRESHOLD || nbDtIssues > DT_THESHOLD) {
      return new CallProblem(null, CpuProblems.loop, CallProblemSeverity.warning);
    }
    let factor = 10 / nbParticipants;
    if (factor > 1 || factor <= 0) {
      factor = 1;
    }
    if (nbDecodeTimeIssues > Math.floor(DECODE_TIME_THRESHOLD * factor)) {
      return new CallProblem(participant, CpuProblems.decode_time, CallProblemSeverity.warning);
    }
    if (nbEncodeTimeIssues > Math.floor(ENCODE_TIME_THRESHOLD * factor)) {
      return new CallProblem(participant, CpuProblems.encode_time, CallProblemSeverity.warning);
    }
    if (nbAvDelay > Math.floor(AV_DELAY_THRESHOLD * factor)) {
      return new CallProblem(participant, CpuProblems.av_delay, CallProblemSeverity.warning);
    }
    if (nbFpsInIssues > Math.floor(FPS_THRESHOLD * factor)) {
      return new CallProblem(participant, CpuProblems.fps_in, CallProblemSeverity.warning);
    }
    if (nbFpsOutIssues > Math.floor(FPS_THRESHOLD * factor)) {
      return new CallProblem(participant, CpuProblems.fps_out, CallProblemSeverity.warning);
    }

    return null;
  }

  public clear(callProblem: CallProblem, callIssues: CallIssue[]): CallIssue[] {
    let filter = [];
    switch (callProblem.message) {
      case CpuProblems.loop:
        filter = [
          CallIssueType.st_volatility,
          CallIssueType.dt_volatility,
        ];
        break;
      case CpuProblems.decode_time:
        filter = [
          CallIssueType.decode_time,
        ];
        break;
      case CpuProblems.encode_time:
        filter = [
          CallIssueType.encode_time,
        ];
        break;
      case CpuProblems.av_delay:
        filter = [
          CallIssueType.av_delay,
        ];
        break;
      case CpuProblems.fps_in:
        filter = [
          CallIssueType.fps_in,
        ];
        break;
      case CpuProblems.fps_out:
        filter = [
          CallIssueType.fps_out,
        ];
        break;
    }
    return callIssues.filter((val) => !(filter.includes(val.type) && val.participant === callProblem.participant));
  }
}
