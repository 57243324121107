import { CallIssue } from 'src/app/models/call-issue/call-issue';
import { Rule } from 'src/app/services/issue-detection/rules/rule';
import { CallProblem, CallProblemSeverity } from 'src/app/models/call-problem/call-problem';
import { CallIssueType } from 'src/app/models/call-issue/call-issue';

export enum PermissionProblems {
  'audio_video'    = 'permissions.audio_video',
  'audio'          = 'permissions.audio',
  'video'          = 'permissions.video',
  'screen_sharing' = 'permissions.screen_sharing',
}

export class PermissionsRule implements Rule {
  public run(callIssues: CallIssue[], participant: string, nbParticipants: number): CallProblem {
    if (!callIssues || callIssues.length === 0) {
      return null;
    }

    const { audio, video, screenSharing } = callIssues.reduce((permissions, issue) => {
      if (issue.type === CallIssueType.video_missing) {
        return {
          ...permissions,
          video: false,
        };
      }

      if (issue.type === CallIssueType.audio_missing) {
        return {
          ...permissions,
          audio: false,
        };
      }

      if (issue.type === CallIssueType.screen_sharing_missing) {
        return {
          ...permissions,
          screenSharing: false,
        };
      }

      return permissions;
    }, {
      audio: true,
      video: true,
      screenSharing: true,
    });

    if (!audio && !video) {
      return new CallProblem(null, PermissionProblems.audio_video, CallProblemSeverity.warning, true);
    }

    if (!video) {
      return new CallProblem(null, PermissionProblems.video, CallProblemSeverity.warning, true);
    }

    if (!audio) {
      return new CallProblem(null, PermissionProblems.audio, CallProblemSeverity.warning, true);
    }

    if (!screenSharing) {
      return new CallProblem(null, PermissionProblems.screen_sharing, CallProblemSeverity.warning, true);
    }

    return null;
  }

  public clear(callProblem: CallProblem, callIssues: CallIssue[]): CallIssue[] {
    return callIssues;
  }
}
