import { Injectable } from '@angular/core';
import { LoggingChannels, LoggingService } from '../logging/logging.service';
import { CallIssue, CallIssueType } from 'src/app/models/call-issue/call-issue';
import { CallProblem } from 'src/app/models/call-problem/call-problem';
import { Rule } from 'src/app/services/issue-detection/rules/rule';
import { CpuRule } from './rules/cpu';
import { BandwidthRule } from './rules/bandwidth';
import { OfflineRule } from 'src/app/services/issue-detection/rules/offline-detector';
import { IssueRemediationService } from '../issue-remediation/issue-remediation.service';
import { PermissionsRule } from 'src/app/services/issue-detection/rules/permissions';
import { UnstableConnectionRule } from 'src/app/services/issue-detection/rules/unstable-connection';
import { UnstableConnectionService } from 'src/app/services/issue-detection/rules/unstable-connection.service';
import { DeviceRule } from './rules/device';
import { ParticipantsListService } from '../participants-list/participants-list.service';
import { NotificationService } from '../notification/notification.service';

const unstableConnectionRule = new UnstableConnectionRule();

const RULES: Rule[] = [
  new OfflineRule(),
  unstableConnectionRule,
  new CpuRule(),
  new BandwidthRule(),
  new PermissionsRule(),
  new DeviceRule(),
];

@Injectable({
  providedIn: 'root'
})
export class IssueDetectionService {

  callIssues: CallIssue[];
  shareIssues: CallIssue[];
  roomId: string;
  userId: string;
  currentProblem: CallProblem = null;

  private toastProblem = null;
  private successHandler: EventListenerOrEventListenerObject;

  constructor(
    private logging: LoggingService,
    private issueRemediation: IssueRemediationService,
    private unstableConnectionService: UnstableConnectionService,
    private participants: ParticipantsListService,
    private notification: NotificationService,
  ) {
    this.callIssues = [];
    this.shareIssues = [];
    unstableConnectionRule.attachEvents(this.unstableConnectionService);
  }

  public start(roomId: string, userId: string) {
    this.roomId = roomId;
    this.userId = userId;
    this.callIssues = [];
    this.shareIssues = [];
    this.issueRemediation.start(roomId, userId);
  }

  public stop() {
    this.issueRemediation.stop();
    this.roomId = null;
    this.userId = null;
    this.callIssues = [];
    this.shareIssues = [];
    if (this.toastProblem) {
      this.toastProblem.dismiss();
    }
  }

  public logEvent(type: CallIssueType, participant: string, value: any, sharing = false) {
    if (this.roomId === null) {
      return;
    }
    this.logging.log('IssueDetectionService: New Event', LoggingChannels.Remediation, type, participant, value, sharing);
    this.logging.logCustomEvent('issue', { type, participant, userId: this.userId, roomId: this.roomId, value, sharing });
    if (sharing) {
      this.shareIssues.push(new CallIssue(type, participant, value));
    } else {
      this.callIssues.push(new CallIssue(type, participant, value));
    }
    try {
      this.checkIssues(participant, sharing);
    } catch (e) {
      this.logging.error('IssueDetectionService: Error encountered checking for issues', e);
    }
  }

  public clearIssueType(type: CallIssueType) {
    this.callIssues = this.callIssues.filter(issue => issue.type !== type);

    return this.callIssues;
  }

  public checkIssues(participant: string, sharing: boolean) {
    if (sharing) {
      this.runShareRules(participant);
    } else {
      const problem = this.runRules(participant);
      if (!problem && this.currentProblem) {
        this.currentProblem = null;
        if (this.toastProblem) {
          this.toastProblem.dismiss();
        }
      }
    }

    const now = (new Date()).getTime();
    if (sharing) {
      this.shareIssues = this.shareIssues.filter((issue) => issue.timestamp >= now - (30 + 1 * (this.participants.nbParticipants - 1)) * 1000);
    } else {
      this.callIssues = this.callIssues.filter((issue) => issue.timestamp >= now - (30 + 1 * (this.participants.nbParticipants - 1)) * 1000);
    }
  }

  private runRules(participant: string) {
    let problem: CallProblem = null;
    for (const rule of RULES) {
      problem = rule.run(this.callIssues, participant, this.participants.nbParticipants - 1);

      if (problem) {
        // TODO - When logging before the user joins the meeting, it throws a firebase warning 'permission_deined'
        if (problem.message !== this.currentProblem?.message) {
          const remediation = this.issueRemediation.checkProblem(problem);
          if (!remediation) {
            if (this.toastProblem && this.currentProblem) {
              this.toastProblem.dismiss();
            }

            this.log(problem, false);
            this.display(problem);
            this.addSuccessNotificationHandler(problem);
            if (problem?.notification) {
              this.currentProblem = problem;
            }
          } else {
            this.log(problem, false);
            this.callIssues = rule.clear(problem, this.callIssues);
          }
        }

        return problem;
      }
    }

    return null;
  }

  private runShareRules(participant: string) {
    let problem: CallProblem = null;
    for (const rule of RULES) {
      problem = rule.run(this.shareIssues, participant, this.participants.nbParticipants - 1);

      if (problem) {
        const remediation = this.issueRemediation.checkProblem(problem, true);
        if (!remediation) {
          this.log(problem, true);
        } else {
          this.log(problem, true);
          this.shareIssues = rule.clear(problem, this.shareIssues);
        }
      }
    }
  }

  private log(problem?: CallProblem, sharing?: boolean) {
    const type = problem?.message || null;
    const participant = problem?.participant || null;
    this.logging.logCustomEvent('problem', { type, participant, userId: this.userId, roomId: this.roomId, sharing });
  }

  private display(problem: CallProblem) {
    if (!problem.notification) {
      return;
    }

    let duration = 2000;
    if (problem.message === 'offline') {
      duration = 0;
    }

    this.notification.display(`problems.${problem.message}`, problem.severity, 'top', duration, [{
      side: 'end',
      icon: 'close',
      role: 'cancel',
    }]);
  }

  private addSuccessNotificationHandler(problem) {
    if (problem.message === 'offline') {
      this.successHandler = () => this.displayOnlineSuccessToastNotification();
      return addEventListener('online', this.successHandler);
    }
  }

  private displayOnlineSuccessToastNotification() {
    removeEventListener('online', this.successHandler);
    this.notification.display('problems.online', 'primary', 'top', 2000, [{
      side: 'end',
      icon: 'close',
      role: 'cancel',
    }]);
  }
}
