import { AudioStreamScriptLoader } from './audio-stream-script-loader';
import { AudioStreamWorkletLoader } from './audio-stream-worklet-loader';

export class AudioStream {
  stream: MediaStream;
  volume: number;
  loaded: boolean;
  connected: boolean;

  private audioContext: AudioContext;
  private source: MediaStreamAudioSourceNode;
  private audioStream: AudioStreamScriptLoader | AudioStreamWorkletLoader;
  private volumeStreamPromise: Promise<string>;
  private retryInterval: any;
  private sampleSize: number;

  constructor(
    stream: MediaStream,
    sampleSize?: number|null,
  ) {
    this.stream = stream;
    this.volume = 0;
    this.loaded = false;
    this.connected = false;
    this.sampleSize = sampleSize;
  }

  load() {
    if (this.loaded) {
      return;
    }
    this.loaded = true;

    this.audioContext = new AudioContext();

    if (typeof window.AudioWorkletNode === 'function') {
      this.audioStream = new AudioStreamWorkletLoader(
        this.audioContext,
        this.sampleSize
      );
    } else if (typeof window.ScriptProcessorNode === 'function') {
      this.audioStream = new AudioStreamScriptLoader(
        this.audioContext,
        this.sampleSize
      );
    }

    if (!this.audioStream) {
      throw new DOMException('AudioStream: Could not find a valid audio context');
    }

    try {
      this.volumeStreamPromise = new Promise(async (resolve, reject) => {
        await this.audioStream.load(resolve, reject);

      });

    } catch (e) {
      console.error('AudioStream: Error loading the audio stream', e);
    }

    return this.volumeStreamPromise;
  }

  getVolumeObserver() {
    return this.audioStream.getObserver();
  }

  getUnbufferredVolumeObserver() {
    return this.audioStream.getUnbufferedVolumeObserver();
  }

  connect() {
    if (this.connected) {
      return;
    }

    this.connected = true;
    try {
      if (!this.audioContext || this.stream.getAudioTracks().length === 0) {
        return this.attemptRetry();
      }

      this.connectAudioStreamWhenReady();
    } catch (err) {
      console.error('AudioStream: Error loading the audio stream. Aborting because =>', err);
    }

    return this.volumeStreamPromise;
  }

  unload() {
    if (this.connected) {
      this.disconnect();
    }

    if (this.audioContext) {
      delete this.audioContext;
      this.audioContext = null;
    }

    if (this.audioStream) {
      this.audioStream.unload();
    }

   this.loaded = false;
  }

  disconnect() {
    if (this.audioContext) {
      if (this.source) {
        this.source.disconnect();
        delete this.source;
        this.source = null;
      }
    }

    if (this.audioStream) {
      this.audioStream.disconnect();
    }

    if (this.retryInterval) {
      clearInterval(this.retryInterval);
      delete this.retryInterval;
    }

    this.connected = false;
  }

  private connectAudioStreamWhenReady() {
    if (!this.audioContext || this.stream.getAudioTracks().length === 0) {
      return;
    }

    try {
      this.source = this.audioContext.createMediaStreamSource(this.stream);
      this.audioStream.connect(this.source, this.audioContext.destination);
      if (this.retryInterval) {
        clearInterval(this.retryInterval);
        delete this.retryInterval;
      }
    } catch (err) {
      console.error('AudioStream: Error connecting microphone', err);
      this.source = null;
    }
  }

  private attemptRetry() {
    let attempt = 0;
    this.retryInterval = setInterval(() => {
      ++attempt;
      this.connectAudioStreamWhenReady();
      if (attempt > 10) {
        if (this.stream.getAudioTracks().length > 0) {
          console.error('AudioStream: Unable to connect the audio stream', this.audioContext, this.stream.getAudioTracks());
        }
        clearInterval(this.retryInterval);
      }
    }, 500);
  }
}
