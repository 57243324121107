import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: environment.datadog.appId,
  clientToken: environment.datadog.token,
  site: 'datadoghq.com',
  service: 'crewdle-app',
  env: environment.production ? 'production' : 'staging',
  version: environment.version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
