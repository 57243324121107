import { Injectable } from '@angular/core';
import { LoggingChannels, LoggingService } from '../logging/logging.service';
import { IntervalService } from '../interval/interval.service';
import { AnalyticEventService } from '../analytic-event/analytic-event.service';
import { NotificationService } from '../notification/notification.service';
import { BlurCpu } from './blur-cpu';
import { FeatureName, SupportService } from '../support/support.service';
import { BlurGpu } from './blur-gpu';
import { Blur } from './blur';
import { FilesetResolver, ImageSegmenter, ImageSegmenterResult } from '@mediapipe/tasks-vision';

declare global {
  interface Window {
    crewdleShowMask: (show: boolean) => void;
    crewdleChangeThreshold: (certainty: number) => void;
    crewdleChangeResolution: (resolution: number) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class VirtualBackgroundService {
  public blurred: boolean;
  public blurDensityState: number;
  public backgroundImage: HTMLImageElement;
  public backgroundImg: boolean;
  public isActive: boolean;
  public resolution: number;
  public savedResolution: number;
  public forceResolution: boolean;
  private segmenter: ImageSegmenter;
  private loopInterval;
  private video: HTMLVideoElement;
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private offscreenCanvas: HTMLCanvasElement;
  private offscreenContext: CanvasRenderingContext2D;
  private blurCanvas: HTMLCanvasElement;
  private blurContext: CanvasRenderingContext2D;
  private maskCanvas: HTMLCanvasElement;
  private maskContext: CanvasRenderingContext2D;
  private fps: number;
  private lastDecrease: number;
  private showMask: boolean;
  private certaintyThreshold: number;
  private blur: Blur;
  private isRunning: boolean;

  constructor(
    private logging: LoggingService,
    private interval: IntervalService,
    private analytics: AnalyticEventService,
    private notification: NotificationService,
    private support: SupportService,
  ) {
    this.blurred = false;
    this.backgroundImg = false;
    this.isActive = false;
    this.showMask = false;
    this.certaintyThreshold = 0.5;
    this.backgroundImage = new Image();
    window.crewdleShowMask = (show: boolean) => {
      this.showMask = show;
      if (show) {
        document.getElementById('home-video').style.padding = '0';
      } else {
        document.getElementById('home-video').style.padding = '10px';
      }
    };
    window.crewdleChangeThreshold = (certainty: number) => {
      this.certaintyThreshold = certainty;
    };
    window.crewdleChangeResolution = (resolution: number) => {
      this.forceResolution = true;
      this.resolution = resolution;
    };
    if (this.support.isFeatureSupported(FeatureName.WebGL)) {
      this.analytics.logEvent('virtual_backgrounds_gpu');
      this.blur = new BlurGpu();
    } else {
      this.analytics.logEvent('virtual_backgrounds_cpu');
      this.blur = new BlurCpu();
    }
    this.offscreenCanvas = document.createElement('canvas');
    this.offscreenContext = this.offscreenCanvas.getContext('2d');
    this.createSegmenter();
  }

  public async startBlur(blurDensity?: string, imageUrl?: string) {
    this.analytics.logEvent('virtual_backgrounds');
    this.stopBlur();

    this.resolution = 0.5;
    this.fps = 24;
    this.lastDecrease = (new Date()).getTime();

    if (!this.segmenter) {
      await this.createSegmenter();
    }

    if (blurDensity) {
      this.backgroundImg = false;
      this.blurred = true;
      this.backgroundImage.src = '';
      this.blurDensityState = blurDensity === 'strong' ? 4 : 2;
      this.loopInterval = setInterval(() => this.handleBlur(), 1000 / this.fps);
      this.logging.log('VirtualBackgroundService: Start blur', LoggingChannels.VirtualBackground);
    }

    if (imageUrl) {
      this.blurred = false;
      this.backgroundImg = true;
      this.backgroundImage.src = imageUrl;
      this.loopInterval = setInterval(() => this.handleBackgroundImg(), 1000 / this.fps);
      this.logging.log('VirtualBackgroundService: Start background image', LoggingChannels.VirtualBackground);
    }

    this.interval.setInterval('virtual-background-upgrade', () => this.upgrade(), 5000);

    await this.isReady();
  }

  public stopBlur() {
    this.interval.clearInterval('virtual-background-upgrade');
    if (this.loopInterval) {
      clearInterval(this.loopInterval);
    }
    this.loopInterval = null;
    this.video = null;
    if (this.context && this.canvas) {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
    this.canvas = null;
    this.context = null;
    if (this.offscreenContext && this.offscreenCanvas) {
      this.offscreenContext.clearRect(0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height);
    }
    if (this.blurContext && this.blurCanvas) {
      this.blurContext.clearRect(0, 0, this.blurCanvas.width, this.blurCanvas.height);
    }
    this.blurCanvas = null;
    this.blurContext = null;
    if (this.maskContext && this.maskCanvas) {
      this.maskContext.clearRect(0, 0, this.maskCanvas.width, this.maskCanvas.height);
    }
    this.maskCanvas = null;
    this.maskContext = null;
    this.resolution = null;
    this.isActive = false;
    this.blurred = false;
    this.backgroundImg = false;
    this.logging.log('VirtualBackgroundService: Stop blur', LoggingChannels.VirtualBackground);
  }

  public getVideoTrack(): MediaStreamTrack {
    const stream = this.offscreenCanvas.captureStream(24);
    const video = stream.getVideoTracks();
    if (video.length > 0) {
      return video[0];
    }
    return null;
  }

  public downgrade(): boolean {
    if (this.isActive) {
      if (this.forceResolution) {
        return false;
      }
      const now = (new Date()).getTime();
      if (this.resolution === 0.25 && this.fps === 18) {
        this.lastDecrease = now;
        return false;
      }
      if (now - this.lastDecrease < 5000) {
        return true;
      }
      this.lastDecrease = now;
      if (this.fps === 18) {
        this.fps = 24;
        this.resolution = this.resolution - 0.25;
        this.resetInterval();
      } else if (this.fps === 24) {
        this.fps = 18;
        this.resetInterval();
        if (this.resolution === 0.25 && this.fps === 18) {
          this.notification.display('problems.background', 'warning', 'top', 5000, [{
            side: 'end',
            icon: 'close',
            role: 'cancel',
          }]);
        }
      }
      this.logging.log('VirtualBackgroundService: downgrade', LoggingChannels.Remediation, this.resolution, this.fps);
      return true;
    }
    return false;
  }

  private async isReady() {
    if (this.isActive) {
      return true;
    }
    await this.interval.sleep();
    return this.isReady();
  }

  private upgrade() {
    if (this.isActive) {
      if (this.forceResolution) {
        return;
      }
      const now = (new Date()).getTime();
      if (this.resolution === 0.75 && this.fps === 24) {
        return;
      }
      if (now - this.lastDecrease < 30000) {
        return;
      }
      this.lastDecrease = now;
      if (this.fps === 18) {
        this.fps = 24;
        this.resetInterval();
      } else if (this.fps === 24) {
        this.resolution = this.resolution + 0.25;
        this.resetInterval();
      }
      this.logging.log('VirtualBackgroundService: upgrade', LoggingChannels.Remediation, this.resolution, this.fps);
    }
  }

  private resetInterval() {
    clearInterval(this.loopInterval);
    if (this.blurred) {
      this.loopInterval = setInterval(() => this.handleBlur(), 1000 / this.fps);
    } else {
      this.loopInterval = setInterval(() => this.handleBackgroundImg(), 1000 / this.fps);
    }
  }

  private async createSegmenter() {
    const model = 'https://storage.googleapis.com/mediapipe-models/image_segmenter/selfie_multiclass_256x256/float32/latest/selfie_multiclass_256x256.tflite';
    if (this.segmenter) {
      try {
        this.segmenter.close();
      } catch (e) {}
      this.segmenter = null;
    }
    try {
      const vision = await FilesetResolver.forVisionTasks(
        'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm'
      );
      this.segmenter = await ImageSegmenter.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath: model,
          delegate: this.support.isFeatureSupported(FeatureName.WebGL) ? 'GPU' : 'CPU',
        },
        runningMode: 'IMAGE',
        outputCategoryMask: false,
        outputConfidenceMasks: true,
      });
      this.logging.log('VirtualBackgroundService: segmenter ready', LoggingChannels.VirtualBackground);
    } catch(err) {
      this.logging.warning('VirtualBackgroundService: segmenter error', err.message);
    }
  }

  private async handleBackgroundImg() {
    if (this.isRunning) {
      return;
    }

    if (!this.segmenter) {
      this.isRunning = true;
      await this.createSegmenter();
      this.isRunning = false;
      return;
    }

    if (this.video && this.video.videoWidth > 0 && document.body.contains(this.video)) {
      this.video.width = this.video.videoWidth;
      this.video.height = this.video.videoHeight;
      if (this.canvas) {
        try {
          this.isRunning = true;
          this.offscreenCanvas.width = this.video.width;
          this.offscreenCanvas.height = this.video.height;
          this.blur.contexts.mask.canvas.width = this.maskCanvas.width = Math.floor(this.offscreenCanvas.width * this.resolution);
          this.blur.contexts.mask.canvas.height = this.maskCanvas.height = Math.floor(this.offscreenCanvas.height * this.resolution);
          // Draw the current frame to get access to its image data
          this.offscreenContext.drawImage(this.video, 0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height);
          this.maskContext.drawImage(this.video, 0, 0, this.maskCanvas.width, this.maskCanvas.height);
          // Segment the current frame to extract the mask of the person
          this.segmenter.segment(this.maskCanvas, this.processBackgroundImg.bind(this));
        } catch(err) {
          this.logging.log('VirtualBackgroundService: handleBackgroundImg error', LoggingChannels.VirtualBackground, err.message);
          await this.createSegmenter();
          this.isRunning = false;
        }
      } else {
        this.canvas = document.getElementById('home-video-canvas') as HTMLCanvasElement;
        this.context = this.canvas.getContext('2d');
        this.maskCanvas = document.createElement('canvas');
        this.maskContext = this.maskCanvas.getContext('2d', { willReadFrequently: true });
        this.blur.initialize('mask');
      }
    } else {
      this.video = document.getElementById('home-video') as HTMLVideoElement;
      this.canvas = null;
      this.context = null;
    }
  }

  private async processBackgroundImg(result: ImageSegmenterResult) {
    try {
      this.drawMask(result);
      if (!this.showMask) {
        // Draw the background over the masked frame
        this.offscreenContext.globalCompositeOperation = 'destination-over';
        const resizeX = this.backgroundImage.width / this.offscreenCanvas.width;
        const resizeY = this.backgroundImage.height / this.offscreenCanvas.height;
        const resize = 1 / Math.min(resizeX, resizeY);
        const width = Math.floor(this.backgroundImage.width * resize);
        const height = Math.floor(this.backgroundImage.height * resize);
        const x = Math.floor((this.offscreenCanvas.width - this.backgroundImage.width * resize) / 2);
        const y = Math.floor((this.offscreenCanvas.height - this.backgroundImage.height * resize) / 2);
        this.offscreenContext.drawImage(this.backgroundImage, 0, 0, this.backgroundImage.width, this.backgroundImage.height, x, y, width, height);
      }
      this.canvas.width = this.offscreenCanvas.width;
      this.canvas.height = this.offscreenCanvas.height;
      this.context.drawImage(this.offscreenCanvas, 0, 0);
      this.isActive = true;
    } catch(e) {}
    finally {
      this.isRunning = false;
    }
  }

  private async handleBlur() {
    if (this.isRunning) {
      return;
    }

    if (!this.segmenter) {
      this.isRunning = true;
      await this.createSegmenter();
      this.isRunning = false;
      return;
    }

    if (this.video && this.video.videoWidth > 0 && document.body.contains(this.video)) {
      this.video.width = this.video.videoWidth;
      this.video.height = this.video.videoHeight;
      if (this.canvas) {
        try {
          this.isRunning = true;
          this.offscreenCanvas.width = this.video.width;
          this.offscreenCanvas.height = this.video.height;
          this.blur.contexts.mask.canvas.width = this.maskCanvas.width = Math.floor(this.offscreenCanvas.width * this.resolution);
          this.blur.contexts.mask.canvas.height = this.maskCanvas.height = Math.floor(this.offscreenCanvas.height * this.resolution);
          this.blur.contexts.blur.canvas.width = this.blurCanvas.width = Math.floor(this.maskCanvas.width / 2);
          this.blur.contexts.blur.canvas.height = this.blurCanvas.height = Math.floor(this.maskCanvas.height / 2);
          // Draw the current frame to get access to its image data
          this.offscreenContext.drawImage(this.video, 0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height);
          this.maskContext.drawImage(this.video, 0, 0, this.maskCanvas.width, this.maskCanvas.height);
          this.blurContext.drawImage(this.video, 0, 0, this.blurCanvas.width, this.blurCanvas.height);
          // Segment the current frame to extract the mask of the person
          this.segmenter.segment(this.maskCanvas, this.processBlur.bind(this));
        } catch(err) {
          this.logging.log('VirtualBackgroundService: handleBlur error', LoggingChannels.VirtualBackground, err.message);
          await this.createSegmenter();
          this.isRunning = false;
        }
      } else {
        this.canvas = document.getElementById('home-video-canvas') as HTMLCanvasElement;
        this.context = this.canvas.getContext('2d');
        this.maskCanvas = document.createElement('canvas');
        this.maskContext = this.maskCanvas.getContext('2d', { willReadFrequently: true });
        this.blur.initialize('mask');
        this.blurCanvas = document.createElement('canvas');
        this.blurContext = this.blurCanvas.getContext('2d', { willReadFrequently: true });
        this.blur.initialize('blur');
      }
    } else {
      this.video = document.getElementById('home-video') as HTMLVideoElement;
      this.canvas = null;
    }
  }

  private async processBlur(result: ImageSegmenterResult) {
    try {
      this.drawMask(result);
      if (!this.showMask) {
        // Draw the background over the masked frame
        const blurData = this.blurContext.getImageData(0, 0, this.blurCanvas.width, this.blurCanvas.height);
        this.blur.blur('blur', blurData, this.blurDensityState);
        this.offscreenContext.globalCompositeOperation = 'destination-over';
        this.offscreenContext.drawImage(this.blur.contexts.blur.canvas, 0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height);
      }
      this.canvas.width = this.offscreenCanvas.width;
      this.canvas.height = this.offscreenCanvas.height;
      this.context.drawImage(this.offscreenCanvas, 0, 0);
      this.isActive = true;
    } catch(e) {}
    finally {
      this.isRunning = false;
    }
  }

  private drawMask(result: ImageSegmenterResult) {
    if (!this.showMask) {
      const mask = result.confidenceMasks[0].getAsUint8Array();
      const maskImage = this.maskContext.getImageData(0, 0, this.maskCanvas.width, this.maskCanvas.height);
      const maskData = maskImage.data;
      const threshold = this.certaintyThreshold * 255;
      for (let i = 0; i < mask.length; i++) {
        if (mask[i] > threshold) {
          maskData[i * 4] = 0;
          maskData[i * 4 + 1] = 0;
          maskData[i * 4 + 2] = 0;
          maskData[i * 4 + 3] = 0;
        } else {
          maskData[i * 4] = 255;
          maskData[i * 4 + 1] = 0;
          maskData[i * 4 + 2] = 0;
          maskData[i * 4 + 3] = 255;
        }
      }
      this.blur.blurMask(maskImage);
      this.offscreenContext.globalCompositeOperation = 'destination-in';
      this.offscreenContext.drawImage(this.blur.contexts.mask.canvas, 0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height);
    } else {
      const mask = result.confidenceMasks[0].getAsUint8Array();
      const maskImage = this.maskContext.getImageData(0, 0, this.maskCanvas.width, this.maskCanvas.height);
      const maskData = maskImage.data;
      const threshold = this.certaintyThreshold * 255;
      for (let i = 0; i < mask.length; i++) {
        if (mask[i] > threshold) {
          maskData[i * 4] = 0;
          maskData[i * 4 + 1] = 0;
          maskData[i * 4 + 2] = 0;
          maskData[i * 4 + 3] = 0;
        } else {
          maskData[i * 4] = 255;
          maskData[i * 4 + 1] = 0;
          maskData[i * 4 + 2] = 0;
          maskData[i * 4 + 3] = 127;
        }
      }
      this.blur.blurMask(maskImage);
      this.offscreenContext.globalCompositeOperation = 'source-over';
      this.offscreenContext.drawImage(this.blur.contexts.mask.canvas, 0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height);
    }
  }
}
