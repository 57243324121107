export class PeerStatsAudio {
  currentBytesSent = 0;
  prevBytesSent = 0;
  currentSentTimestamp = 0;
  prevSentTimestamp = 0;

  currentBytesReceived = 0;
  prevBytesReceived = 0;
  currentReceivedTimestamp = 0;
  prevReceivedTimestamp = 0;

  roundTripTime = 0;

  estimatedPlayoutTimestamp = 0;

  jitterIn = 0;
  jitterOut = 0;

  packetsLost = 0;
  packetsReceived = 0;

  get incomingBitrate() {
    if (this.currentReceivedTimestamp && this.prevReceivedTimestamp) {
      return ((this.currentBytesReceived - this.prevBytesReceived) / (this.currentReceivedTimestamp - this.prevReceivedTimestamp) * 8 * 1000 / 1024);
    }
    return 0;
  }

  get outgoingBitrate() {
    if (this.currentSentTimestamp && this.prevSentTimestamp) {
      return ((this.currentBytesSent - this.prevBytesSent) / (this.currentSentTimestamp - this.prevSentTimestamp) * 8 * 1000 / 1024);
    }
    return 0;
  }

  get packetsLostPerc() {
    if (this.packetsReceived > 0) {
      return this.packetsLost / this.packetsReceived;
    }
    return 0;
  }

  set bytesSent(bytesSent: number) {
    if (bytesSent > this.currentBytesSent) {
      this.prevBytesSent = this.currentBytesSent;
      this.currentBytesSent = bytesSent;
    }
  }

  set bytesReceived(bytesReceived: number) {
    if (bytesReceived > this.currentBytesReceived) {
      this.prevBytesReceived = this.currentBytesReceived;
      this.currentBytesReceived = bytesReceived;
    }
  }

  set sentTimestamp(timestamp: number) {
    if (timestamp > this.currentSentTimestamp) {
      this.prevSentTimestamp = this.currentSentTimestamp;
      this.currentSentTimestamp = timestamp;
    }
  }

  set receivedTimestamp(timestamp: number) {
    if (timestamp > this.currentReceivedTimestamp) {
      this.prevReceivedTimestamp = this.currentReceivedTimestamp;
      this.currentReceivedTimestamp = timestamp;
    }
  }
}
