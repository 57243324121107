import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AnalyticEventService } from 'src/app/services/analytic-event/analytic-event.service';
import { BillingService } from 'src/app/services/billing/billing.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { LoggingChannels, LoggingService } from 'src/app/services/logging/logging.service';
import { MobileService } from 'src/app/services/mobile/mobile.service';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { ProfileComponent } from 'src/app/components/profile/profile.component';
import { StreamService } from 'src/app/services/stream/stream.service';
import { CelloService } from '../cello/cello.service';

export enum SettingsTabs {
  billing = 'menu.billing',
  upgrade = 'menu.upgrade',
  you = 'menu.you',
  changePassword = 'menu.change_password',
  organization = 'menu.organization',
  redeen = 'menu.redeem',
  mediaSettings = 'room.room_menu.media_settings',
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public activeTab?: SettingsTabs;
  public requestedMediaPermissions = false;
  public modal: HTMLIonModalElement;

  constructor(
    private analytics: AnalyticEventService,
    private billing: BillingService,
    private cello: CelloService,
    private logging: LoggingService,
    private modalCtrl: ModalController,
    private mobile: MobileService,
    private organization: OrganizationService,
    private firebase: FirebaseService,
    private stream: StreamService,
  ) {}

  public async navigateToProfile(tab?: SettingsTabs, marketplacePath = 'users'): Promise<any> {
    const shouldReturn = this.setupActiveTab(tab, marketplacePath);

    if (shouldReturn) {
      return;
    }

    this.dismissModal();

    this.cello.hide();

    this.modal = await this.modalCtrl.create({
      component: ProfileComponent,
      componentProps: {},
      cssClass: 'profile-page-modal',
      backdropDismiss: false,
    });

    this.modal.onDidDismiss().then(() => {
      this.cello.show();

      if (this.mobile.isMobile) {
        this.setActiveTab(null);
      }
    });

    return await this.modal.present();
  }

  public setActiveTab(tab: SettingsTabs) {
    this.activeTab = tab;
    if (tab === SettingsTabs.billing) {
      return this.openBilling();
    }

    if (tab === SettingsTabs.upgrade) {
      return this.analytics.logEvent('upgrade_start');
    }

    if (tab === SettingsTabs.mediaSettings && !this.requestedMediaPermissions) {
      return this.requestMediaPermissions();
    }
  }

  public dismissModal() {
    if (this.modal) {
      this.modal.dismiss();
    }
  }

  public setupActiveTab(tab?: SettingsTabs, marketplacePath?: string): boolean {
    if (tab && this.organization.isMarketplace()) {
      this.organization.loadMarketplace(marketplacePath);

      return true;
    }

    if (this.activeTab) {
      this.activeTab = null;
    }

    if (tab) {
      this.setActiveTab(tab);
    }

    return false;
  }

  public navigateToBillingOrUpgrade() {
    if (this.billing.isFreePlan()) {
      return this.navigateToProfile(SettingsTabs.upgrade, 'apps');
    }

    return this.navigateToProfile(SettingsTabs.billing, 'apps');
  }

  public showOrganization() {
    this.navigateToProfile(SettingsTabs.organization, 'users');
  }

  public openBilling() {
    this.analytics.logEvent('billing_portal');
    this.firebase.functions.call('customerPortal').then((data) => {
      if (data.url) {
        window.open(data.url, '_self');
      }
    });
  }

  public requestMediaPermissions() {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop();
          stream.removeTrack(track);
        });
        this.requestedMediaPermissions = true;
      })
      .catch((error) => {
        this.logging.log('ProfileService: error in media permissions', LoggingChannels.RoomFeature, error);
        this.requestedMediaPermissions = true;
      });
  }

  public hasMediaPermissions(): boolean {
    return !!(this.stream.videoInputId && this.stream.audioInputId);
  }
}
