import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';

import { AppHeaderComponent } from 'src/app/components/app-header/app-header.component';
import { AvatarComponent } from 'src/app/components/avatar/avatar.component';
import { ButtonComponent } from 'src/app/components/UI-Components/button/button.component';
import { NavComponent } from 'src/app/components/nav/nav.component';
import { SignUpToolbarComponent } from 'src/app/components/sign-up-toolbar/sign-up-toolbar.component';
import { AccountSettingsComponent } from '../account-settings/account-settings.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    IonicModule,
  ],
  declarations: [AppHeaderComponent, AccountSettingsComponent, AvatarComponent, ButtonComponent, NavComponent, SignUpToolbarComponent],
  exports: [AppHeaderComponent, AccountSettingsComponent, AvatarComponent, ButtonComponent, NavComponent, SignUpToolbarComponent],
})
export class AppHeaderModule { }
