export enum CallProblemSeverity {
  'warning' = 'warning',
  'error'   = 'danger',
}

export class CallProblem {
  timestamp: number;
  participant: string;
  message: string;
  severity: string;
  notification: boolean;

  constructor(participant: string, message: string, severity: string, notification: boolean = false) {
    this.timestamp = (new Date()).getTime();
    this.participant = participant;
    this.message = message;
    this.severity = severity;
    this.notification = notification;
  }
}
