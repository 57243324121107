import { Injectable } from '@angular/core';
import { Unsubscribe } from 'firebase/firestore';
import { User } from 'firebase/auth';

// Models
import { Organization } from 'src/app/models/organization/organization';

// Services
import { FirebaseService } from '../firebase/firebase.service';
import { IntervalService } from '../interval/interval.service';
import { LoggingChannels, LoggingService } from '../logging/logging.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  public data: Organization;
  public loading: boolean;
  public isAdmin: boolean;
  private dataSub: Unsubscribe;
  private memberSub: Unsubscribe;

  constructor(
    private logging: LoggingService,
    private firebase: FirebaseService,
    private interval: IntervalService,
  ) {
    this.loading = true;
    this.isAdmin = false;
  }

  public async isReady(): Promise<boolean> {
    if (this.data) {
      return true;
    }
    await this.interval.sleep();
    return this.isReady();
  }

  public loadOrganization(user: User, organization: string) {
    if (this.dataSub) {
      this.dataSub();
      this.dataSub = null;
    }
    this.isAdmin = false;
    this.dataSub = this.firebase.firestore.docListen(`organizations/${organization}`, (orgDoc) => {
      if (orgDoc.exists()) {
        this.logging.log('OrganizationService: Loaded organization data', LoggingChannels.Organization);
        const orgData: any = orgDoc.data();

        this.data = new Organization();
        this.data.id = orgDoc.id;
        this.data.name = orgData?.name;
        this.data.ownerId = orgData?.ownerId;
        this.data.stripeCustomerId = orgData?.stripeCustomerId;
        this.data.stripeSubscriptionId = orgData?.stripeSubscriptionId;
        this.data.stripeItemId = orgData?.stripeItemId;
        this.data.active = orgData?.active || true;
        this.data.marketplace = orgData?.marketplace;
        this.data.subscription = orgData?.subscription;
        this.data.memberSeats = orgData?.memberSeats;

        this.loading = false;

        this.loadMember(user, organization);

        if (this.data.marketplace && this.data.active === false) {
          this.firebase.auth.signOut().then(() => {
            window.location.href = this.data.marketplace.baseUrl;
          });
        }
      } else {
        this.loading = false;
      }
    });
  }

  public unloadOrganization() {
    this.data = null;
    this.isAdmin = false;
    if (this.dataSub) {
      this.dataSub();
      this.dataSub = null;
    }
    if (this.memberSub) {
      this.memberSub();
      this.memberSub = null;
    }
  }

  public isMarketplace(): boolean {
    return !!(this.data && this.data.marketplace);
  }

  public loadMarketplace(path = 'users') {
    if (this.isMarketplace()) {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: this.data.marketplace.baseUrl + `/account/${path}`,
      }).click();
    }
  }


  private loadMember(user: User, organization: string) {
    if (this.memberSub) {
      this.memberSub();
      this.memberSub = null;
    }
    if (user && organization) {
      this.memberSub = this.firebase.firestore.docListen(`organizations/${organization}/members/${user.uid}`, (memberDoc) => {
        if (memberDoc.exists()) {
          this.logging.log('OrganizationService: Loaded member data', LoggingChannels.Organization);
          const memberData: any = memberDoc.data();

          if (memberData.role === 'admin') {
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }
        } else {
          this.isAdmin = false;
        }
      });
    }
  }
}
