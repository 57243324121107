import { Injectable } from '@angular/core';
import { FirebaseService } from '../firebase/firebase.service';
import { BaseContactActivity, ContactActivity, ContactActivityFirestore, OnlineStatus } from 'src/app/models/online-status/online-status';
import { serverTimestamp, Timestamp } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class OnlineStatusService {
  constructor(
    private firebase: FirebaseService,
  ) {}

  public setOnlineStatus(user: string, online: boolean, status: OnlineStatus, roomId?: string): Promise<void> {
    roomId = roomId || '';
    return this.firebase.firestore.docUpdate(`users/${user}`, {
      activity: {
        online,
        status,
        roomId,
        timestamp: serverTimestamp(),
      }
    });
  }

  public updateOnlineStatus(userId: string, online = true): Promise<void> {
    return this.setOnlineStatus(userId, online, online ? 'online' : 'busy');
  }

  public setUserBusy(user: string, busy: boolean, roomId?: string): void {
    this.setOnlineStatus(user, !busy, busy ? 'busy' : 'online', busy ? roomId : '');
  }

  public getOnlineStatus(activity: BaseContactActivity): OnlineStatus {
    if (!activity) {
      return 'offline';
    }

    if (activity.status === 'busy') {
      return 'busy';
    }

    if (!activity.online) {
      return 'offline';
    }

    return 'online';
  }

  public getOnlineActivityFromServerData(activity: ContactActivityFirestore, createdOn?: Timestamp): ContactActivity {
    const status = this.getOnlineStatus(activity);
    const timestamp = activity?.timestamp || createdOn || 0;
    const lastActivity = timestamp ? this.firebase.firestore.timestampToDate(timestamp).getTime() : null;
    const online = this.computeOnlineActivity(lastActivity, status);

    return {
      status,
      online,
      lastActivity,
    };
  }

  public getOnlineActivity(activity: ContactActivity): ContactActivity {
    const status = this.getOnlineStatus(activity);
    const lastActivity = activity?.lastActivity || 0;
    const online = this.computeOnlineActivity(lastActivity, status);

    return {
      status,
      online,
      lastActivity,
    };
  }

  public isContactOnline(activity: ContactActivity): boolean {
    const { online } = this.getOnlineActivity(activity);

    return online;
  }

  private computeOnlineActivity(lastActivity: number, status: OnlineStatus): boolean {
    const contactSeenRecently = lastActivity ? Date.now() - lastActivity < 1000 * 60 * 5 : null;
    const online = lastActivity ? contactSeenRecently && status === 'online' : false;

    return online;
  }
}
