import { RemediationProfile } from './remediation-profile';

export class Remediation {
  max: {
    width: number;
    height: number;
    framerate: number;
  };
  local: { [key: string]: RemediationProfile };
  remote: { [key: string]: RemediationProfile };
  last: { [key: string]: number };
  profiles: RemediationProfile[];

  constructor() {
    this.max = {
      width: null,
      height: null,
      framerate: null,
    };
    this.local = {};
    this.remote = {};
    this.last = {};
  }
}
