import { STATS_INTERVAL } from 'src/app/services/call-stats/call-stats.service';
import { PeerStatsParticipant } from './peer-stats-participant';

export class PeerStats {
  availableOutgoingBitrate: number;
  availableIncomingBitrate: number;
  scheduleTimestamp: number;
  scheduleTime: number;
  durationTime: number;
  echoReturnLoss: number;
  echoReturnLossEnhancement: number;
  participantsStats: { [key: string]: PeerStatsParticipant };
  shareStats: { [key: string]: PeerStatsParticipant };
  currentParticipant: string;

  constructor() {
    this.participantsStats = {};
    this.shareStats = {};
  }

  get stVolatility() {
    if (this.scheduleTime > 0 && this.scheduleTime - STATS_INTERVAL > 0) {
      return (this.scheduleTime - STATS_INTERVAL) / STATS_INTERVAL * 100;
    }

    return 0;
  }

  get dtVolatility() {
    if (this.durationTime > 0 && this.durationTime > (STATS_INTERVAL / 10)) {
      return (this.durationTime - (STATS_INTERVAL / 10)) / (STATS_INTERVAL / 5) * 100;
    }

    return 0;
  }

  get incomingBitrate() {
    let sum = 0;
    Object.keys(this.participantsStats).forEach((key) => {
      sum += this.participantsStats[key].incomingBitrate;
    });
    return sum;
  }

  get outgoingBitrate() {
    let sum = 0;
    Object.keys(this.participantsStats).forEach((key) => {
      sum += this.participantsStats[key].outgoingBitrate;
    });
    return sum;
  }

  get nextParticipant() {
    let firstParticipant;
    let nextParticipant;
    let isNext = false;
    const participants = Object.keys(this.participantsStats).sort().filter((key) => key !== 'sharing');
    if (participants.length > 0) {
      participants.forEach((key) => {
        if (!firstParticipant) {
          firstParticipant = key;
        }
        if (isNext) {
          nextParticipant = key;
          isNext = false;
        }
        if (key === this.currentParticipant) {
          isNext = true;
        }
      });
      if (!nextParticipant) {
        nextParticipant = firstParticipant;
      }

      this.currentParticipant = nextParticipant;
      return nextParticipant;
    }

    return null;
  }
}
