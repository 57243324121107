export enum RemediationTypes {
  'bitrate'           = 'bitrate',
  'resolution'        = 'resolution',
  'participants'      = 'participants',
  'remote_bitrate'    = 'remote_bitrate',
  'remote_resolution' = 'remote_resolution',
};

export const remediationRules = {
  'cpu.loop': [
    { type: RemediationTypes.resolution, value: -1 },
    { type: RemediationTypes.remote_resolution, value: -1 },
  ],
  'cpu.encode_time': [
    { type: RemediationTypes.resolution, value: -1 },
  ],
  'cpu.decode_time': [
    { type: RemediationTypes.remote_resolution, value: -1 },
  ],
  'cpu.av_delay': [
    { type: RemediationTypes.resolution, value: -1 },
    { type: RemediationTypes.remote_resolution, value: -1 },
  ],
  'cpu.fps_in': [
    { type: RemediationTypes.resolution, value: -1 },
    { type: RemediationTypes.remote_resolution, value: -1 },
  ],
  'cpu.fps_out': [
    { type: RemediationTypes.resolution, value: -1 },
  ],
  'bandwidth.bandwidth_out': [
    { type: RemediationTypes.bitrate, value: -1 },
  ],
  'bandwidth.bandwidth_in': [
    { type: RemediationTypes.remote_bitrate, value: -1 },
  ],
  'bandwidth.audio_jitter_in': [
    { type: RemediationTypes.remote_bitrate, value: -1 },
  ],
  'bandwidth.audio_jitter_out': [
    { type: RemediationTypes.bitrate, value: -1 },
  ],
  'bandwidth.video_jitter_in': [
    { type: RemediationTypes.remote_bitrate, value: -1 },
  ],
  'bandwidth.video_jitter_out': [
    { type: RemediationTypes.bitrate, value: -1 },
  ],
  'bandwidth.rtt': [
    { type: RemediationTypes.bitrate, value: -1 },
  ],
};
