import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  FirestoreError,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QueryConstraint,
  QuerySnapshot,
  setDoc,
  SetOptions,
  Unsubscribe,
  updateDoc,
  where,
  WhereFilterOp,
  runTransaction,
  Timestamp,
  serverTimestamp,
  Transaction,
} from 'firebase/firestore';

export class CrewdleFirestore {
  private firestore: Firestore;

  constructor(firestore: Firestore) {
    this.firestore = firestore;
  }

  public doc(path: string, ...pathSegments: string[]): DocumentReference<DocumentData> {
    return doc(this.firestore, path, ...pathSegments);
  }

  public docListen(
    path: string,
    onNext: (snapshot: DocumentSnapshot<DocumentData>) => void,
    onError?: (error: FirestoreError) => void,
    onCompletion?: () => void
  ): Unsubscribe {
    return onSnapshot(doc(this.firestore, path), onNext, onError, onCompletion);
  }

  public docGet(path: string): Promise<DocumentSnapshot<DocumentData>> {
    return getDoc(doc(this.firestore, path));
  }

  public docAdd(path: string, data: object): Promise<DocumentReference<object>> {
    return addDoc(collection(this.firestore, path), data);
  }

  public docSet(path: string, data: object, options?: SetOptions): Promise<void> {
    return setDoc(doc(this.firestore, path), data, options);
  }

  public docUpdate(path: string, data: object): Promise<void> {
    return updateDoc(doc(this.firestore, path), data);
  }

  public docDelete(path: string): Promise<void> {
    return deleteDoc(doc(this.firestore, path));
  }

  public collectionGet(path: string, ...queryConstraints: QueryConstraint[]): Promise<QuerySnapshot<DocumentData>> {
    return getDocs(query(collection(this.firestore, path), ...queryConstraints));
  }

  public collectionListen(
    path: string,
    queryConstraints: QueryConstraint[],
    onNext: (snapshot: QuerySnapshot<DocumentData>) => void,
    onError?: (error: FirestoreError) => void,
    onCompletion?: () => void
  ): Unsubscribe {
    return onSnapshot(query(collection(this.firestore, path), ...queryConstraints), onNext, onError, onCompletion);
  }

  public deleteField() {
    return deleteField();
  }

  public where(fieldPath: string, opStr: WhereFilterOp, value: unknown): QueryConstraint {
    return where(fieldPath, opStr, value);
  }

  public collection(path: string, ...pathSegments: string[]): CollectionReference<DocumentData> {
    return collection(this.firestore, path, ...pathSegments);
  }

  public runTransaction<T>(updateFunction: (transaction: Transaction) => Promise<T>): Promise<T> {
    return runTransaction(this.firestore, updateFunction);
  }

  public async getCurrentTimestamp(): Promise<Date|null> {
    const ref = await this.docAdd('timestamps', {
      now: serverTimestamp(),
    });

    const timestamp = await this.listenUntilCurrentTimestampIsAvailable(ref.id);

    return this.timestampToDate(timestamp);
  }

  public timestampToDate(timestamp: Timestamp): Date|null {
    if (!timestamp) {
      return null;
    }

    return timestamp.toDate();
  }

  public dateToTimestamp(date: Date): Timestamp {
    return Timestamp.fromDate(date);
  }

  private async listenUntilCurrentTimestampIsAvailable(id: string): Promise<Timestamp> {
    const promise = new Promise<Timestamp>((resolve) => {
      const unsubscribe = this.docListen(`timestamps/${id}`, (snapshot) => {
        const timestamp = snapshot.data()?.now;
        if (!timestamp || snapshot.metadata.hasPendingWrites) {
          // Still waiting for a timestamp'
          return;
        }

        unsubscribe();
        resolve(timestamp);
      });
    });

    return promise;
  }
}
