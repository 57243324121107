import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { serverTimestamp, where } from 'firebase/firestore';

// Models
import { Contact, Conversation } from 'src/app/services/messaging/models';
import { createFirebaseSubjectListener, FirebaseSubjectListener } from 'src/app/models/firebase-listener/firebase-listener.model';

// Services
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { UserService } from 'src/app/services/user/user.service';
import { OnlineStatusService } from '../online-status/online-status.service';

declare global {
  interface Window {
    contactService: any;
    cs: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  public contacts = new BehaviorSubject<Contact[]>([]);
  public contactsLoaded: Promise<Contact[]>;

  private resolveContacts: (value: Contact[]) => void;

  private contactListener: FirebaseSubjectListener<Subject<Contact[]>>;
  constructor(
    private firebase: FirebaseService,
    private logging: LoggingService,
    private onlineStatus: OnlineStatusService,
    private user: UserService,
  ) {
    window.contactService = window.cs = {
      setAllowedContacts: this.setAllowedContacts.bind(this),
      getAllowedContacts: this.getAllowedContacts.bind(this),
      addAllowedContact: this.addAllowedContact.bind(this),
      removeAllowedContact: this.removeAllowedContact.bind(this),
      fetchContactsWhereUserIsAllowed: this.fetchContactsWhereUserIsAllowed.bind(this),
      updatePresence: this.updatePresence.bind(this),
      getContactById: this.getContactById.bind(this),
    };

    this.contactsLoaded = new Promise(resolve => this.resolveContacts = resolve);
    this.setContactListenerWhenReady();
  }

  public setContactListener(): BehaviorSubject<Contact[]> {
    if (this.contactListener) {
      return this.contacts;
    }

    this.contactListener = this.fetchContactsWhereUserIsAllowed();
    this.contactListener.subject.subscribe(contacts => {
      if (!contacts) {
        this.contacts.next([]);
        this.resolveContacts([]);

        return;
      }

      this.contacts.next(contacts);
      this.resolveContacts(contacts);
    });

    return this.contacts;
  }

  public deleteContactListener() {
    if (this.contactListener) {
      this.contactListener.unsubscribe();
      this.contacts.complete();
      this.contacts = new BehaviorSubject<Contact[]>([]);
      this.contactListener = null;
    }
  }

  // Set allowed contacts in Firestore on /users/{uid}/allowed
  public async setAllowedContacts(contacts: string[]): Promise<void> {
    const uid = this.user.user.uid;
    return await this.firebase.firestore.docSet(`users/${uid}`, { allowed: contacts }, { merge: true });
  }

  // Get allowed contacts from Firestore on /users/{uid}/allowed
  public async getAllowedContacts(): Promise<string[]> {
    const uid = this.user.user.uid;
    const userDoc = await this.firebase.firestore.docGet(`users/${uid}`);
    const contacts: string[] = userDoc.exists ? (userDoc.data()?.allowed || []) : [];

    return contacts;
  }

  // Add a single contact to allowed contacts in Firestore on /users/{uid}/allowed
  public async addAllowedContact(contact: string): Promise<void> {
    const uid = this.user.user.uid;
    const existingContacts = await this.getAllowedContacts();
    const allowed =  existingContacts.indexOf(contact) === -1 ? [...existingContacts, contact] : existingContacts;
    const allowedContacts = { allowed };

    if (allowed.length === existingContacts.length) {
      return;
    }

    return await this.firebase.firestore.docSet(`users/${uid}`, allowedContacts, { merge: true });
  }

  public async removeAllowedContact(contact: string) {
    const userId = this.user.user.uid;
    const existingContacts = await this.getAllowedContacts();
    const allowedContacts = { allowed: existingContacts.filter(c => c !== contact) };
    return await this.firebase.firestore.docSet(`users/${userId}`, allowedContacts, { merge: true });
  }

  public fetchContactsWhereUserIsAllowed(): FirebaseSubjectListener<Subject<Contact[]>> {
    if (!this.user?.user?.uid) {
      throw new Error('User is not logged in');
    }

    const userId = this.user.user.uid;
    const subject = new Subject<Contact[]>();

    let previousContacts: Contact[] = [];
    const unsubscribe = this.firebase.firestore.collectionListen('users', [where('allowed', 'array-contains', userId)], (snapshots) => {
      const contacts: Contact[] = snapshots.docs.map(doc => this.mapContact(doc)).sort(this.compareContacts);

      // Prevent emitting the same contacts over and over again
      if (contacts.length === previousContacts.length && contacts.length > 0) {
        const contactsAreIdentical = contacts.map(c => this.compareContacts(c, previousContacts.find(pc => pc.id === c.id))).every(c => !c);
        if (contactsAreIdentical) {
          return;
        }
      }

      previousContacts = contacts;
      subject.next(contacts);
    });

    return createFirebaseSubjectListener(subject, unsubscribe);
  }

  // Update presence on Firestore on /users/{uid}/online
  public async updatePresence(userId?: string): Promise<void> {
    this.onlineStatus.updateOnlineStatus(userId);
  }

  public async getContactById(id: string): Promise<Contact> {
    const contact = await this.firebase.firestore.docGet(`users/${id}`);
    return this.mapContact(contact);
  }

  public compareContacts(a: Contact, b: Contact): number {
    // If contacts are currently online, they are only compared if not online
    // This prevents contacts from jumping around in the list when they go online
    if (!a.online || !b.online) {
      if (a.lastOnline < b.lastOnline) {
        return 1;
      }
      if (a.lastOnline > b.lastOnline) {
        return -1;
      }
    }

    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  }

  public async deleteContact(contactId: string): Promise<{success: true; data: any}|{success: false; error: any}> {
    const userId = this.user.user.uid;
    try {
      const data = await this.firebase.functions.call('deleteContact', {
        userId,
        contactId,
      });

      return { success: true, data };
    } catch (error) {
      this.logging.error(error);
      return { success:false, error };
    }
  }

  public getContact(id: string): Contact {
    return this.contacts.value.find(c => c.id === id);
  }

  public async getAnonymousContactName(contactId: string, conversation: Conversation): Promise<string> {
    try {

      const participant = conversation.participants.find(({ userId }) => userId === contactId);
      if (!participant) {
        return 'Guest';
      }

      if (participant.name) {
        return participant.name;
      }

    } catch (error) {
      this.logging.error('ContactsService: Error fetching name of anonymous contact', error);

      return 'Guest';
    }
  }

  public async getAnonymousContact(contactId: string, conversation: Conversation): Promise<Contact> {
    const name = await this.getAnonymousContactName(contactId, conversation);

    const defaultData = this.getAnonymousDefaultData(contactId);
    return {
      ...defaultData,
      name,
    };
  }

  public async getDefaultConversationName(contactIds: string[]): Promise<string> {
    await this.contactsLoaded;
    const ids = contactIds.filter(id => id !== this.user.user.uid);
    const contacts = await Promise.all(ids.map(id => this.getContact(id)));
    const names = contacts.map(c => c?.name).sort();
    return names.join(', ');
  }

  private mapContact(contact: any): Contact {
    const data = contact.data();
    if (!data) {
      return this.getAnonymousDefaultData(contact.id);
    }

    const {online, status, lastActivity} = this.onlineStatus.getOnlineActivityFromServerData(data.activity, data.createdOn);
    return {
      id: contact.id,
      name: data.name,
      email: data.email,
      // TODO - Rename this should probably be an activity: ContactActivity object
      online,
      status,
      lastOnline: lastActivity,
      avatar: `https://gravatar.com/avatar/${contact.id}?s=200&d=404`,
    };
  }

  private async setContactListenerWhenReady() {
    await this.user.isReady();
    this.setContactListener();
  }

  private getAnonymousDefaultData(id: string): Contact {
    return {
      id,
      name: 'Guest',
      email: '',
      online: false,
      lastOnline: 0,
      anonymous: true,
    };
  }
}
