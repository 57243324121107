// Angular
import {
  Component,
  Input,
  OnInit,
  HostListener,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

//Services
import { BillingService } from 'src/app/services/billing/billing.service';
import { LanguageChangeService } from 'src/app/services/language-change/language-change.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { MobileService } from 'src/app/services/mobile/mobile.service';
import { ProfileService, SettingsTabs } from 'src/app/services/profile/profile.service';
import { StreamService } from 'src/app/services/stream/stream.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  title: string;

  constructor(
    public billing: BillingService,
    public languageChangeService: LanguageChangeService,
    public mobile: MobileService,
    public profile: ProfileService,
    public stream: StreamService,
    public translate: TranslateService,
    public user: UserService,
    private localStorage: LocalStorageService,
    private logging: LoggingService,
    private modalCtrl: ModalController,
  ) {
    this.title = 'room.settings';
  }

  // TODO - Legacy, remove this for activeTab instead
  public get active(): SettingsTabs {
    return this.profile.activeTab;
  }

  public get activeTab(): SettingsTabs {
    return this.profile.activeTab;
  }

  ngOnInit() {
    this.renameIconTitle();
    this.languageChangeService.lang = this.localStorage.getItem('lang') || 'en';
    if (this.profile.hasMediaPermissions()) {
      this.profile.requestedMediaPermissions = true;
    }

    if (!this.profile.activeTab) {
      return this.profile.activeTab = SettingsTabs.you;
    }

    this.profile.setActiveTab(this.profile.activeTab);
  }

  public showOrganization() {
    this.setActiveTab(SettingsTabs.organization);
  }

  public backToSetting() {
    this.title = 'room.settings';
    this.profile.activeTab = null;
  }

  public setActiveTab(tab) {
    this.title = tab;
    this.profile.setActiveTab(tab);
  }

  public dismissModal() {
    this.modalCtrl.dismiss();
  }

  public Logout() {
    this.dismissModal();
    this.user.logout();
  }

  public setPage(page: SettingsTabs) {
    this.profile.setActiveTab(page);
  }

  private renameIconTitle() {
    setTimeout(() => {
      const ionicons = document.querySelectorAll('ion-icon');
      ionicons.forEach(icon => {
        const iconTitle = icon.shadowRoot.querySelector('.icon-inner svg title');
        if (iconTitle) {
          iconTitle.innerHTML = '';
        }
    });
    },2000);
  }

}
