import { Functions, httpsCallable } from 'firebase/functions';

export class CrewdleFunctions {
  private functions: Functions;

  constructor(functions: Functions) {
    this.functions = functions;
  }

  public call(name: string, options = {}): Promise<any> {
    return httpsCallable(this.functions, name)(options).then((data) => data.data);
  }
}
