import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FeatureSwitchService } from '../feature-switch/feature-switch.service';
import { FirebaseService } from '../firebase/firebase.service';
import { Router } from '@angular/router';

declare global {
  interface Window {
    cello: any;
    CelloAttribution: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CelloService {

  constructor(
    private featureSwitch: FeatureSwitchService,
    private firebase: FirebaseService,
    private router: Router,
  ) {
    if (this.featureSwitch.cello) {
      const trackingScript = document.createElement('script') as HTMLScriptElement;
      trackingScript.async = true;
      trackingScript.type = 'module';
      const attributionScript = document.createElement('script') as HTMLScriptElement;
      attributionScript.async = true;

      if (environment.production) {
        trackingScript.src = 'https://assets.cello.so/app/v2/latest/cello.js';
        attributionScript.src = 'https://assets.cello.so/attribution/latest/cello-attribution.js';
      } else {
        trackingScript.src = 'https://assets.sandbox.cello.so/app/v2/latest/cello.js';
        attributionScript.src = 'https://assets.sandbox.cello.so/attribution/latest/cello-attribution.js';
      }

      window.cello = window.cello || { cmd: [] };
      window.document.head.appendChild(trackingScript);
      window.document.head.appendChild(attributionScript);
    }
  }

  public boot() {
    if (this.featureSwitch.cello) {
      this.firebase.functions.call('getCelloToken', {}).then((res) => {
        const token = res.token;

        window.cello.cmd.push((cello) => {
          cello.boot({
            productId: environment.cello.productId,
            token,
            showOnBoot: this.router.url.includes('/room/') ? false : true,
          });
        });
      });
    }
  }

  public shutdown() {
    if (this.featureSwitch.cello) {
      window.cello.cmd.push((cello) => {
        cello.shutdown();
      });
    }
  }

  public hide() {
    if (this.featureSwitch.cello) {
      window.cello.cmd.push((cello) => {
        cello.hide();
      });
    }
  }

  public show() {
    if (this.featureSwitch.cello) {
      window.cello.cmd.push((cello) => {
        cello.show();
      });
    }
  }

  public setAttribution() {
    if (this.featureSwitch.cello && window.CelloAttribution) {
      const ucc = window.CelloAttribution('getReferral');
      if (ucc) {
        this.firebase.functions.call('setCelloUcc', {
          ucc,
        });
      }
    }
  }
}
