const VIDEO_FIDELITY = 0.06;

export class VideoProfile {
  bitrate: number;
  width: number;
  height: number;
  aspectRatio: number;
  framerate: number;

  constructor(width: number, height: number, framerate: number) {
    this.width = width;
    this.height = height;
    this.aspectRatio = this.width / this.height;
    this.framerate = framerate;
    this.bitrate = this.width * this.height * this.framerate * VIDEO_FIDELITY / 1000;
  }
}
