import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HiddenParticipantsWarningComponent } from 'src/app/modals/hidden-participants-warning/hidden-participants-warning.component';
import { Participant } from 'src/app/models/participant/participant';
import { Session } from 'src/app/models/session/session';
import { IntervalService } from '../interval/interval.service';

@Injectable({
  providedIn: 'root'
})
export class ParticipantsListService {

  participants: { [key: string]: Participant };
  nbParticipants: number;
  allParticipantsArray: Participant[];
  participantsArray: Participant[];
  participantsSortedArray: Participant[];
  userId: string;
  session: Session;
  visibleParticipantNb: number;
  hiddenParticipantsText: string;

  constructor(
    private interval: IntervalService,
    private translate: TranslateService,
    private modalCtrl: ModalController,
  ) {
    this.reset();

    this.visibleParticipantNb = 9;
  }

  public connect(userId: string, session: Session) {
    this.userId = userId;
    this.session = session;
    this.reset();
    this.interval.setInterval('participants-list', () => {
      this.forceUpdate();
    }, 500);
  }

  public forceUpdate() {
    if (this.participants) {
      this.nbParticipants = Object.keys(this.participants).length;
      if (this.nbParticipants > this.visibleParticipantNb) {
        this.hiddenParticipantsText = '+' + (this.nbParticipants - this.visibleParticipantNb);
      }
      const participants: Participant[] = [];
      const allParticipants: Participant[] = [];
      Object.keys(this.participants).forEach((key) => {
        const participant = this.participants[key];
        allParticipants.push(participant);
        if (key !== this.userId) {
          participants.push(participant);
        }
      });

      this.allParticipantsArray = allParticipants;
      this.participantsArray = participants;
      this.participantsSortedArray = this.getParticipants();
    }
  }

  public disconnect() {
    this.reset();
    this.interval.clearInterval('participants-list');
  }

  public getSelf(): Participant {
    return this.participants[this.userId];
  }

  public getParticipantName(id: string): string {
    let participant: Participant = null;
    if (id === this.userId) {
      return this.translate.instant('room.me');
    } else if (this.participants) {
      participant = this.participants[id];
    }
    return participant && participant.userData ? participant.userData.name : '';
  }

  public getParticipants(): Participant[] {
    const participants = [...this.participantsArray];

    let oldSpeakersDisplay = participants
      .filter((value) => value.order < this.visibleParticipantNb)
      .sort((a, b) => a.order - b.order);
    oldSpeakersDisplay.forEach((participant, i) => {
      participant.order = i;
    });
    let nextSpeakerIndex = oldSpeakersDisplay.length;
    oldSpeakersDisplay = oldSpeakersDisplay
      .sort((a, b) => b.lastSpokeTimestamp - a.lastSpokeTimestamp);

    const recentSpeakerIDsDisplay = participants
      .sort((a, b) => b.lastSpokeTimestamp - a.lastSpokeTimestamp)
      .slice(0, this.visibleParticipantNb - 1)
      .map((speaker) => speaker.id);

    this.sortParticipants(participants)
      .forEach((participant, i) => {
        if (
          // Using renderParticipantNb -1 to account for yourself in the rendered view
          i < this.visibleParticipantNb - 1 && (
            recentSpeakerIDsDisplay.includes(participant.id) ||
            participant.raiseHand ||
            this.session.spotlight === participant.id
          )
        ) {
          participant.isHidden = false;
          if (!oldSpeakersDisplay.includes(participant)) {
            if (nextSpeakerIndex < this.visibleParticipantNb - 1) {
              participant.order = nextSpeakerIndex;
              nextSpeakerIndex++;
            } else {
              const oldParticipant = oldSpeakersDisplay.pop();
              participant.order = oldParticipant.order;
              oldParticipant.order = Infinity;
            }
          }
        } else {
          participant.isHidden = true;
        }
      });

    participants.sort((a, b) => a.order - b.order);

    return participants;
  }

  public getSharingParticipantId() {
    return this.allParticipantsArray.reduce(
      (id, participant) => participant.isSharing ? participant.id : id,
      null
    );
  }

  public async showHiddenParticipantsAlert() {
    const alert = await this.modalCtrl.create({
      component: HiddenParticipantsWarningComponent,
      cssClass:
        this.translate.currentLang === 'en'
          ? 'hidden-participants-warning-modal-en'
          : 'hidden-participants-warning-modal-fr',
      componentProps: {
        visibleParticipantNb: this.visibleParticipantNb,
      },
    });
    await alert.present();
  }

  private reset() {
    this.participants = {};
    this.participantsArray = [];
    this.participantsSortedArray = [];
    this.allParticipantsArray = [];
    this.nbParticipants = 0;
  }

  // Business rules:
  //  * Sort current spotlight in first
  //  * Then sort by raised hands
  //  * Then sort by last spoke
  // TODO - Leave space for active speaker if more than
  // `renderParticipantNb` participant currently have their hand raised
  private sortParticipants(participants) {
    return participants
      .sort((a, b) => b.lastSpokeTimestamp - a.lastSpokeTimestamp)
      .sort((a, b) => (a.raiseHand || Infinity) - (b.raiseHand || Infinity))
      .sort((a, b) => (b.id === this.session.spotlight ? 1 : 0) - (a.id === this.session.spotlight ? 1 : 0));
  }
}
