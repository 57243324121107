import {
  deleteObject,
  FirebaseStorage,
  FullMetadata,
  getMetadata,
  listAll,
  ListResult,
  ref,
  SettableMetadata,
  StorageReference,
  updateMetadata
} from 'firebase/storage';

export class CrewdleStorage {
  storage: FirebaseStorage;

  constructor(storage: FirebaseStorage) {
    this.storage = storage;
  }

  getRef(path: string): StorageReference {
    return ref(this.storage, path);
  }

  listAll(storageRef: StorageReference): Promise<ListResult> {
    return listAll(storageRef);
  }

  getMetaData(storageRef: StorageReference): Promise<FullMetadata> {
    return getMetadata(storageRef);
  }

  updateMetadata(storageRef: StorageReference, metadata: SettableMetadata): Promise<FullMetadata> {
    return updateMetadata(storageRef, metadata);
  }

  delete(storageRef: StorageReference): Promise<void> {
    return deleteObject(storageRef);
  }
}
