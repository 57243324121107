import { CallIssue } from 'src/app/models/call-issue/call-issue';
import { Rule } from 'src/app/services/issue-detection/rules/rule';
import { CallProblem, CallProblemSeverity } from 'src/app/models/call-problem/call-problem';

export class OfflineRule implements Rule {
  public run(callIssues: CallIssue[], participant: string, nbParticipants: number): CallProblem {
    if (navigator.onLine === false) {
      return new CallProblem(null, 'offline', CallProblemSeverity.error, true);
    }

    return null;
  }

  public clear(callProblem: CallProblem, callIssues: CallIssue[]): CallIssue[] {
    return callIssues;
  }
}
