import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-caller-modal',
  templateUrl: './caller-modal.component.html',
  styleUrls: ['./caller-modal.component.scss'],
})
export class CallerModalComponent implements OnInit, OnDestroy{
  @Input() callerName: string;
  @Input() calling = false;

  acceptState = false;
  noReplyTimer: any = null;
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.noReplyTimer = setTimeout(() => {
      if (!this.acceptState) {
        this.noReply();
      }
    }, 30000);
  }

  ngOnDestroy() {
    clearTimeout(this.noReplyTimer);
    this.noReplyTimer = null;
  }

  public acceptCall() {
    this.acceptState = true;
    this.modalCtrl.dismiss({
      acceptCall: 'accept',
    });
  }

  public cancelCall() {
    this.acceptState = true;
    this.modalCtrl.dismiss({
      acceptCall: 'cancel',
    });
  }

  public dismissModal() {
    this.acceptState = true;
    this.modalCtrl.dismiss();
  }

  private noReply() {
    this.modalCtrl.dismiss({
      acceptCall: 'no reply',
    });
  }
}
