import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { limit, orderBy, serverTimestamp, where } from 'firebase/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { CallerModalComponent } from 'src/app/modals/caller-modal/caller-modal.component';

// Models

// Services
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { LoggingService, LoggingChannels } from 'src/app/services/logging/logging.service';
import { UserService } from 'src/app/services/user/user.service';
import { AnalyticEventService } from '../analytic-event/analytic-event.service';

// TODO - Move this interface
interface CallingListener {
  callId: string;
  listener: Observable<[boolean, string]>;
}

@Injectable({
  providedIn: 'root'
})
export class VideoCallService {

  private ringTone: HTMLMediaElement;
  private callPopUpModal: HTMLIonModalElement;
  private onCall: string | false = false;

  constructor(
    private analytics: AnalyticEventService,
    private firebase: FirebaseService,
    private modalCtrl: ModalController,
    private logging: LoggingService,
    private router: Router,
    private user: UserService,
  ) {
    this.ringTone =  new Audio('/assets/sound/crewdleCallRingtone.mp3');
  }

  public async createCall(room: string, callees: string[]): Promise<CallingListener> {
    const ref = await this.firebase.firestore.docAdd('videocalls', {
      callerId: this.user.user.uid,
      callerName: this.user.user.displayName,
      callees,
      room,
      status: 'calling',
      timestamp: serverTimestamp(),
    });

    const subject = new BehaviorSubject<[boolean, string]>([null, null]);
    const unsubscribe = this.firebase.firestore.docListen(`videocalls/${ref.id}`, async (call) => {
      if (!call.exists()) {
        subject.next([false, null]);
        subject.complete();
        unsubscribe();
        return;
      }

      const data = call.data();

      if (data.status === 'calling') {
        return;
      }

      if (data.status === 'answered') {
        subject.next([true, 'accepted']);
        subject.complete();
        unsubscribe();
      } else {
        subject.next([false, data.status]);
        subject.complete();
        unsubscribe();
      }
    });

    return {
      callId: ref.id,
      listener: subject.asObservable(),
    };
  }

  public presentCallerPopup(callId: string) {
    return this.createCallPopup('', true, callId);
  }

  public async setupVideoCallListener() {
    await this.user.isReady();
    let modal: HTMLIonModalElement;

    let ignoreCall: string;
    this.firebase.firestore.collectionListen('videocalls', [
      where('callees', 'array-contains', this.user.user.uid),
      orderBy('timestamp', 'desc'),
      limit(1),
    ], async (snapshots) => {
      if (snapshots.size === 0) {
        return ;
      }

      if (this.user.data.status !== 'online') {
        return;
      }

      const callDoc = snapshots.docs[0];
      const call = callDoc.data();

      if (ignoreCall === callDoc.id) {
        return;
      }

      if (call.status === 'cancel' || call.status === 'no reply') {
        if (modal) {
          modal.dismiss();
        }

        return;
      }

      if (call.callerId === this.user.user.uid) {
        return;
      }

      if (this.onCall === callDoc.id) {
        return ;
      }

      if (!call || call.status !== 'calling') {
        return ;
      }

      if (await this.isCallExpired(call)) {
        ignoreCall = callDoc.id;
        this.setExpiredCall(callDoc.id);
        return;
      }

      modal = await this.presentCalleePopup(call.callerName);

      const dismissData = (await modal.onDidDismiss()).data;
      const callStatus = dismissData ? dismissData.acceptCall : 'rejected';

      if (callStatus === 'accept') {
        this.onCall = callDoc.id;

        await this.firebase.firestore.docUpdate(`videocalls/${callDoc.id}`, {
          status: 'answered',
        });

        this.analytics.logEvent('video_call_answered');

        this.router.navigate(['/room', call.room]);
      }
    });
  }

  public async deleteCall(callId: string) {
    await this.firebase.firestore.docDelete(`videocalls/${callId}`);
  }

  private async presentCalleePopup(callerName: string) {
    return this.createCallPopup(callerName, false);
  }

  private async createCallPopup(callerName: string, calling: boolean, callId?: string) {
    this.ringTone.play().catch(error => this.logging.log('VideoCallService: error in callPopup', LoggingChannels.Contact, error));
    this.callPopUpModal = await this.modalCtrl.create({
      component: CallerModalComponent,
      cssClass: 'caller-modal-size',
      componentProps: {
        callerName,
        calling,
      },
      backdropDismiss: false,
    });

    this.callPopUpModal.onDidDismiss().then((dismissEvent) => {
      this.ringTone.pause();
      if (calling && callId && dismissEvent.data && dismissEvent.data.acceptCall !== 'accept') {
        this.firebase.firestore.docUpdate(`videocalls/${callId}`, {
          status: dismissEvent.data.acceptCall,
        });
      }

      return dismissEvent;
    });

    await this.callPopUpModal.present();

    return this.callPopUpModal;
  }

  private async isCallExpired(call: any): Promise<boolean> {
    const startCallTime = call?.timestamp ? this.firebase.firestore.timestampToDate(call.timestamp).getTime() : 0;
    const currentTimestamp = (await this.firebase.firestore.getCurrentTimestamp()).getTime();
    const timeSinceCall = currentTimestamp - startCallTime;

    if (timeSinceCall > 45000) {
      return true;
    }

    return false;
  }

  private async setExpiredCall(callId: string) {
    await this.firebase.firestore.docUpdate(`videocalls/${callId}`, {
      status: 'expired',
    });
  }
}
