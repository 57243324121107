export class SignalingMessage {
    uid: string;
    msg: any;
    status: string;
    waiting: number;

    constructor(uid: string, msg: any) {
      this.uid = uid;
      this.msg = msg;
      this.status = 'unsent';
      this.waiting = 0;
    }
  }
