import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeatureSwitchService {

  public chat: boolean;
  public callstats: boolean;
  public showStats: boolean;
  public contacts: boolean;
  public organizationContacts: boolean;
  public messaging: boolean;
  public audioCall: boolean;
  public renderCanvas: boolean;
  public debugRestart: boolean;
  public debugProfile: boolean;
  public freeScreenSharing: boolean;
  public privateChat: boolean;
  public waitingRoom: boolean;
  public largeEvents: boolean;
  public cello: boolean;

  constructor() {
    this.renderCanvas = false;
    this.chat = true;
    this.callstats = true;
    this.showStats = true;
    this.contacts = true;
    this.organizationContacts = false;
    this.messaging = false;
    this.audioCall = true;
    this.debugRestart = false;
    this.debugProfile = false;
    this.freeScreenSharing = false;
    this.privateChat = false;
    this.waitingRoom = true;
    this.largeEvents = false;
    this.cello = true;
  }
}
