import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() className: string;
  @Input() avatarLink: string;
  @Input() avatarName: string;
  @Input() dataToolTip: string;
  @Input() isOnline: string;
  @Input() loaded = false;
  @Output() avatarClick = new EventEmitter();
  @Output() avatarLinkUpdate = new EventEmitter<string>();

  private avatarColors = [
    '#6C757D', '#495057', '#212529', '#76C893', '#52B69A', '#34A0A4',
    '#B69121', '#A47E1B', '#926C15', '#805B10', '#76520E', '#023047',
    '#DC2F02', '#E85D04', '#F48C06', '#FB8500', '#F25C54', '#F27059',
    '#C1903E', '#C79A50', '#E56B6F', '#B56576', '#6D597A', '#355070',
    '#582F0E', '#7F4F24', '#936639', '#A68A64', '#656D4A', '#414833',
    '#3A5D2F', '#808F48', '#184E77', '#1E6091', '#1A759F', '#168AAD'
  ];

  constructor() {}

  ngOnInit() {}

  public clicked($event) {
    this.avatarClick.emit($event);
  }

  public get firstInitial(): string {
    return this.avatarName ? this.avatarName.charAt(0).toUpperCase() : '';
  }

  public get color(): string {
    if (!this.avatarName) {
      return this.avatarColors[0];
    }

    const colorPosition = this.hashCode(this.avatarName) % this.avatarColors.length;
    return this.avatarColors[colorPosition];

  }

  public hideImageOnError() {
    this.avatarLink = '';
    this.avatarLinkUpdate.emit(this.avatarLink);
  }

  public displayAvatar() {
    this.loaded = true;
  }

  private hashCode(str: string): number {
    if (str.length === 0) {
      return 0;
    }

    let hash = 0;
    for (const char of str) {
      const charCode = char.charCodeAt(0);
      hash  = ((hash << 5) - hash) + charCode; // eslint-disable-line no-bitwise
      hash |= 0; // eslint-disable-line no-bitwise
    }
    return Math.abs(hash);
  }
}
