import { Injectable } from '@angular/core';
import { AbstractVolumeStream } from '../abstract-volume-stream/abstract-volume-stream';
import { NotificationService } from '../../services/notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class MicStream extends AbstractVolumeStream {
  constructor() {
    super();
  }
}
