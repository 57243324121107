export enum billingSupport {
  'forum'       = 'forum',
  'email9to5'   = 'email9to5',
};

enum billingPeriod {
  'monthly'     = 'monthly',
  'yearly'      = 'yearly',
}

interface billingPricing {
  price: number;
  productId?: string | number;
  period?: billingPeriod;
};

interface billingDuration {
  oneOnOne: number;
  group: number;
}

interface billingRecording {
  local: boolean;
  cloud: boolean;
  cloudStorage: number;
}

export interface billingPlan {
  id: string;
  active: boolean;
  legacyId?: string;
  pricing: billingPricing;
  maxParticipants: number;
  maxVisibleParticipants: number;
  userManagement: boolean;
  meetingDuration: billingDuration;
  personalLink: boolean;
  recording: billingRecording;
  liveStreaming: boolean;
  screenSharing: boolean;
  virtualBackgrounds: boolean;
  hostNotRequired: boolean;
  roomPermissions: boolean;
  remoteControls: boolean;
  waitingRoom: boolean;
  integrations: boolean;
  support: billingSupport;
};

export const billingPlans: billingPlan[] = [
  {
    id: 'free',
    active: true,
    pricing: {
      price: 0,
    },
    maxParticipants: 12,
    maxVisibleParticipants: 9,
    userManagement: false,
    meetingDuration: {
      oneOnOne: Infinity,
      group: Infinity,
    },
    personalLink: true,
    recording: {
      local: false,
      cloud: false,
      cloudStorage: null,
    },
    liveStreaming: false,
    screenSharing: false,
    virtualBackgrounds: false,
    hostNotRequired: false,
    roomPermissions: false,
    remoteControls: false,
    waitingRoom: false,
    integrations: true,
    support: billingSupport.forum,
  },
  {
    id: 'pro',
    active: true,
    pricing: {
      price: 9,
      productId: 'pro',
      period: billingPeriod.yearly,
    },
    maxParticipants: 30,
    maxVisibleParticipants: 9,
    userManagement: false,
    meetingDuration: {
      oneOnOne: Infinity,
      group: Infinity,
    },
    personalLink: true,
    recording: {
      local: true,
      cloud: false,
      cloudStorage: null,
    },
    liveStreaming: false,
    screenSharing: true,
    virtualBackgrounds: true,
    hostNotRequired: true,
    roomPermissions: true,
    remoteControls: false,
    waitingRoom: false,
    integrations: true,
    support: billingSupport.forum,
  },
  {
    id: 'business',
    active: true,
    legacyId: 'lite',
    pricing: {
      price: 39,
      productId: 'business',
      period: billingPeriod.yearly,
    },
    maxParticipants: 100,
    maxVisibleParticipants: 9,
    userManagement: true,
    meetingDuration: {
      oneOnOne: Infinity,
      group: Infinity,
    },
    personalLink: true,
    recording: {
      local: true,
      cloud: true,
      cloudStorage: 1,
    },
    liveStreaming: true,
    screenSharing: true,
    virtualBackgrounds: true,
    hostNotRequired: true,
    roomPermissions: true,
    remoteControls: true,
    waitingRoom: true,
    integrations: true,
    support: billingSupport.email9to5,
  },
  {
    id: 'small_business',
    active: true,
    pricing: {
      price: 195,
      productId: 'small_business',
      period: billingPeriod.yearly,
    },
    maxParticipants: 30,
    maxVisibleParticipants: 9,
    userManagement: true,
    meetingDuration: {
      oneOnOne: Infinity,
      group: Infinity,
    },
    personalLink: true,
    recording: {
      local: true,
      cloud: true,
      cloudStorage: 1,
    },
    liveStreaming: true,
    screenSharing: true,
    virtualBackgrounds: true,
    hostNotRequired: true,
    roomPermissions: true,
    remoteControls: true,
    waitingRoom: true,
    integrations: true,
    support: billingSupport.email9to5,
  },
];
