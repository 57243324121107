import { Observable } from 'rxjs';
import { bufferCount, share } from 'rxjs/operators';

export class AudioStreamWorkletLoader {
  stream: MediaStream;
  volume: number;
  loaded: boolean;
  connected: boolean;

  private audioContext: AudioContext;
  private processor: AudioWorkletNode;
  private volumeEventObs: Observable<any>;
  private observer: Observable<any>;
  private sampleSize?: number|null;

  private readonly DEFAULT_SAMPLE_SIZE = 1;

  constructor(
    audioContext: AudioContext,
    sampleSize?: number|null,
  ) {
    this.stream = new MediaStream();
    this.volume = 0;
    this.loaded = false;
    this.connected = false;
    this.audioContext = audioContext;
    this.sampleSize = sampleSize || this.DEFAULT_SAMPLE_SIZE;
  }

  async load(resolve, reject) {
    if (this.loaded) {
      return;
    }

    this.loaded = true;

    try {
      // @ts-ignore
      await this.audioContext.audioWorklet.addModule('/js/volume-processor/volume-processor.js');

      const volumeProcessorNode = new AudioWorkletNode(this.audioContext, 'volume-processor');
      volumeProcessorNode.port.postMessage(JSON.stringify({
        type: 'init',
      }));


      this.volumeEventObs = new Observable((subscriber) => {

        volumeProcessorNode.port.onmessage = e => {
          // Ignore initialization message in volume observer
          if (e.data.indexOf('Init') > -1) {
            return true;
          }

          try {
            const data = JSON.parse(e.data);

            if (data.refresh === true && data.volume >= 0) {
              this.volume = data.volume;
              subscriber.next(data.volume);
            }

            if (data.close === true) {
              subscriber.complete();
            }
          } catch (err) {
            console.error('AudioStreamWorkletLoader: error decoding volume payload', e, err);
            reject('AudioStreamWorkletLoader: error decoding volume payload');
          }
        };
      }).pipe(share());

      this.observer = this.volumeEventObs.pipe(
        bufferCount(this.sampleSize, this.sampleSize)
      );

      this.processor = volumeProcessorNode;
      resolve('AudioStreamWorkletLoader: Volume Stream Observer initialized');

    } catch (e) {
      console.error('AudioStreamWorkletLoader: Error loading volume processor', e);
    }
  }

  connect(source, destination) {
    return source.connect(this.processor).connect(destination);
  }

  unload() {
    delete this.processor;
    delete this.loaded;
  }

  disconnect() {
    if (!this.processor) {
      return;
    }

    this.processor.port.postMessage(JSON.stringify({
      type: 'close-processor'
    }));

    this.connected = false;
  }

  getObserver() {
    return this.observer;
  }

  getUnbufferedVolumeObserver() {
    return this.volumeEventObs;
  }

}
