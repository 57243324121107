import { PeerStatsAudio } from './peer-stats-audio';
import { PeerStatsVideo } from './peer-stats-video';

export class PeerStatsParticipant {
  id: string;
  audio: PeerStatsAudio;
  video: PeerStatsVideo;

  constructor(id: string) {
    this.id = id;
    this.audio = new PeerStatsAudio();
    this.video = new PeerStatsVideo();
  }

  get incomingBitrate() {
    return this.audio.incomingBitrate + this.video.incomingBitrate;
  }

  get outgoingBitrate() {
    return this.audio.outgoingBitrate + this.video.outgoingBitrate;
  }

  get rtt() {
    return Math.floor((this.audio.roundTripTime + this.video.roundTripTime) / 2 * 1000);
  }

  get avDelay() {
    return this.audio.estimatedPlayoutTimestamp - this.video.estimatedPlayoutTimestamp;
  }
}
