import {
  Database,
  DataSnapshot,
  get,
  goOffline,
  goOnline,
  off,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  OnDisconnect,
  onDisconnect,
  onValue,
  push,
  ref,
  remove,
  set,
  ThenableReference,
  Unsubscribe,
  update
} from 'firebase/database';

export class CrewdleDatabase {
  private database: Database;

  constructor(database: Database) {
    this.database = database;
  };

  public refPush(path: string, value: unknown): ThenableReference {
    return push(ref(this.database, path), value);
  }

  public refUpdate(path: string, options: object): Promise<void> {
    return update(ref(this.database, path), options);
  }

  public refSet(path: string, value: unknown): Promise<void> {
    return set(ref(this.database, path), value);
  }

  public refRemove(path: string): Promise<void> {
    return remove(ref(this.database, path));
  }

  public refListenValue(path: string, callback: (snapshot: DataSnapshot) => unknown): Unsubscribe {
    return onValue(ref(this.database, path), callback);
  }

  public refListenChildAdded(path: string, callback: (snapshot: DataSnapshot) => unknown): Unsubscribe {
    return onChildAdded(ref(this.database, path), callback);
  }

  public refListenChildChanged(path: string, callback: (snapshot: DataSnapshot) => unknown): Unsubscribe {
    return onChildChanged(ref(this.database, path), callback);
  }

  public refListenChildRemoved(path: string, callback: (snapshot: DataSnapshot) => unknown): Unsubscribe {
    return onChildRemoved(ref(this.database, path), callback);
  }

  public refOff(path: string): void {
    return off(ref(this.database, path));
  }

  public refGetValue(path: string): Promise<DataSnapshot> {
    return get(ref(this.database, path));
  }

  public refOnDisconnect(path: string): OnDisconnect {
    return onDisconnect(ref(this.database, path));
  }

  public goOffline(): void {
    return goOffline(this.database);
  }

  public goOnline(): void {
    return goOnline(this.database);
  }
}
