interface SubjectLike {
  unsubscribe(): void;
  complete(): void;
}

export interface FirebaseSubjectListener<T extends SubjectLike> {
  subject: T;
  unsubscribe: () => void;
}

export function createFirebaseSubjectListener<T extends SubjectLike>(
  subject: T,
  unsubscribe: () => void
): FirebaseSubjectListener<T> {
  return {
    subject,
    unsubscribe: () => {
      subject.complete();
      subject.unsubscribe();
      unsubscribe();
    }
  };
}
