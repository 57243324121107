import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

// Models
import { FirebaseSubjectListener } from 'src/app/models/firebase-listener/firebase-listener.model';
import { Conversation, ConversationList, Message } from 'src/app/services/messaging/models';
import { MessagingService } from 'src/app/services/messaging/messaging.service';
import { UserService } from 'src/app/services/user/user.service';
import { BrowserNotificationService } from '../browser-notification/browser-notification.service';

type LastMessagesReadMap = { [conversationId: Conversation['id']]: Message['id'] };

@Injectable({
  providedIn: 'root'
})
export class MessagingListenerService {

  public conversations: ConversationList;
  public listener: BehaviorSubject<ConversationList>;

  private initialized: boolean;
  private lastMessageReadIds: LastMessagesReadMap = {};
  private messagesListener: FirebaseSubjectListener<Subject<ConversationList>>;
  constructor(
    private browserNotification: BrowserNotificationService,
    private messaging: MessagingService,
    private router: Router,
    private user: UserService,
  ) {
    this.browserNotification.requestPermission();
  }

  public listen() {
    if (this.listener) {
      return this.listener;
    }

    this.listener = new BehaviorSubject<ConversationList>(null);

    this.messagesListener = this.messaging.listenConversations();
    this.messagesListener.subject.subscribe(async conversations => {
      if (!conversations) {
        return;
      }

      if (!this.conversations) {
        this.lastMessageReadIds = await this.fetchLastMessageReadIds(conversations);
        this.conversations = conversations;
      }

      const oldConversations = [...this.conversations.map(conversation => ({ ...conversation }))];
      if (this.conversations.length > conversations.length) {
        [...Array(this.conversations.length - conversations.length)].forEach(() => this.conversations.pop());
      }

      if (this.conversations.length < conversations.length) {
        const newConversationsCount = conversations.length - this.conversations.length;
        const nextIndex = this.conversations.length;
        [...Array(newConversationsCount)].forEach((_, index) => this.conversations.push(conversations[nextIndex + index]));
      }

      conversations.forEach((conversation, index) => {
        delete conversation.newMessageCount;
        Object.keys(conversation).forEach(key => (this.conversations[index][key] = conversation[key]));
      });

      const promises = conversations.map(async (conversation, index) => {
        const oldConversation = oldConversations?.find(({ id }) => id === conversation.id);
        if (!this.initialized || oldConversation?.lastMessage?.id !== conversation.lastMessage?.id) {
          if (this.initialized) {
            if (this.checkIfInConversation(conversation.lastMessage?.sender, this.user.user.uid, conversation.lastMessage?.content, conversation)) {
              const notification = await this.browserNotification.showNotification(conversation.name, {
                body: conversation.lastMessage?.content,
                icon: '/assets/icon/C_icon_only.svg',
              });

              if (notification) {
                notification.onclick = () => {
                  this.router.navigate(['/messaging'], { queryParams: { conversationId: conversation.id } });
                  window.focus();
                };
              }
            }
          }

          if (conversation.lastMessage?.sender !== this.user.user.uid) {
            return await this.getNewMessageCount(conversation, this.lastMessageReadIds[conversation.id]);
          }

          return '0';
        }

        return oldConversations[index].newMessageCount;
      });

      this.initialized = true;
      const newMessageCounts = await Promise.all(promises);
      this.conversations.forEach((conversation, index) => conversation.newMessageCount = newMessageCounts[index]);
      this.listener.next(this.conversations);
      // this.updateConversationOnline(true);
      // this.interval.setInterval(this.converstionOnlineInterval, () => this.updateConversationOnline(true), 1 * 60 * 1000);
    });

    return this.listener;
  }

  private checkIfInConversation(sender, user, lastMessage, conversation) {
    if (sender !== user && lastMessage) {
      if(document.visibilityState !== 'visible' || window.location.href.indexOf(conversation.id) === -1) {
        return true;
      }
    } else {
      return false;
    }
  }

  public async getNewMessageCount(conversation: Partial<Conversation>, lastMessageId: string): Promise<string> {
    const messageCount = await this.getMessagesCountFromMessageId(conversation.id, lastMessageId);
    if (messageCount > 9) {
      return '9+';
    }

    return messageCount.toString();
  }

  public async fetchLastMessageReadIds(conversations: ConversationList): Promise<LastMessagesReadMap> {

    const promises = conversations.map(async conversation => ({
        conversationId: conversation.id,
        lastReadMessageId: await this.messaging.fetchLastReadMessageId(conversation.id, this.user.user.uid),
    }));

    const lastMessageReadIdsArray = await Promise.all(promises);
    const lastMessageReadIds: LastMessagesReadMap = lastMessageReadIdsArray.reduce(
      (acc, { conversationId, lastReadMessageId }) => ({ ...acc, [conversationId]: lastReadMessageId }), {}
    );

    return lastMessageReadIds;
  }

  public updateCachedLastMessageRead(conversationId: Conversation['id'], messageId: Message['id']) {
    this.lastMessageReadIds[conversationId] = messageId;
  }

  private async getMessagesCountFromMessageId(conversationId: string, lastMessageId: string): Promise<number> {
    if (!lastMessageId) {
      return (await this.messaging.getLastMessagesWithLimit(conversationId, 10)).size;
    }

    return await this.messaging.getMessagesCountAfter(conversationId, lastMessageId);
  }

}
