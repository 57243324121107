import { Injectable } from '@angular/core';
import { ToastButton, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private toastCtrl: ToastController,
    private translate: TranslateService,
  ) {
  }

  public async display(
    message: string,
    color = 'primary',
    position: 'bottom' | 'middle' | 'top' = 'bottom',
    duration = 2000,
    buttons?: (string | ToastButton)[],
    cssClass?: string
  ) {
    const toast = await this.toastCtrl.create({
      message: this.translate.instant(message),
      position,
      duration,
      color,
      cssClass,
      buttons,
    });

    toast.present();
  }
}
