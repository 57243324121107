import * as MD5 from 'crypto-js/md5';
import { OnlineStatus } from '../online-status/online-status';

export class UserData {
  name: string;
  email: string;
  language: string;
  blurredBackground: string;
  activeBackgroundImage: string;
  uploadedImages: string;
  hash: string;
  organization: string;
  roomId: string;
  usageTime: number;
  needOrganization: boolean;
  newSubscription: boolean;
  subscription: string;
  status: OnlineStatus;

  setEmail(email: string): void {
    this.email = email;
    if (email) {
      this.hash = MD5(email.toLowerCase()).toString();
    }
  }
}
