import { Injectable } from '@angular/core';
import adapter from 'webrtc-adapter';
import { LoggingChannels, LoggingService } from '../logging/logging.service';
import { Platform } from '@ionic/angular';

interface FeatureSupport {
  name: FeatureName;
  supported: boolean;
}

export enum FeatureName {
  WebRTC = 'WebRTC',
  MediaStream = 'MediaStream',
  ScreenSharing = 'ScreenSharing',
  AudioVolume = 'AudioVolume',
  DeviceConfig = 'DeviceConfig',
  AudioOutput = 'AudioOutput',
  Recording = 'Recording',
  Backgrounds = 'Backgrounds',
  WebGL = 'WebGL'
}

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  public browser: string;
  public browserVersion: number;
  public featureSupport: FeatureSupport[] = [];

  constructor(
    private logging: LoggingService,
    private platform: Platform,
  ) {
    this.browser = adapter.browserDetails.browser;
    this.browserVersion = adapter.browserDetails.version;
    this.logging.log('SupportService: browser', LoggingChannels.Default, this.browser, this.browserVersion);

    this.checkWebRTC();
    this.checkMediaStream();
    this.checkScreenSharing();
    this.checkAudioVolume();
    this.checkDeviceConfig();
    this.checkAudioOutput();
    this.checkMediaRecording();
    this.checkBackgrounds();
    this.checkWebGL();
  }

  public isFeatureSupported(featureName: FeatureName): boolean {
    const feature = this.featureSupport.find(f => f.name === featureName);
    return feature ? feature.supported : false;
  }

  private checkWebRTC(): void {
    const featureName = FeatureName.WebRTC;
    let supported = false;

    if (typeof RTCPeerConnection === 'function' && 
        typeof RTCIceCandidate === 'function' && 
        typeof RTCSessionDescription === 'function') {
      supported = true;
    } else {
      this.logging.log(`SupportService: ${featureName} not supported`, LoggingChannels.Default);
    }

    if (window.navigator.userAgent) {
      if (!this.platform.is('capacitor') && /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv)/i.test(window.navigator.userAgent)) {
        supported = false;
        this.logging.log(`SupportService: ${featureName} not supported`, LoggingChannels.Default, window.navigator.userAgent);
      }
    }

    if (this.browser === 'chrome' && this.browserVersion < 104 && this.browserVersion !== null) {
      supported = false;
      this.logging.log(`SupportService: ${featureName} not supported - old Chrome`, LoggingChannels.Default);
    }
    if (this.browser === 'firefox' && this.browserVersion < 104) {
      supported = false;
      this.logging.log(`SupportService: ${featureName} not supported - old Firefox`, LoggingChannels.Default);
    }
    try {
      if (
        this.browser === 'safari' &&
        (
          this.browserVersion < 15 ||
          !window.navigator.userAgent ||
          (
            window.navigator &&
            window.navigator.userAgent &&
            typeof window.navigator.userAgent === 'string' &&
            parseInt(window.navigator.userAgent.match(/Version\/([^\.]+)/i)[1], 10) < 15
          )
        )
      ) {
        supported = false;
        this.logging.log(`SupportService: ${featureName} not supported - old Safari`, LoggingChannels.Default);
      }
    } catch(err) {
      this.logging.log('SupportService: unrecognized Safari user agent', LoggingChannels.Default, window.navigator.userAgent);
    }
    if (this.browser === 'edge' && this.browserVersion < 104) {
      supported = false;
      this.logging.log(`SupportService: ${featureName} not supported - old Edge`, LoggingChannels.Default);
    }
    if (this.browser === 'opera' && this.browserVersion < 90) {
      supported = false;
      this.logging.log(`SupportService: ${featureName} not supported - old Opera`, LoggingChannels.Default);
    }

    this.featureSupport.push({ name: featureName, supported });
  }

  private checkMediaStream(): void {
    const featureName = FeatureName.MediaStream;
    let supported = false;

    if (typeof MediaStream === 'function' &&
        typeof MediaStreamTrack === 'function' &&
        typeof navigator === 'object' &&
        typeof navigator.mediaDevices === 'object' &&
        typeof navigator.mediaDevices.getUserMedia === 'function') {
      supported = true;
    } else {
      this.logging.log(`SupportService: ${featureName} not supported`, LoggingChannels.Default);
    }

    this.featureSupport.push({ name: featureName, supported });
  }

  private checkScreenSharing(): void {
    const featureName = FeatureName.ScreenSharing;
    let supported = false;

    if (typeof navigator === 'object' &&
        typeof navigator.mediaDevices === 'object' &&
        typeof navigator.mediaDevices['getDisplayMedia'] === 'function') {
      supported = true;
    } else {
      this.logging.log(`SupportService: ${featureName} not supported`, LoggingChannels.Default);
    }

    this.featureSupport.push({ name: featureName, supported });
  }

  private checkAudioVolume(): void {
    const featureName = FeatureName.AudioVolume;
    let supported = false;

    if (typeof AudioContext === 'function' &&
        typeof ScriptProcessorNode === 'function' &&
        typeof MediaStreamAudioSourceNode === 'function') {
      supported = true;
    } else {
      this.logging.log(`SupportService: ${featureName} not supported`, LoggingChannels.Default);
    }

    this.featureSupport.push({ name: featureName, supported });
  }

  private checkDeviceConfig(): void {
    const featureName = FeatureName.DeviceConfig;
    let supported = false;

    if (typeof navigator === 'object' &&
        typeof navigator.mediaDevices === 'object' &&
        typeof navigator.mediaDevices.enumerateDevices === 'function' &&
        typeof MediaDeviceInfo === 'function') {
      supported = true;
    } else {
      this.logging.log(`SupportService: ${featureName} not supported`, LoggingChannels.Default);
    }

    this.featureSupport.push({ name: featureName, supported });
  }

  private checkAudioOutput(): void {
    const featureName = FeatureName.AudioOutput;
    let supported = false;

    const video = document.createElement('video')
    if (typeof video['setSinkId'] === 'function') {
      supported = true;
    } else {
      this.logging.log(`SupportService: ${featureName} not supported`, LoggingChannels.Default);
    }

    this.featureSupport.push({ name: featureName, supported });
  }

  private async checkMediaRecording(): Promise<void> {
    const featureName = FeatureName.Recording;
    let supported = false;

    try {
      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      if (!!window.MediaRecorder && !!window.MediaSource && canvas.captureStream) {
        const stream = canvas.captureStream();
        const recorder = new MediaRecorder(stream);
        recorder.start();
        await new Promise(resolve => setTimeout(resolve, 2000));
        recorder.stop();
        supported = recorder.state === 'inactive';
      }
    } catch (err) {
      this.logging.log(`SupportService: ${featureName} check failed`, LoggingChannels.Default, err);
    }

    this.featureSupport.push({ name: featureName, supported });
    if (!supported) {
      this.logging.log(`SupportService: ${featureName} not supported`, LoggingChannels.Default);
    }
  }

  private checkBackgrounds(): void {
    const featureName = FeatureName.Backgrounds;
    let supported = false;

    try {
      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      if (!!canvas.captureStream) {
        supported = true;
      }
    } catch (err) {
      this.logging.log(`SupportService: ${featureName} check failed`, LoggingChannels.Default, err);
    }

    this.featureSupport.push({ name: featureName, supported });
    if (!supported) {
      this.logging.log(`SupportService: ${featureName} not supported`, LoggingChannels.Default);
    }
  }

  private checkWebGL(): void {
    const featureName = FeatureName.WebGL;
    let supported = false;

    try {
      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      if (!!window.WebGLRenderingContext && canvas.getContext('webgl')) {
        supported = true;
      }
    } catch (err) {
      this.logging.log(`SupportService: ${featureName} check failed`, LoggingChannels.Default, err);
    }

    this.featureSupport.push({ name: featureName, supported });
    if (!supported) {
      this.logging.log(`SupportService: ${featureName} not supported`, LoggingChannels.Default);
    }
  }
}
