import { CallIssue, CallIssueType } from 'src/app/models/call-issue/call-issue';
import { Rule } from 'src/app/services/issue-detection/rules/rule';
import { CallProblem, CallProblemSeverity } from 'src/app/models/call-problem/call-problem';

export enum DeviceProblems {
  'mismatch'       = 'device.mismatch',
  'weak_network'   = 'device.weak_network',
  'no_volume'      = 'device.no_volume',
  'missing_volume' = 'device.missing_volume',
}

export class DeviceRule implements Rule {
  constructor(
  ) {}

  public run(callIssues: CallIssue[], participant: string, nbParticipants: number): CallProblem {
    if (!callIssues || callIssues.length === 0) {
      return null;
    }

    if (callIssues.filter((val) => val.type === CallIssueType.missing_volume).length > 0) {
      return new CallProblem(null, DeviceProblems.missing_volume, CallProblemSeverity.error, true);
    }

    if (callIssues.filter((val) => val.type === CallIssueType.no_volume).length > 0) {
      return new CallProblem(null, DeviceProblems.no_volume, CallProblemSeverity.error, true);
    }

    if (callIssues.filter((val) => val.type === CallIssueType.input_output_mismatch).length > 0) {
      return new CallProblem(null, DeviceProblems.mismatch, CallProblemSeverity.warning, true);
    }

    if (callIssues.filter((val) => val.type === CallIssueType.weak_network).length > 0) {
      return new CallProblem(null, DeviceProblems.weak_network, CallProblemSeverity.warning, true);
    }

    return null;
  }

  public clear(callProblem: CallProblem, callIssues: CallIssue[]): CallIssue[] {
    return callIssues;
  }
}
