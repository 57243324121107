import { Injectable } from '@angular/core';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { environment } from 'src/environments/environment';
import { CrewdleFirestore } from 'src/app/models/firebase/firestore';
import { CrewdleFunctions } from 'src/app/models/firebase/functions';
import { CrewdleDatabase } from 'src/app/models/firebase/database';
import { CrewdleAuth } from 'src/app/models/firebase/auth';
import { CrewdleStorage } from 'src/app/models/firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  app: FirebaseApp;
  auth: CrewdleAuth;
  firestore: CrewdleFirestore;
  database: CrewdleDatabase;
  functions: CrewdleFunctions;
  storage: CrewdleStorage;

  constructor() {
    this.app = initializeApp(environment.firebaseConfig);
    this.auth = new CrewdleAuth(getAuth(this.app));
    this.firestore = new CrewdleFirestore(getFirestore(this.app));
    this.database = new CrewdleDatabase(getDatabase(this.app));
    this.functions = new CrewdleFunctions(getFunctions(this.app, 'northamerica-northeast1'));
    this.storage = new CrewdleStorage(getStorage(this.app));
  }
}
