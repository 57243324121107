import { AudioProfile, AudioProfileType } from './audio-profile';
import { VideoProfile } from './video-profile';

export class RemediationProfile {
  id: string;
  video: VideoProfile;
  audio: AudioProfile;

  constructor(width: number, height: number, framerate: number, audio: AudioProfileType) {
    this.id = `${width}-${height}-${framerate}-${audio}`;
    this.video = new VideoProfile(width, height, framerate);
    this.audio = new AudioProfile(audio);
  }

  get bitrate() {
    return this.video.bitrate + this.audio.bitrate;
  }
}
