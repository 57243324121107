import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  constructor(
    private platform: Platform,
  ) {}

  public get isMobile(): boolean {
    return (this.deviceWidth <= 576 || this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('tablet');
  }

  public get deviceWidth() {
    return window.innerWidth;
  }

  public get isSharingSupported(): boolean {
    const navigator: any = window.navigator;
    return !!navigator.share;
  }
}
