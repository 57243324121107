import { Component, OnInit, Input} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { UserService } from 'src/app/services/user/user.service';
import { FeatureSwitchService } from 'src/app/services/feature-switch/feature-switch.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { BillingService } from 'src/app/services/billing/billing.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit {
@Input() showOrg: boolean;
showType: string;
  constructor(
    public featureSwitch: FeatureSwitchService,
    public billing: BillingService,
    public user: UserService,
    public organization: OrganizationService,
    private localStorage: LocalStorageService,
    private popoverController: PopoverController,
  ) { }

  ngOnInit() {
    this.showType = this.localStorage.getItem('showType');
  }

  public signOut() {
    this.user.logout();
    this.popoverController.dismiss();
  }

  public dismissModal(id) {
    this.showType = id;
    this.localStorage.setItem('showType', id);
    this.popoverController.dismiss(this.showType);
  }

}
