import { Injectable } from '@angular/core';
import { serverTimestamp } from 'firebase/firestore';
import { ModalRoomConfigs, NewRoomConfigsSetter } from 'src/app/models/room-configs/room-configs';

import { LoggingChannels, LoggingService } from 'src/app/services/logging/logging.service';
import { UserService } from 'src/app/services/user/user.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class NewRoomService {

  constructor(
    private firebase: FirebaseService,
    private logging: LoggingService,
    private user: UserService,
  ) { }

  public async createNewRoom(name: string, configsRoomData: Partial<ModalRoomConfigs>, personalLink?: boolean) {
    if (personalLink) {
      return this.getPersonalRoom();
    }

    if (!configsRoomData) {
      this.logging.log('HomePage: error in the configs data', LoggingChannels.RoomFeature);
      return;
    }

    const newRoomData: NewRoomConfigsSetter = {
      name,
      createdOn: serverTimestamp(),
      status: 'not_started',
      parentId: this.user.user.uid,
      hostId: this.user.user.uid,

      // Default configurations
      access: 'url',
      forceMode: 'default',
      roomClose: true,
      inviteParticipants: true,
      shareScreen: true,
      isHostRequired: true,
      audioOnly: false,

      // Overwrite default configurations with the ones provided
      ...configsRoomData,
    };

    const docRef = await this.createRoomInFirebase(newRoomData);
    const roomId = docRef.id;
    const passcode = await this.generatePasscode(roomId, configsRoomData);

    const roomType = configsRoomData.type === 'personal' ? 'new_personal_room' : 'new_room';
    return {
      roomId,
      passcode,
      roomType,
    };
  }

  public async updateRoom(id: string, configsRoomData: Partial<ModalRoomConfigs>) {
    if (!configsRoomData) {
      this.logging.log('HomePage: error in the configs data', LoggingChannels.RoomFeature);
      return;
    }

    const docRef = await this.updateRoomInFirebase(id, configsRoomData);
    const roomId = docRef.id;
    const passcode = await this.generatePasscode(roomId, configsRoomData);

    const roomType = configsRoomData.type === 'personal' ? 'new_personal_room' : 'new_room';
    return {
      roomId,
      passcode,
      roomType,
    };
  }

  // TODO - Add interface for configsRoomData
  private async generatePasscode(id: string, configsRoomData: Partial<ModalRoomConfigs>): Promise<number> {
    if (configsRoomData.access !== 'passcode') {
      return null;
    }

    if (configsRoomData.type === 'personal') {
      // to fetch the passcode on the user side
      const passcodeDoc = await this.firebase.firestore.docGet(`passcodes/${id}`);
      if (passcodeDoc.data()) {
        return passcodeDoc.data().passcode;
      }
    }

    return this.storePasscode(id);
  }

  private async storePasscode(id: string) {
    const passcode = Math.floor(100000000 + Math.random() * 900000000);
    await this.firebase.firestore.docSet(`passcodes/${id}`, {
      passcode: Math.floor(100000000 + Math.random() * 900000000),
    });

    return passcode;
  }

  private async getPersonalRoom() {
    const doc = await this.firebase.firestore.docGet(`sessions/${this.user.data.roomId}`);
    const roomConfigs: any = doc.data() as ModalRoomConfigs;
    const roomId = doc.id;
    const passcode = await this.generatePasscode(roomId, roomConfigs);

    return {
      passcode,
      roomId,
      roomType: 'personal_room',
    };
  }

  private async createRoomInFirebase(newRoomData: NewRoomConfigsSetter) {
    return await this.firebase.firestore.docAdd(`sessions`, newRoomData);
  }

  private async updateRoomInFirebase(id: string, configsRoomData: Partial<ModalRoomConfigs>) {
      await this.firebase.firestore.docSet(`sessions/${id}`, configsRoomData, { merge: true });
      return this.firebase.firestore.doc(`sessions/${id}`);
  }
}
