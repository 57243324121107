import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { AccountSettingsComponent } from 'src/app/components/account-settings/account-settings.component';
import { AppHeaderService } from 'src/app/services/app-header/app-header.service';
import { ContactListService } from 'src/app/services/contact-list/contact-list.service';

@Injectable({
  providedIn: 'root'
})
export class AccountSettingsService {

  constructor(
    private appHeader: AppHeaderService,
    private contactList: ContactListService,
    private popoverController: PopoverController,
  ) {}

  public async show(ev: any, showOrg: boolean) {
    const popover = await this.popoverController.create({
      component: AccountSettingsComponent,
      cssClass: 'accountSettingsPopOver',
      event: ev,
      translucent: true,
      showBackdrop: false,
      componentProps: {
        showOrg,
      },
    });

    await popover.present();

    return popover.onDidDismiss();
  }

  public async onClick(ev) {
    const { data = null } = await this.show(ev, this.appHeader.showOrg);

    if (!data) {
      return;
    }

    this.appHeader.activeAccount = data;
    this.contactList.setContactType(data);
  }
}
