import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { environment } from 'src/environments/environment';

// Models
import { CallIssueType } from 'src/app/models/call-issue/call-issue';
import { CrewdlePeerConnection } from 'src/app/models/peer-connection/crewdle-peer-connection';
import { PeerStats } from 'src/app/models/peer-stats/peer-stats';
import { PeerStatsParticipant } from 'src/app/models/peer-stats/peer-stats-participant';

// Services
import { DC_TIME_THRESHOLD, DataChannelService } from '../data-channel/data-channel.service';
import { FeatureSwitchService } from 'src/app/services/feature-switch/feature-switch.service';
import { IntervalService } from 'src/app/services/interval/interval.service';
import { IssueDetectionService } from 'src/app/services/issue-detection/issue-detection.service';
import { IssueRemediationService } from 'src/app/services/issue-remediation/issue-remediation.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { LoggingChannels, LoggingService } from 'src/app/services/logging/logging.service';
import { ParticipantsListService } from '../participants-list/participants-list.service';
import { SignalingService } from '../signaling/signaling.service';
import { FeatureName, SupportService } from '../support/support.service';
import { VirtualBackgroundService } from '../virtual-background/virtual-background.service';
import { AmplifyService } from '../amplify/amplify.service';

export const STATS_INTERVAL = 1000; // ms
const SEND_INTERVAL = 60 * 1000; // ms
const ST_TRESHOLD = 50; // %
const DT_THESHOLD = 20; // %
const BITRATE_OUT_THRESHOLD = 25; // %
const BITRATE_IN_THRESHOLD = 25; // %
const JITTER_THRESHOLD = 600; // nb
const RTT_THRESHOLD = Infinity; // nb
const AV_DELAY_THRESHOLD = 300; // nb
const DECODE_TIME_THRESHOLD = 75; // %
const ENCODE_TIME_THRESHOLD = 75; // %
const FPS_THRESHOLD = 10; // nb

declare global {
  interface Window {
    crewdleStats: boolean;
  }
};

@Injectable({
  providedIn: 'root',
})
export class CallStatsService {
  roomId: string;
  userId: string;
  session: any;
  inputDevices: any[];
  constants: any;
  report: PeerStats;
  shareVideoTrackId: string;
  shareAudioTrackId: string;

  constructor(
    private appsync: AmplifyService,
    private background: VirtualBackgroundService,
    private dataChannel: DataChannelService,
    private featureSwitch: FeatureSwitchService,
    private intervalService: IntervalService,
    private issueDetection: IssueDetectionService,
    private issueRemediation: IssueRemediationService,
    private localStorage: LocalStorageService,
    private logging: LoggingService,
    private participants: ParticipantsListService,
    private platform: Platform,
    private signaling: SignalingService,
    private support: SupportService,
  ) {
    this.inputDevices = [];
  }

  public init(roomId: string, userId: string) {
    this.roomId = roomId;
    this.userId = userId;

    this.logging.log('CallStatsService: init', LoggingChannels.CallStats);

    if (this.featureSwitch.callstats) {
      this.report = new PeerStats();
      this.intervalService.setInterval(
        'callstats-send',
        () => {
          if (this.participants.nbParticipants > 1 && this.report) {
            this.sendStats();
          }
        },
        SEND_INTERVAL
      );
      let isRunning = false;
      this.intervalService.setInterval(
        'callstats-get',
        async () => {
          if (this.participants.nbParticipants > 1 && this.report && !isRunning) {
            isRunning = true;
            const nextParticipant = this.report.nextParticipant;
            const now = Date.now();

            if (
              nextParticipant &&
              this.participants.participants[nextParticipant]?.dataChannel?.readyState === 'open' &&
              (now - this.dataChannel.lasts[nextParticipant]) <= DC_TIME_THRESHOLD &&
              this.participants.participants[nextParticipant]?.pc?.connectionState !== 'failed' &&
              this.participants.participants[nextParticipant]?.pc?.connectionState !== 'disconnected'
            ) {
              this.report.scheduleTime = STATS_INTERVAL;
              if (this.report.scheduleTimestamp) {
                this.report.scheduleTime = now - this.report.scheduleTimestamp;
              }
              this.report.scheduleTimestamp = now;

              this.getStats(nextParticipant, this.participants.participants[nextParticipant].pc).then(() => {
                this.report.durationTime = Date.now() - now;
                try {
                  this.checkIssues(nextParticipant);
                } catch (err) {}
                isRunning = false;
              }).catch((err) => {
                isRunning = false;
              });
            } else {
              this.report.scheduleTimestamp = now;
              isRunning = false;
            }
          }
          if (this.featureSwitch.showStats && window.crewdleStats) {
            this.participants.participantsArray.forEach((p) => {
              const statsDiv = document.getElementById('video-stats-' + p.id);
              const data = this.getData(p.id);
              if (data && statsDiv) {
                statsDiv.innerHTML = data;
              }
              const homeStatsDiv = document.getElementById('video-stats-home');
              const homeData = this.getHostData();
              if (homeData && homeStatsDiv) {
                homeStatsDiv.innerHTML = homeData;
              }
            });
          }
        },
        STATS_INTERVAL
      );
    }
  }

  public start(
    roomId: string,
    userId: string,
    participantId: string
  ) {
    this.roomId = roomId;
    this.userId = userId;
    this.report.participantsStats[participantId] = new PeerStatsParticipant(participantId);

    this.logging.log('CallStatsService: start', LoggingChannels.CallStats, participantId);
  }

  public updateUser(roomId: string, userId: string) {
    this.roomId = roomId;
    this.userId = userId;
    this.getInputDevices().then(() => {
      this.updateCallStatsUser();
    });
  }

  public stop(id: string) {
    this.logging.log('CallStatsService: stop', LoggingChannels.CallStats, id);
    this.clearStats(id);
  }

  public close() {
    this.logging.log('CallStatsService: close', LoggingChannels.CallStats);
    if (this.featureSwitch.callstats) {
      this.report = null;
      this.intervalService.clearInterval('callstats-get');
      this.intervalService.clearInterval('callstats-send');
    }
  }

  public getHostData(): string {
    let text = '';
    text += 'GetStats Loop: ' + this.report.stVolatility?.toFixed(2) + '% / ' + this.report.dtVolatility?.toFixed(2) + '%';
    text += '<br />Bandwidth: ' + this.report.incomingBitrate?.toFixed(2) + 'kbps';
    text += ' / ' + this.report.outgoingBitrate?.toFixed(2) + 'kbps';
    text += '<br />Nb Participants: ' + this.participants.visibleParticipantNb;
    if (this.background.isActive) {
      text += '<br />Virtual background: ' + this.background.resolution;
    }
    return text;
  }

  public getData(id?: string): string {
    if (id) {
      let text = '';
      if (this.report.participantsStats[id]) {
        text += id;
        text += '<br />Bandwidth: ' + this.report.participantsStats[id].incomingBitrate?.toFixed(2) + 'kbps';
        text += ' / ' + this.report.participantsStats[id].outgoingBitrate?.toFixed(2) + 'kbps';
        text += '<br />RTT: ' + this.report.participantsStats[id].rtt + 'ms';
        text += '<br />AV Delay: ' + this.report.participantsStats[id].avDelay + 'ms';
        text += '<br />Audio Jitter: ' + (this.report.participantsStats[id].audio.jitterIn * 1000).toFixed(1) + 'ms';
        text += ' / ' + (this.report.participantsStats[id].audio.jitterOut * 1000).toFixed(1) + 'ms';
        text += '<br />Video Jitter: ' + (this.report.participantsStats[id].video.jitterIn * 1000).toFixed(1) + 'ms';
        text += ' / ' + (this.report.participantsStats[id].video.jitterOut * 1000).toFixed(1) + 'ms';
        text += '<br />FPS: ' + this.report.participantsStats[id].video.fpsIn.toFixed(0);
        text += ' / ' + this.report.participantsStats[id].video.fpsOut.toFixed(0);
        text += '<br />Resolution: ' + this.report.participantsStats[id].video.frameWidthIn + 'x';
        text += this.report.participantsStats[id].video.frameHeightIn;
        text += ' / ' + this.report.participantsStats[id].video.frameWidthOut + 'x';
        text += this.report.participantsStats[id].video.frameHeightOut;
        text += '<br />Encoder Time: ' + (this.report.participantsStats[id].video.decodeTimePerFrame * 1000).toFixed(1) + 'ms';
        text += ' / ' + (this.report.participantsStats[id].video.encodeTimePerFrame * 1000).toFixed(1) + 'ms';
        text += '<br />Profile: ' + this.issueRemediation.participantRemediations.remote[id]?.id;
        text += ' / ' + this.issueRemediation.participantRemediations.local[id]?.id;
        text += '<br />Data Channel Messages: ' + this.dataChannel.counts[id] + ' (' + (Date.now() - this.dataChannel.lasts[id]) + 'ms)';
        text += '<br />Signaling Messages: ' + this.signaling.counts[id] + ' (' + this.appsync.connectionState + ')';
        text += '<br />Data Channel State: ' + this.participants.participants[id]?.dataChannel?.remoteReadyState;
        text += ' / ' + this.participants.participants[id]?.dataChannel?.readyState;
        text += '<br />Connection State: ' + this.participants.participants[id]?.pc?.remoteConnectionState;
        text += ' / ' + this.participants.participants[id]?.pc?.connectionState;
        text += '<br />Ice Connection State: ' + this.participants.participants[id]?.pc?.remoteIceConnectionState;
        text += ' / ' + this.participants.participants[id]?.pc?.iceConnectionState;
        text += '<br />Reconnect State: ' + (this.participants.participants[id]?.attemptingReconnect ? 'Connecting' : 'Connected');
      }
      return text;
    } else {
      return null;
    }
  }

  public logAlert(alerts: any) {
    this.logging.logCustomEvent('alert', {
      userId: this.userId,
      roomId: this.roomId,
      alerts,
    });
  }

  public logEvent(
    event: string,
    options?: any,
    roomId?: string,
    userId?: string
  ) {
    this.logging.logCustomEvent('event', {
      userId: this.userId || userId,
      roomId: this.roomId || roomId,
      event,
      options: options || null,
    });
  }

  private sendStats() {
    this.logging.log('CallStatsService: send', LoggingChannels.CallStats);
    const callStatsReport = this.getReport();
    if (callStatsReport.length > 0) {
      this.logging.log('CallStatsService: sending', LoggingChannels.CallStats);
      this.logStats(callStatsReport);
    }
  }

  private clearStats(id: string) {
    if (this.report) {
      if (this.report.participantsStats[id]) {
        delete this.report.participantsStats[id];
      }
      if (this.report.shareStats[id]) {
        delete this.report.shareStats[id];
      }
    }
  }

  private getUserInfo() {
    const navigatorObj: any = navigator;
    const connectionObj: any = navigatorObj.connection;

    if (['slow-2g', '2g', '3g'].includes(connectionObj?.effectiveType)) {
      this.issueDetection.logEvent(CallIssueType.weak_network, null, connectionObj?.effectiveType);
    }

    return {
      userId: this.userId,
      roomId: this.roomId,
      status: 'open',
      version: environment.version,
      mobile: this.platform.is('mobile'),
      tablet: this.platform.is('tablet'),
      inputDevices: this.inputDevices,
      localTime: new Date().toUTCString(),
      networkType: connectionObj?.effectiveType || null,
      downlink: connectionObj?.downlink || null,
      rtt: connectionObj?.rtt || null,
      support: {
        audioOutputSupported: this.support.isFeatureSupported(FeatureName.AudioOutput),
        backgroundSupported: this.support.isFeatureSupported(FeatureName.Backgrounds),
        configSupported: this.support.isFeatureSupported(FeatureName.DeviceConfig),
        recordingSupported: this.support.isFeatureSupported(FeatureName.Recording),
        sharingSupported: this.support.isFeatureSupported(FeatureName.ScreenSharing),
        streamSupported: this.support.isFeatureSupported(FeatureName.MediaStream),
        volumeSupported: this.support.isFeatureSupported(FeatureName.AudioVolume),
        webglSupported: this.support.isFeatureSupported(FeatureName.WebGL),
        webRTCSupported: this.support.isFeatureSupported(FeatureName.WebRTC),
      }
    };
  }

  private updateCallStatsUser() {
    this.logging.logCustomEvent('user', this.getUserInfo());
  }

  private logStats(report) {
    this.logging.logCustomEvent('stats', {
      userId: this.userId,
      roomId: this.roomId,
      report,
    });
  }

  private async getStats(
    participantId: string,
    pc: CrewdlePeerConnection,
  ) {
    const senderFilter = [
      'outbound-rtp',
      'remote-inbound-rtp',
    ];
    const senders = pc.getSenders();
    for (const sender of senders) {
      if (sender.track) {
        if (this.participants.participants[participantId].tracks.find((s) => s.track && s.track.id === sender.track.id)) {
          const stats = await sender.getStats();
          stats.forEach((stat) => {
            if (senderFilter.includes(stat.type)) {
              this.parse(participantId, stat);
            }
          });
        }
        if (this.participants.participants[this.userId].isSharing && [this.shareVideoTrackId, this.shareAudioTrackId].includes(sender.track.id)) {
          if (!this.report.shareStats[participantId]) {
            this.report.shareStats[participantId] = new PeerStatsParticipant(participantId);
          }
          const stats = await sender.getStats();
          stats.forEach((stat) => {
            if (senderFilter.includes(stat.type)) {
              this.parse(participantId, stat, true);
            }
          });
        }
      }
    }

    const receiverFilter = [
      'inbound-rtp',
    ];
    const receivers = pc.getReceivers();
    for (const receiver of receivers) {
      if (receiver.track) {
        if (this.participants.participants[participantId].stream.getTracks().find((track) => track.id === receiver.track.id)) {
          const stats = await receiver.getStats();
          stats.forEach((stat) => {
            if (receiverFilter.includes(stat.type)) {
              this.parse(participantId, stat);
            }
          });
        }
        if (this.participants.participants[participantId].isSharing && [this.shareVideoTrackId, this.shareAudioTrackId].includes(receiver.track.id)) {
          if (!this.report.shareStats[participantId]) {
            this.report.shareStats[participantId] = new PeerStatsParticipant(participantId);
          }
          const stats = await receiver.getStats();
          stats.forEach((stat) => {
            if (receiverFilter.includes(stat.type)) {
              this.parse(participantId, stat, true);
            }
          });
        }
      }
    }

    if (
      !this.participants.participants[this.userId].isSharing &&
      !this.participants.participants[participantId].isSharing &&
      this.report.shareStats[participantId]
    ) {
      delete this.report.shareStats[participantId];
    }
  }

  private parse(participantId: string, stat: any, sharing = false) {
    let r = this.report?.participantsStats[participantId];
    if (sharing) {
      r = this.report?.shareStats[participantId];
    }

    if (r) {
      if (stat.type === 'inbound-rtp') {
        if (stat.kind === 'audio') {
          r.audio.bytesReceived = stat.bytesReceived;
          r.audio.packetsLost = stat.packetsLost;
          r.audio.packetsReceived = stat.packetsReceived;
          r.audio.receivedTimestamp = stat.timestamp;
          if (typeof stat.jitter !== 'undefined') {
            r.audio.jitterIn = stat.jitter;
          } else {
            r.audio.jitterIn = 0;
          }
          if (typeof stat.estimatedPlayoutTimestamp !== 'undefined') {
            r.audio.estimatedPlayoutTimestamp = stat.estimatedPlayoutTimestamp;
          }
        } else if (stat.kind === 'video') {
          r.video.bytesReceived = stat.bytesReceived;
          r.video.packetsLost = stat.packetsLost;
          r.video.packetsReceived = stat.packetsReceived;
          r.video.receivedTimestamp = stat.timestamp;
          r.video.totalDecodeTime = stat.totalDecodeTime;
          r.video.framesDecoded = stat.framesDecoded;
          r.video.frameWidthIn = stat.frameWidth;
          r.video.frameHeightIn = stat.frameHeight;
          if (typeof stat.estimatedPlayoutTimestamp !== 'undefined') {
            r.video.estimatedPlayoutTimestamp = stat.estimatedPlayoutTimestamp;
          }
          if (typeof stat.jitter !== 'undefined') {
            r.video.jitterIn = stat.jitter;
          } else {
            r.video.jitterIn = 0;
          }
        }
      } else if (stat.type === 'outbound-rtp') {
        if (stat.kind === 'audio') {
          r.audio.bytesSent = stat.bytesSent;
          r.audio.sentTimestamp = stat.timestamp;
        } else if (stat.kind === 'video') {
          r.video.bytesSent = stat.bytesSent;
          r.video.totalEncodeTime = stat.totalEncodeTime;
          r.video.framesEncoded = stat.framesEncoded;
          r.video.sentTimestamp = stat.timestamp;
          r.video.frameWidthOut = stat.frameWidth;
          r.video.frameHeightOut = stat.frameHeight;
        }
      } else if (stat.type === 'remote-inbound-rtp') {
        if (stat.kind === 'audio') {
          r.audio.roundTripTime = stat.roundTripTime;
          if (typeof stat.jitter !== 'undefined') {
            r.audio.jitterOut = stat.jitter;
          } else {
            r.audio.jitterOut = 0;
          }
        } else if (stat.kind === 'video') {
          r.video.roundTripTime = stat.roundTripTime;
          if (typeof stat.jitter !== 'undefined') {
            r.video.jitterOut = stat.jitter;
          } else {
            r.video.jitterOut = 0;
          }
        }
      }
    }
  }

  private getReport() {
    const statsReports = [];

    statsReports.push({
      session: {
        availableBandwidth: ((this.report.availableOutgoingBitrate || 0) / 1024).toFixed(2),
        bandwidthIn: this.report.incomingBitrate?.toFixed(2) || 0,
        bandwidthOut: this.report.outgoingBitrate?.toFixed(2) || 0,
        stVolatility: this.report.stVolatility?.toFixed(2) || 0,
        dtVolatility: this.report.dtVolatility?.toFixed(2) || 0,
        echoReturnLoss: this.report.echoReturnLoss || 0,
        echoReturnLossEnhancement: this.report.echoReturnLossEnhancement || 0,
        audio: this.participants.participants[this.userId].audioSwitch,
        video: this.participants.participants[this.userId].videoSwitch,
        background: this.background.isActive,
      }
    });

    this.participants.participantsArray.forEach((participant) => {
      const r = this.report.participantsStats[participant.id] || new PeerStatsParticipant(participant.id);
      this.pushReport(statsReports, participant.id, r);
    });
    Object.keys(this.report.shareStats).forEach((id) => {
      this.pushReport(statsReports, id, this.report.shareStats[id], true);
    });

    return statsReports;
  }

  private pushReport(StatsReports: any[], id: string, p: PeerStatsParticipant, sharing = false) {
    const r = {
      session: {
        id: '',
        sharing,
        outgoingBitrate: 0,
        incomingBitrate: 0,
        rtt: 0,
        avDelay: 0,
      },
      status: {
        dataChannel: '',
        remoteDataChannel: '',
        connection: '',
        remoteConnection: '',
        iceConnection: '',
        remoteIceConnection: '',
      },
      audio: {
        packetsLost: 0,
        jitterIn: 0,
        jitterOut: 0,
        outgoingBitrate: 0,
        incomingBitrate: 0,
      },
      video: {
        packetsLost: 0,
        encodeTimePerFrame: 0,
        decodeTimePerFrame: 0,
        frameHeightIn: 0,
        frameWidthIn: 0,
        frameHeightOut: 0,
        frameWidthOut: 0,
        jitterIn: 0,
        jitterOut: 0,
        fpsIn: 0,
        fpsOut: 0,
        outgoingBitrate: 0,
        incomingBitrate: 0,
      },
    };
    r.session.id = id;
    r.status.dataChannel = this.participants.participants[id]?.dataChannel?.readyState || '';
    r.status.remoteDataChannel = this.participants.participants[id]?.dataChannel?.remoteReadyState || '';
    r.status.connection = this.participants.participants[id]?.pc?.connectionState || '';
    r.status.remoteConnection = this.participants.participants[id]?.pc?.remoteConnectionState || '';
    r.status.iceConnection = this.participants.participants[id]?.pc?.iceConnectionState || '';
    r.status.remoteIceConnection = this.participants.participants[id]?.pc?.remoteIceConnectionState || '';
    if (typeof p.rtt !== 'undefined') {
      r.session.rtt = p.rtt;
    }
    if (typeof p.avDelay !== 'undefined') {
      r.session.avDelay = p.avDelay;
    }
    if (typeof p.outgoingBitrate !== 'undefined') {
      r.session.outgoingBitrate = p.outgoingBitrate;
    }
    if (typeof p.incomingBitrate !== 'undefined') {
      r.session.incomingBitrate = p.incomingBitrate;
    }

    if (typeof p.audio.packetsLostPerc !== 'undefined') {
      r.audio.packetsLost = p.audio.packetsLostPerc;
    }
    if (typeof p.audio.jitterIn !== 'undefined') {
      r.audio.jitterIn = p.audio.jitterIn;
    }
    if (typeof p.audio.jitterOut !== 'undefined') {
      r.audio.jitterOut = p.audio.jitterOut;
    }
    if (typeof p.audio.outgoingBitrate !== 'undefined') {
      r.audio.outgoingBitrate = p.audio.outgoingBitrate;
    }
    if (typeof p.audio.incomingBitrate !== 'undefined') {
      r.audio.incomingBitrate = p.audio.incomingBitrate;
    }

    if (typeof p.video.packetsLostPerc !== 'undefined') {
      r.video.packetsLost = p.video.packetsLostPerc;
    }
    if (typeof p.video.encodeTimePerFrame !== 'undefined') {
      r.video.encodeTimePerFrame = p.video.encodeTimePerFrame;
    }
    if (typeof p.video.decodeTimePerFrame !== 'undefined') {
      r.video.decodeTimePerFrame = p.video.decodeTimePerFrame;
    }
    if (typeof p.video.frameWidthIn !== 'undefined') {
      r.video.frameWidthIn = p.video.frameWidthIn;
    }
    if (typeof p.video.frameHeightIn !== 'undefined') {
      r.video.frameHeightIn = p.video.frameHeightIn;
    }
    if (typeof p.video.frameWidthOut !== 'undefined') {
      r.video.frameWidthOut = p.video.frameWidthOut;
    }
    if (typeof p.video.frameHeightOut !== 'undefined') {
      r.video.frameHeightOut = p.video.frameHeightOut;
    }
    if (typeof p.video.jitterIn !== 'undefined') {
      r.video.jitterIn = p.video.jitterIn;
    }
    if (typeof p.video.jitterOut !== 'undefined') {
      r.video.jitterOut = p.video.jitterOut;
    }
    if (typeof p.video.fpsIn !== 'undefined') {
      r.video.fpsIn = p.video.fpsIn;
    }
    if (typeof p.video.fpsOut !== 'undefined') {
      r.video.fpsOut = p.video.fpsOut;
    }
    if (typeof p.video.outgoingBitrate !== 'undefined') {
      r.video.outgoingBitrate = p.video.outgoingBitrate;
    }
    if (typeof p.video.incomingBitrate !== 'undefined') {
      r.video.incomingBitrate = p.video.incomingBitrate;
    }

    StatsReports.push(r);
  }

  private getInputDevices(): Promise<void> {
    const inputs = [];
    const videoInputId = this.localStorage.getItem('videoInputId');
    const audioInputId = this.localStorage.getItem('audioInputId');
    const audioOutputId = this.localStorage.getItem('audioOutputId');
    return navigator.mediaDevices.enumerateDevices().then((devices) => {
      devices.forEach((device) => {
        let selected = '';
        if (
          device.kind === 'videoinput' && device.deviceId === videoInputId ||
          device.kind === 'audioinput' && device.deviceId === audioInputId ||
          device.kind === 'audiooutput' && device.deviceId === audioOutputId
        ) {
          selected = '[SELECTED] ';
        }
        inputs.push(`${selected}${device.kind} : ${device.label}`);
      });
      this.inputDevices = inputs;
    });
  }

  private checkIssues(participant: string) {
    if (this.report.stVolatility > ST_TRESHOLD) {
      this.issueDetection.logEvent(CallIssueType.st_volatility, null, this.report.stVolatility);
      if (this.participants.participants[this.userId].isSharing) {
        this.issueDetection.logEvent(CallIssueType.st_volatility, null, this.report.stVolatility, true);
      }
    }
    if (this.report.dtVolatility > DT_THESHOLD) {
      this.issueDetection.logEvent(CallIssueType.dt_volatility, null, this.report.dtVolatility);
      if (this.participants.participants[this.userId].isSharing) {
        this.issueDetection.logEvent(CallIssueType.dt_volatility, null, this.report.dtVolatility, true);
      }
    }

    this.checkParticipantIssues(participant);
    this.checkShareIssues(participant);
  }

  private checkParticipantIssues(participant: string) {
    if (this.report.participantsStats[participant]) {
      const report = this.report.participantsStats[participant];
      const other = this.participants.participants[participant];
      const self = this.participants.participants[this.userId];

      if (other.videoSwitch) {
        const remoteFramerate = this.issueRemediation.participantRemediations.remote[participant].video.framerate;
        const remoteBitrate = this.issueRemediation.participantRemediations.remote[participant].video.bitrate;
        if (report.video.decodeTimePerFrame > (1000 / remoteFramerate) * (DECODE_TIME_THRESHOLD / 100)) {
          this.issueDetection.logEvent(CallIssueType.decode_time, participant, report.video.decodeTimePerFrame);
        }
        if (report.incomingBitrate > 0 && report.incomingBitrate < remoteBitrate * (BITRATE_IN_THRESHOLD / 100)) {
          this.issueDetection.logEvent(CallIssueType.bandwidth_in, participant, report.incomingBitrate);
        }
        if ((report.video.jitterIn * 1000) > JITTER_THRESHOLD) {
          this.issueDetection.logEvent(CallIssueType.video_jitter_in, participant, report.video.jitterIn * 1000);
        }
        if (report.video.fpsIn > 0 && report.video.fpsIn < FPS_THRESHOLD) {
          this.issueDetection.logEvent(CallIssueType.fps_in, participant, report.video.fpsIn);
        }
      }
      if (other.audioSwitch) {
        if ((report.audio.jitterIn * 1000) > JITTER_THRESHOLD) {
          this.issueDetection.logEvent(CallIssueType.audio_jitter_in, participant, report.audio.jitterIn * 1000);
        }
      }
      if (other.videoSwitch && other.audioSwitch) {
        if (report.avDelay > AV_DELAY_THRESHOLD) {
          this.issueDetection.logEvent(CallIssueType.av_delay, participant, report.avDelay);
        }
      }

      if (self.videoSwitch) {
        const localFramerate = this.issueRemediation.participantRemediations.local[participant].video.framerate;
        const localBitrate = this.issueRemediation.participantRemediations.local[participant].video.bitrate;
        if (report.video.encodeTimePerFrame > (1000 / localFramerate) * (ENCODE_TIME_THRESHOLD / 100)) {
          this.issueDetection.logEvent(CallIssueType.encode_time, participant, report.video.encodeTimePerFrame);
        }
        if (report.outgoingBitrate > 0 && report.outgoingBitrate < localBitrate * (BITRATE_OUT_THRESHOLD / 100)) {
          this.issueDetection.logEvent(CallIssueType.bandwidth_out, participant, report.outgoingBitrate);
        }
        if ((report.video.jitterOut * 1000) > JITTER_THRESHOLD) {
          this.issueDetection.logEvent(CallIssueType.video_jitter_out, participant, report.video.jitterOut * 1000);
        }
        if (report.video.fpsOut > 0 && report.video.fpsOut < FPS_THRESHOLD) {
          this.issueDetection.logEvent(CallIssueType.fps_out, participant, report.video.fpsOut);
        }
      }
      if (self.audioSwitch) {
        if ((report.audio.jitterOut * 1000) > JITTER_THRESHOLD) {
          this.issueDetection.logEvent(CallIssueType.audio_jitter_out, participant, report.audio.jitterOut * 1000);
        }
      }
      if (self.videoSwitch || self.audioSwitch) {
        if (report.rtt > RTT_THRESHOLD) {
          this.issueDetection.logEvent(CallIssueType.rtt, participant, report.rtt);
        }
      }
    }
  }

  private checkShareIssues(participant: string) {
    if (this.report.shareStats[participant]) {
      const report = this.report.shareStats[participant];
      const other = this.participants.participants[participant];
      const self = this.participants.participants[this.userId];

      if (other.isSharing) {
        const remoteFramerate = this.issueRemediation.shareRemediations.remote[participant].video.framerate;
        if (report.video.decodeTimePerFrame > (1000 / remoteFramerate) * (1 - DECODE_TIME_THRESHOLD / 100)) {
          this.issueDetection.logEvent(CallIssueType.decode_time, participant, report.video.decodeTimePerFrame, true);
        }
        if ((report.video.jitterIn * 1000) > JITTER_THRESHOLD) {
          this.issueDetection.logEvent(CallIssueType.video_jitter_in, participant, report.video.jitterIn * 1000, true);
        }
        if (this.shareAudioTrackId) {
          if ((report.audio.jitterIn * 1000) > JITTER_THRESHOLD) {
            this.issueDetection.logEvent(CallIssueType.audio_jitter_in, participant, report.audio.jitterIn * 1000, true);
          }
          if (report.avDelay > AV_DELAY_THRESHOLD) {
            this.issueDetection.logEvent(CallIssueType.av_delay, participant, report.avDelay, true);
          }
        }
      }

      if (self.isSharing) {
        const localFramerate = this.issueRemediation.shareRemediations.local[participant].video.framerate;
        if (report.video.encodeTimePerFrame > (1000 / localFramerate) * (1 - ENCODE_TIME_THRESHOLD / 100)) {
          this.issueDetection.logEvent(CallIssueType.encode_time, participant, report.video.encodeTimePerFrame, true);
        }
        if ((report.video.jitterOut * 1000) > JITTER_THRESHOLD) {
          this.issueDetection.logEvent(CallIssueType.video_jitter_out, participant, report.video.jitterOut * 1000, true);
        }
        if (report.rtt > RTT_THRESHOLD) {
          this.issueDetection.logEvent(CallIssueType.rtt, participant, report.rtt, true);
        }
        if (this.shareAudioTrackId) {
          if ((report.audio.jitterOut * 1000) > JITTER_THRESHOLD) {
            this.issueDetection.logEvent(CallIssueType.audio_jitter_out, participant, report.audio.jitterOut * 1000, true);
          }
        }
      }
    }
  }
}
