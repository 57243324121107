import { Injectable } from '@angular/core';

export enum UnstableConnectionEventsType {
  disconnect = 'disconnect',
}

type UnstableConnectionEvents = {
  timestamp: number;
  eventType: UnstableConnectionEventsType;
};

// We keep unstable events for up to 15 minutes
const retainmentPeriod = 1000 * 60 * 15;

@Injectable({
  providedIn: 'root'
})
export class UnstableConnectionService {
  private events: UnstableConnectionEvents[] = [];

  public log(eventType: UnstableConnectionEventsType = UnstableConnectionEventsType.disconnect) {
    const event = {
      timestamp: Date.now(),
      eventType,
    };

    this.events.push(event);
  }

  public getEvents() {
    const currentTime = Date.now();
    this.events = this.events.filter(e => currentTime - e.timestamp < retainmentPeriod);
    return this.events;
  }
}
