import {
  Auth,
  AuthProvider,
  CompleteFn,
  createUserWithEmailAndPassword,
  ErrorFn,
  FacebookAuthProvider,
  GoogleAuthProvider,
  NextOrObserver,
  OAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  Unsubscribe,
  updateEmail,
  updatePassword,
  updateProfile,
  User,
  UserCredential
} from 'firebase/auth';
import { environment } from 'src/environments/environment';

export class CrewdleAuth {
  public auth: Auth;

  constructor(auth: Auth) {
    this.auth = auth;
  }

  public onAuthStateChanged(nextOrObserver: NextOrObserver<User>, error?: ErrorFn, completed?: CompleteFn): Unsubscribe {
    return onAuthStateChanged(this.auth, nextOrObserver, error, completed);
  }

  public signInWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  public signInWithPopup(provider: AuthProvider): Promise<UserCredential> {
    return signInWithPopup(this.auth, provider);
  }

  public signInWithCustomToken(token: string): Promise<UserCredential> {
    return signInWithCustomToken(this.auth, token);
  }

  public signInAnonymously(): Promise<UserCredential> {
    return signInAnonymously(this.auth);
  }

  public signOut(): Promise<void> {
    return signOut(this.auth);
  }

  public createUserWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  public sendPasswordResetEmail(email: string): Promise<void> {
    const actionCodeSettings = {
      url: environment.baseUrl + 'login',
    };
    return sendPasswordResetEmail(this.auth, email, actionCodeSettings);
  }

  public sendEmailVerification(user: User): Promise<void> {
    const actionCodeSettings = {
      url: environment.baseUrl + 'login',
    };
    return sendEmailVerification(user, actionCodeSettings);
  }

  public setLanguageCode(languageCode: string) {
    this.auth.languageCode = languageCode;
  }

  public updateEmail(user: User, email: string): Promise<void> {
    return updateEmail(user, email);
  }

  public updatePassword(user: User, password: string): Promise<void> {
    return updatePassword(user, password);
  }

  public updateProfile(user: User, data: {displayName?: string; photoURL?: string}): Promise<void> {
    return updateProfile(user, data);
  }

  public getFacebookAuthProvider(): FacebookAuthProvider {
    const provider = new FacebookAuthProvider();
    provider.setCustomParameters({display: 'popup'});
    return provider;
  }

  public getGoogleAuthProvider(): GoogleAuthProvider {
    return new GoogleAuthProvider();
  }

  public getMicrosoftAuthProvider(): OAuthProvider {
    return new OAuthProvider('microsoft.com');
  }
}
