import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { environment } from 'src/environments/environment';

export enum LoggingChannels {
  Authentication      = 'authentication',
  CallStats           = 'call_stats',
  ConnectionFlow      = 'connection_flow',
  ConnectionState     = 'connection_state',
  Contact             = 'contact',
  Conversation        = 'conversation',
  Default             = 'default',
  Disconnect          = 'disconnect',
  Organization        = 'organization',
  Remediation         = 'remediation',
  RoomFeature         = 'room_feature',
  RoomFlow            = 'room_flow',
  Stream              = 'stream',
  VirtualBackground   = 'virtual_background',
  Volume              = 'volume',
}

declare global {
  interface Window {
    crewdleEnableLogging: (channel: LoggingChannels) => void;
    crewdleDisableLogging: (channel: LoggingChannels) => void;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  private enabledChannels: LoggingChannels[] = [LoggingChannels.Default];

  constructor() {
    this.loadEnabledChannels();
    window.crewdleEnableLogging = (channel: LoggingChannels) => {
      if (!this.enabledChannels.includes(channel)) {
        this.enabledChannels.push(channel);
        localStorage.setItem(`crewdle_logging_${channel}`, 'true');
      }
    };
    window.crewdleDisableLogging = (channel: LoggingChannels) => {
      if (this.enabledChannels.includes(channel)) {
        this.enabledChannels = this.enabledChannels.filter((c) => c !== channel);
        localStorage.removeItem(`crewdle_logging_${channel}`);
      }
    };
    datadogLogs.init({
      clientToken: environment.datadog.token,
      site: 'datadoghq.com',
      service: 'crewdle-net',
      env: environment.production ? 'production' : 'staging',
      version: environment.version,
    });
  }

  public log(message: any, channel: LoggingChannels, ...args: any[]) {
    if (this.enabledChannels.includes(channel)) {
      console.log(new Date(), message, args);
    }
  }

  public error(message: any, ...args: any[]) {
    console.error(message, args);
  }

  public warning(message: any, ...args: any[]) {
    console.warn(new Date(), message, args);
  }

  public logCustomEvent(message: any, data: any) {
    datadogLogs.logger.info(message, data);
  }

  public isChannelEnabled(channel: LoggingChannels) {
    return this.enabledChannels.includes(channel);
  }

  private loadEnabledChannels() {
    Object.keys(LoggingChannels).forEach((key) => {
      if (localStorage.getItem(`crewdle_logging_${LoggingChannels[key]}`)) {
        console.log('LoggingService: logging enabled for', LoggingChannels[key]);
        this.enabledChannels.push(LoggingChannels[key]);
      }
    });
  }
}
