export enum AudioProfileType {
  'high'    = 96,
  'good'    = 48,
  'medium'  = 32,
  'low'     = 16,
}

export class AudioProfile {
  bitrate: number;

  constructor(type: AudioProfileType) {
    this.bitrate = type;
  }
}
