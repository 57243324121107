import { Injectable } from '@angular/core';
import { IntervalService } from '../interval/interval.service';
import { CallProblem, CallProblemSeverity } from 'src/app/models/call-problem/call-problem';
import { LoggingChannels, LoggingService } from '../logging/logging.service';
import { remediationRules, RemediationTypes } from './issue-remediation.rules';
import { DataChannelService } from '../data-channel/data-channel.service';
import { BandwidthProblems } from '../issue-detection/rules/bandwidth';
import { ParticipantsListService } from '../participants-list/participants-list.service';
import { RemediationProfile } from 'src/app/models/remediation-profile/remediation-profile';
import { AudioProfileType } from 'src/app/models/remediation-profile/audio-profile';
import { FeatureSwitchService } from '../feature-switch/feature-switch.service';
import { Remediation } from 'src/app/models/remediation-profile/remediation';
import { VirtualBackgroundService } from '../virtual-background/virtual-background.service';
import { NotificationService } from '../notification/notification.service';

declare global {
  interface RTCRtpEncodingParameters {
    maxFrameRate: number;
  }
  interface Window {
    forceProfile: string;
    forceShareProfile: string;
  }
}

type RemediationNotification = {
  notification: boolean;
  message: string;
  color: string;
};

const remediationProblems = {
  [RemediationTypes.bitrate]: {
    notification: false,
    message: 'remediation.problems.bitrate',
    color: 'warning',
  },
  [RemediationTypes.resolution]: {
    notification: false,
    message: 'remediation.problems.resolution',
    color: 'warning',
  },
};

@Injectable({
  providedIn: 'root'
})
export class IssueRemediationService {

  participantRemediations: Remediation;
  shareRemediations: Remediation;
  screenShareProfile: RemediationProfile;
  savedProfiles: { [key: string]: RemediationProfile };
  downgradeTime: number;
  upgradeTime: number;
  roomId: string;
  userId: string;
  shareVideoTrack: MediaStreamTrack;
  shareAudioTrack: MediaStreamTrack;
  lastResolution: number;

  constructor(
    private interval: IntervalService,
    private logging: LoggingService,
    private dataChannel: DataChannelService,
    private participants: ParticipantsListService,
    private featureSwitch: FeatureSwitchService,
    private background: VirtualBackgroundService,
    private notification: NotificationService,
  ) {
    this.participantRemediations = new Remediation();
    this.shareRemediations = new Remediation();
    this.downgradeTime = 5000;
    this.upgradeTime = 15000;
  }

  public start(roomId: string, userId: string) {
    this.roomId = roomId;
    this.userId = userId;

    this.interval.setInterval('remediation', () => {
      Object.keys(this.participantRemediations.local).forEach((participant) => {
        this.upgrade(participant);
      });
      Object.keys(this.shareRemediations.local).forEach((participant) => {
        this.upgrade(participant, true);
      });
      if (this.background.resolution !== this.lastResolution) {
        this.lastResolution = this.background.resolution;
        setTimeout(() => {
          Object.keys(this.participantRemediations.local).forEach((participant) => {
            this.applyRemediations(participant, false);
          });
        }, 300);
      }
    }, 1000);
  }

  public stop() {
    this.interval.clearInterval('remediation');
    this.roomId = null;
    this.userId = null;
  }

  public startParticipant(participant: string) {
    if (this.participantRemediations.local[participant]) {
      this.reconnectParticipant(participant);
      return;
    }
    this.upgradeTime += 2000;
    this.participantRemediations.last[participant] = Date.now();
    if (this.participantRemediations.profiles) {
      if (this.savedProfiles || this.shareVideoTrack) {
        if (this.background.isActive) {
          this.background.forceResolution = true;
          this.background.savedResolution = this.background.resolution;
          this.background.resolution = 0.25;
        }
        setTimeout(() => {
          this.savedProfiles[participant] = this.participantRemediations.profiles[0];
          this.setLocalProfile(participant, this.screenShareProfile, false);
          this.applyRemediations(participant, false);
        }, 300);
      } else {
        this.setLocalProfile(participant, this.participantRemediations.profiles[0]);
        this.applyRemediations(participant, false);
      }
    }
    if (this.shareVideoTrack) {
      this.shareRemediations.last[participant] = Date.now();
      if (this.shareRemediations.profiles) {
        this.setLocalProfile(participant, this.shareRemediations.profiles[0], true);
        this.applyRemediations(participant, true);
      }
    }
    this.dataChannel.registerCallback(participant, 'remediation', (data) => {
      if (data.bitrate) {
        this.checkProblem(new CallProblem(participant, BandwidthProblems.bandwidth_out, CallProblemSeverity.warning), data.sharing);
      }
      if (data.resolution) {
        const profile = this.findNewProfile(participant, data.sharing);
        let remediations: Remediation;
        if (data.sharing) {
          remediations = this.shareRemediations;
        } else {
          remediations = this.participantRemediations;
        }
        if (profile && profile.id !== remediations.local[participant].id) {
          remediations.last[participant] = Date.now();
          this.setLocalProfile(participant, profile, data.sharing);
          this.logRemediation(participant, 'Downgrade profile to remote', profile, data.sharing);
          this.logging.log('IssueRemediationService: downgrade profile to remote', LoggingChannels.Remediation, participant, profile.id, data.sharing);
          this.applyRemediations(participant, data.sharing);
        }
      }
    });
    this.dataChannel.registerCallback(participant, 'remediation-profile', (data) => {
      const profileParts = (data.profile as string).split('-');
      const profile = new RemediationProfile(
        parseInt(profileParts[0], 10),
        parseInt(profileParts[1], 10),
        parseInt(profileParts[2], 10),
        parseInt(profileParts[3], 10)
      );
      if (data.sharing) {
        this.shareRemediations.remote[participant] = profile;
      } else {
        this.participantRemediations.remote[participant] = profile;
      }
    });
  }

  public stopParticipant(participant: string) {
    this.upgradeTime -= 2000;
    delete this.participantRemediations.local[participant];
    delete this.participantRemediations.remote[participant];
    delete this.participantRemediations.last[participant];
    if (this.shareRemediations.local[participant]) {
      delete this.shareRemediations.local[participant];
    }
    if (this.shareRemediations.remote[participant]) {
      delete this.shareRemediations.remote[participant];
    }
    if (this.shareRemediations.last[participant]) {
      delete this.shareRemediations.last[participant];
    }
  }

  public reconnectParticipant(participant: string) {
    this.applyRemediations(participant, false);
    if (this.shareVideoTrack) {
      this.applyRemediations(participant, true);
    }
  }

  public startSharing() {
    Object.keys(this.participantRemediations.local).forEach((participant) => {
      this.shareRemediations.last[participant] = Date.now();
      this.setLocalProfile(participant, this.shareRemediations.profiles[0], true);
    });
    this.setSharingProfiles();
  }

  public stopSharing() {
    this.shareVideoTrack = null;
    this.shareAudioTrack = null;
    Object.keys(this.shareRemediations.local).forEach((participant) => {
      delete this.shareRemediations.local[participant];
    });
    Object.keys(this.shareRemediations.remote).forEach((participant) => {
      delete this.shareRemediations.remote[participant];
    });
    Object.keys(this.shareRemediations.last).forEach((participant) => {
      delete this.shareRemediations.last[participant];
    });
    this.restoreProfiles();
  }

  public setSharingProfiles() {
    this.savedProfiles = {};
    if (this.background.isActive) {
      this.background.forceResolution = true;
      this.background.savedResolution = this.background.resolution;
      this.background.resolution = 0.25;
    }
    setTimeout(() => {
      Object.keys(this.participantRemediations.local).forEach((participant) => {
        this.savedProfiles[participant] = this.participantRemediations.local[participant];
        this.setLocalProfile(participant, this.screenShareProfile, false);
        this.applyRemediations(participant, false);
      });
    }, 300);
  }

  public restoreProfiles() {
    if (this.background.isActive) {
      this.background.forceResolution = false;
      this.background.resolution = this.background.savedResolution;
    }
    setTimeout(() => {
      if (this.savedProfiles) {
        Object.keys(this.savedProfiles).forEach((participant) => {
          this.setLocalProfile(participant, this.savedProfiles[participant], false);
          this.applyRemediations(participant, false);
        });
      }
      this.savedProfiles = null;
    }, 300);
  }

  public checkProblem(problem: CallProblem, sharing = false): boolean {
    if (remediationRules[problem.message]) {
      for (const remediation of remediationRules[problem.message]) {
        switch (remediation.type) {
          case RemediationTypes.participants:
            if (remediation.value < this.participants.visibleParticipantNb) {
              this.logging.log('IssueRemediationService: Decrease nb visible participants', LoggingChannels.Remediation);
              this.participants.visibleParticipantNb = remediation.value;
            }
            break;
          case RemediationTypes.bitrate:
            if (problem.participant) {
              this.logging.log('IssueRemediationService: Decrease Bitrate', LoggingChannels.Remediation, problem.participant);
              this.logRemediation(problem.participant, 'Decrease Bitrate', this.participantRemediations.local[problem.participant], sharing);
              this.downgrade(problem.participant, sharing);
              this.display(remediationProblems[RemediationTypes.bitrate]);
            } else {
              this.checkProblems(problem, sharing);
            }
            break;
          case RemediationTypes.resolution:
            if (problem.participant) {
              this.logging.log('IssueRemediationService: Decrease Resolution', LoggingChannels.Remediation, problem.participant);
              this.logRemediation(problem.participant, 'Decrease Resolution', this.participantRemediations.local[problem.participant], sharing);
              this.downgrade(problem.participant, sharing);
              this.display(remediationProblems[RemediationTypes.resolution]);
            } else {
              this.background.downgrade();
              setTimeout(() => {
                this.checkProblems(problem, sharing);
                this.logRemediation(problem.participant, 'Decrease Background Resolution', this.participantRemediations.local[problem.participant], sharing);
              }, 300);
            }
            break;
          case RemediationTypes.remote_bitrate:
            if (!this.shareVideoTrack || !sharing) {
              if (problem.participant) {
                this.logging.log('IssueRemediationService: Remote Bitrate', LoggingChannels.Remediation, problem.participant);
                this.logRemediation(problem.participant, 'Remote Bitrate', this.participantRemediations.local[problem.participant], sharing);
                this.dataChannel.send(problem.participant, 'remediation', {
                  bitrate: remediation.value,
                  sharing,
                });
              } else {
                this.checkProblems(problem, sharing);
              }
            }
            break;
          case RemediationTypes.remote_resolution:
            if (!this.shareVideoTrack || !sharing) {
              if (problem.participant) {
                if (!this.background.isActive || this.background.resolution <= 0.75) {
                  this.logging.log('IssueRemediationService: Remote Resolution', LoggingChannels.Remediation, problem.participant);
                  this.logRemediation(problem.participant, 'Remote Resolution', this.participantRemediations.local[problem.participant], sharing);
                  this.dataChannel.send(problem.participant, 'remediation', {
                    resolution: remediation.value,
                    sharing,
                  });
                }
              } else {
                this.checkProblems(problem, sharing);
              }
            }
            break;
        }
      }

      return true;
    }

    return false;
  }

  public setMaxProfile(width: number, height: number, framerate: number, sharing = false) {
    framerate = Math.round(framerate);
    if (sharing) {
      this.shareRemediations.max.width = width;
      this.shareRemediations.max.height = height;
      this.shareRemediations.max.framerate = framerate;
    } else {
      this.participantRemediations.max.width = width;
      this.participantRemediations.max.height = height;
      this.participantRemediations.max.framerate = framerate;
      this.screenShareProfile = new RemediationProfile(253, Math.floor(253 * height / width), framerate, AudioProfileType.high);
    }
    this.logging.log('IssueRemediationService: max profile', LoggingChannels.Remediation, width, height, framerate, sharing);
    this.generateProfiles(sharing);
    this.checkLocalProfile(sharing);
  }

  private checkProblems(problem: CallProblem, sharing: boolean) {
    for (const participant of this.participants.participantsArray) {
      if (participant.id) {
        this.checkProblem(new CallProblem(participant.id, problem.message, problem.severity), sharing);
      }
    }
  }

  private checkLocalProfile(sharing: boolean) {
    let remediation: Remediation;
    if (sharing) {
      remediation = this.shareRemediations;
    } else {
      remediation = this.participantRemediations;
    }

    Object.keys(remediation.local).forEach((participant) => {
      let found = false;
      for (const profile of remediation.profiles) {
        if (profile.id === remediation.local[participant].id) {
          found = true;
        }
      }
      if (!found) {
        remediation.last[participant] = Date.now();
        this.setLocalProfile(participant, remediation.profiles[0], sharing);
      }
    });
  }

  /**
   * This function generates the dynamic remediation profiles based on the current camera settings.
   *
   * The first step of the algorithm is to generate profiles up to lowest resolutions with fps of 60, 30 and 24.
   *
   * The second step is to lower fps to 18 and then lower the audio bitrate gradually.
   */
  private generateProfiles(sharing: boolean) {
    let remediation: Remediation;
    if (sharing) {
      remediation = this.shareRemediations;
    } else {
      remediation = this.participantRemediations;
    }
    let width = remediation.max.width;
    let height = remediation.max.height;
    let framerate = remediation.max.framerate;
    remediation.profiles = [];
    const profiles = [];
    do {
      profiles.push(`${width}-${height}-${framerate}-${AudioProfileType.high}`);
      remediation.profiles.push(new RemediationProfile(width, height, framerate, AudioProfileType.high));
      if (framerate > 30) {
        framerate = 30;
      } else if (framerate > 24) {
        framerate = 24;
      } else {
        framerate = remediation.max.framerate;
        width = Math.round(width / (sharing ? 1.25 : 1.5));
        height = Math.round(height / (sharing ? 1.25 : 1.5));
      }
    } while (width >= (sharing ? 800 : 320) || height >= (sharing ? 600 : 240));
    do {
      profiles.push(`${width}-${height}-${framerate}-${AudioProfileType.high}`);
      remediation.profiles.push(new RemediationProfile(width, height, framerate, AudioProfileType.high));
      if (framerate > 30) {
        framerate = 30;
      } else if (framerate > 24) {
        framerate = 24;
      } else {
        framerate = 18;
        profiles.push(`${width}-${height}-${framerate}-${AudioProfileType.high}`);
        remediation.profiles.push(new RemediationProfile(width, height, framerate, AudioProfileType.high));
        profiles.push(`${width}-${height}-${framerate}-${AudioProfileType.good}`);
        remediation.profiles.push(new RemediationProfile(width, height, framerate, AudioProfileType.good));
        profiles.push(`${width}-${height}-${framerate}-${AudioProfileType.medium}`);
        remediation.profiles.push(new RemediationProfile(width, height, framerate, AudioProfileType.medium));
        profiles.push(`${width}-${height}-${framerate}-${AudioProfileType.low}`);
        remediation.profiles.push(new RemediationProfile(width, height, framerate, AudioProfileType.low));
      }
    } while (framerate >= 24);
    this.logging.log('IssueRemediationService: available profiles', LoggingChannels.Remediation, profiles, sharing);
    if (sharing) {
      this.logging.logCustomEvent('profiles', {
        userId: this.userId,
        roomId: this.roomId,
        profiles,
        sharing: true,
      });
    } else {
      this.logging.logCustomEvent('profiles', {
        userId: this.userId,
        roomId: this.roomId,
        profiles,
        sharing: false,
      });
    }
  }

  private setLocalProfile(participant: string, profile: RemediationProfile, sharing = false) {
    this.logging.log('IssueRemediationService: new profile', LoggingChannels.Remediation, participant, profile.id, sharing);
    if (sharing) {
      this.shareRemediations.local[participant] = profile;
    } else {
      this.participantRemediations.local[participant] = profile;
    }
    this.dataChannel.send(participant, 'remediation-profile', {
      profile: profile.id,
      sharing
    });
  }

  private logRemediation(participant: string, remediation: string, profile: RemediationProfile, sharing: boolean) {
    this.logging.logCustomEvent('remediation', {
      userId: this.userId,
      roomId: this.roomId,
      participant,
      remediation,
      profile: profile?.id || null,
      sharing,
    });
  }

  private downgrade(participant: string, sharing: boolean) {
    let remediation: Remediation;
    let forceProfile: string;
    if (sharing) {
      remediation = this.shareRemediations;
      forceProfile = window.forceShareProfile;
    } else {
      if (this.savedProfiles) {
        return;
      }
      remediation = this.participantRemediations;
      forceProfile = window.forceProfile;
    }

    if (this.featureSwitch.debugProfile && forceProfile) {
      return;
    }

    if (!remediation.profiles) {
      return;
    }

    const now = Date.now();
    if (now - remediation.last[participant] < this.downgradeTime) {
      return;
    }

    remediation.last[participant] = now;
    let found = false;
    for (const profile of remediation.profiles) {
      if (found) {
        this.setLocalProfile(participant, profile, sharing);
        this.logRemediation(participant, 'Downgrade profile', profile, sharing);
        this.logging.log('IssueRemediationService: downgrade profile', LoggingChannels.Remediation, participant, profile.id, sharing);
        this.applyRemediations(participant, sharing);
        if (profile.id === remediation.profiles[remediation.profiles.length - 1].id) {
          this.display({
            notification: true,
            message: 'problems.video',
            color: 'warning',
          });
        }
        break;
      }
      if (profile.id === remediation.local[participant].id) {
        found = true;
      }
    }
  }

  private upgrade(participant: string, sharing = false) {
    let remediation: Remediation;
    let forceProfile: string;
    if (sharing) {
      remediation = this.shareRemediations;
      forceProfile = window.forceShareProfile;
    } else {
      if (this.savedProfiles) {
        return;
      }
      remediation = this.participantRemediations;
      forceProfile = window.forceProfile;
    }

    if (this.featureSwitch.debugProfile && forceProfile) {
      return this.debugProfile(participant, sharing);
    }

    if (this.background.isActive && this.background.resolution < 0.5 && !sharing) {
      return;
    }

    if (!remediation.profiles) {
      return;
    }

    const now = Date.now();
    if (now - remediation.last[participant] < this.upgradeTime) {
      return;
    }

    remediation.last[participant] = now;
    let found = false;
    for (let i = remediation.profiles.length; i--; i >= 0) {
      const profile = remediation.profiles[i];
      if (found && profile.video.width <= remediation.max.width && profile.video.height <= remediation.max.height) {
        this.setLocalProfile(participant, profile, sharing);
        this.logRemediation(participant, 'Upgrade profile', profile, sharing);
        this.logging.log('IssueRemediationService: upgrade profile', LoggingChannels.Remediation, participant, profile.id, sharing);
        this.applyRemediations(participant, sharing);
        break;
      }
      if (profile.id === remediation.local[participant].id) {
        found = true;
      }
    }
  }

  private debugProfile(participant: string, sharing: boolean) {
    let remediation: Remediation;
    let forceProfile: string;
    if (sharing) {
      remediation = this.shareRemediations;
      forceProfile = window.forceShareProfile;
    } else {
      remediation = this.participantRemediations;
      forceProfile = window.forceProfile;
    }

    if (forceProfile !== remediation.local[participant].id) {
      for (const profile of remediation.profiles) {
        if (profile.id === forceProfile) {
          this.setLocalProfile(participant, profile, sharing);
          this.applyRemediations(participant, sharing);
        }
      }
    }
  }

  private applyRemediations(id: string, sharing: boolean) {
    let remediation: Remediation;
    const tracks: MediaStreamTrack[] = [];
    if (!this.participants.participants[id]) {
      return;
    }
    if (sharing) {
      remediation = this.shareRemediations;
      if (this.shareVideoTrack) {
        tracks.push(this.shareVideoTrack);
      }
      if (this.shareAudioTrack) {
        tracks.push(this.shareAudioTrack);
      }
    } else {
      remediation = this.participantRemediations;
      this.participants.participants[id].tracks.forEach((sender) => {
        tracks.push(sender.track);
      });
    }

    const pc = this.participants.participants[id]?.pc;
    if (pc) {
      let widthScale;
      let heightScale;
      if (this.background.isActive) {
        const canvas = document.getElementById('home-video-canvas') as HTMLCanvasElement;
        if (!canvas) {
          return;
        }
        widthScale = canvas.width / remediation.local[id].video.width;
        heightScale = canvas.height / remediation.local[id].video.height;
      } else {
        widthScale = remediation.max.width / remediation.local[id].video.width;
        heightScale = remediation.max.height / remediation.local[id].video.height;
      }
      const scale = widthScale > heightScale ? widthScale : heightScale;
      pc.getSenders().forEach((pSender) => {
        if (pSender.track && pSender.track.kind === 'video') {
          if (tracks.find((track) => track.id === pSender.track.id)) {
            const parameters = pSender.getParameters();
            if (parameters && parameters.encodings) {
              for (const encoding of parameters.encodings) {
                encoding.maxBitrate = remediation.local[id].bitrate * 1000;
                encoding.maxFrameRate = remediation.local[id].video.framerate;
                if (scale > 1) {
                  encoding.scaleResolutionDownBy = scale;
                } else {
                  encoding.scaleResolutionDownBy = 1;
                }
              }
            }
            pSender.setParameters(parameters).catch((err) => {
              this.logging.warning('IssueRemediationService: RTCRtpSender error - Setting video parameters on the sender', {
                id,
                pSender
              }, remediation.local[id].id);
            });
          }
        }
        if (pSender.track && pSender.track.kind === 'audio') {
          if (tracks.find((track) => track.id === pSender.track.id)) {
            const parameters = pSender.getParameters();
            if (parameters && parameters.encodings) {
              for (const encoding of parameters.encodings) {
                encoding.maxBitrate = remediation.local[id].audio.bitrate * 1024;
              }
            }
            pSender.setParameters(parameters).catch((err) => {
              this.logging.warning('IssueRemediationService: RTCRtpSender error - Setting audio parameters on the sender', {
                id,
                pSender
              }, remediation.local[id].id);
            });
          }
        }
      });
    }
  }

  private display(remediationNotifications: RemediationNotification) {
    if (!remediationNotifications.notification) {
      return;
    }

    this.notification.display(remediationNotifications.message, remediationNotifications.color, 'top', 5000, [{
      side: 'end',
      icon: 'close',
      role: 'cancel',
    }]);
  }

  private findNewProfile(id: string, sharing: boolean) {
    let remediation: Remediation;
    let currentProfile: RemediationProfile;
    let remoteProfile: RemediationProfile;
    if (sharing) {
      remediation = this.shareRemediations;
      currentProfile = this.shareRemediations.local[id];
      remoteProfile = this.shareRemediations.remote[id];
    } else {
      remediation = this.participantRemediations;
      currentProfile = this.participantRemediations.local[id];
      remoteProfile = this.participantRemediations.remote[id];
    }

    if (!currentProfile || !remoteProfile) {
      return;
    }

    if (
      currentProfile.video.width < remoteProfile.video.width ||
      currentProfile.video.height < remoteProfile.video.height
    ) {
      return;
    }

    for (const profile of remediation.profiles) {
      if (
        profile.video.width <= remoteProfile.video.width &&
        profile.video.height <= remoteProfile.video.height &&
        profile.video.framerate <= remoteProfile.video.framerate
      ) {
        return profile;
      }
    }
  }
}
