import { Injectable } from '@angular/core';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { environment } from 'src/environments/environment';
import { FirebaseService } from '../firebase/firebase.service';
import { Unsubscribe } from 'firebase/firestore';
import { User } from 'firebase/auth';

declare global {
  interface Window {
    trackJs: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticEventService {

  analytics: AnalyticsBrowser;
  userSub: Unsubscribe;
  orgSub: Unsubscribe;

  constructor(
    private firebase: FirebaseService,
  ) {
    this.analytics = AnalyticsBrowser.load({ writeKey: environment.segment.key, cdnURL: 'https://crana.crewdle.com' });
    this.analytics.ready(() => {
      if (window.trackJs) {
        window.trackJs.configure({ version: environment.version });
      }
    });
    this.getAuthUser();
  }

  public logPage(): void {
    this.analytics.page();
  }

  public logEvent(eventType: string): void {
    this.analytics.track(eventType);
  }

  private resetUser(): void {
    this.analytics.reset();
    if (this.userSub) {
      this.userSub();
      this.userSub = null;
    }
    if (this.orgSub) {
      this.orgSub();
      this.orgSub = null;
    }
  }

  private logUser(id: string, email: string, name: string, createdAt: string): void {
    this.analytics.identify(id, {
      email,
      name,
      createdAt,
    });
  }

  private logCompany(id: string, name: string): void {
    this.analytics.group(id, {
      name,
    });
  }

  private getAuthUser(): void {
    this.firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser && !authUser.isAnonymous) {
        this.getUserData(authUser);
      } else {
        this.resetUser();
      }
    });
  }

  private getUserData(authUser: User): void {
    if (this.userSub) {
      this.userSub();
      this.userSub = null;
    }
    this.userSub = this.firebase.firestore.docListen(`users/${authUser.uid}`, (userDoc) => {
      if (userDoc.exists()) {
        const user: any = userDoc.data();
        if (user) {
          const createdAt = new Date(authUser.metadata.creationTime).toISOString();
          this.logUser(authUser.uid, user.email, user.name, createdAt);
          if (user.organization) {
            this.getOrgData(user.organization);
          } else if (this.orgSub) {
            this.orgSub();
            this.orgSub = null;
          }
        }
      }
    });
  }

  private getOrgData(orgId: string) {
    if (this.orgSub) {
      this.orgSub();
      this.orgSub = null;
    }
    this.orgSub = this.firebase.firestore.docListen(`organizations/${orgId}`, (orgDoc) => {
      if (orgDoc.exists()) {
        const org: any = orgDoc.data();
        this.logCompany(orgId, org.name);
      }
    });
  }
}
