import { Injectable } from '@angular/core';
import {
  DocumentData, limit, orderBy, QueryConstraint, QueryDocumentSnapshot, QuerySnapshot, serverTimestamp, startAfter, Unsubscribe, where
} from 'firebase/firestore';

import { BehaviorSubject, Subject } from 'rxjs';

import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { UserService } from 'src/app/services/user/user.service';

import { FirebaseSubjectListener, createFirebaseSubjectListener } from 'src/app/models/firebase-listener/firebase-listener.model';
import { ConversationList, Conversation, ConversationUpdater, Message, ConversationCategory } from './models';
import { ContactsService } from 'src/app/services/contacts/contacts.service';
import { NewRoomService } from 'src/app/services/new-room/new-room.service';
import { LoggingChannels, LoggingService } from 'src/app/services/logging/logging.service';
import { MessagingParticipantService } from 'src/app/services/messaging/participant/messaging-participant.service';


declare global {
  interface Window {
    messagingService: any;
    ms: any;
  }
}

// Create a message service that retrieves a list of conversation from a firebase real-time database
@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  public readonly MESSAGES_PAGE_SIZE = 20;

  constructor(
    private contactsService: ContactsService,
    private firebase: FirebaseService,
    private newRoomService: NewRoomService,
    private logging: LoggingService,
    private participantService: MessagingParticipantService,
    private user: UserService,
  ) {}

  public async fetchOrCreateConversation(participants: string[], conversationName?: string): Promise<string> {
    const {
    conversationId,
    isNewConversation,
    conversationName: name,
    participants: userIds,
    error = null
    } = await this.firebase.functions.call('fetchOrCreateConversation', {
    participants, conversationName
    });

    if (error) {
      throw new Error(error);
    }

    if (isNewConversation) {
      const roomId = await this.createNewVideoRoom({name, userIds});
      const audioRoomId = await this.createNewAudioRoom({name, userIds});
      this.firebase.firestore.docUpdate(`conversations/${conversationId}`, {roomId, audioRoomId});
    }

    return conversationId;
  }

  // Create a conversation in firestore at /conversations/{conversationId}
  public async createConversation(conversation: Partial<Conversation>): Promise<Conversation['id']> {
    const { roomId, audioRoomId = '' } = await this.getRoomIds(conversation);
    const conversationRef = await this.firebase.firestore.docAdd('conversations', {
      // Defaults
      userIds: [this.user.user.uid],
      category: 'personal',
      closed: false,

      // Overrides
      ...conversation,
      parentId: this.user.user.uid,
      roomId,
      audioRoomId,
      createdOn: serverTimestamp(),
      updatedOn: serverTimestamp(),
    });

    const participants = conversation.userIds.map((userId) => ({
      userId,
      lastMessageRead: null,
      isTyping: false,
    }));

    await this.participantService.addParticipants(conversationRef.id, participants);

    return conversationRef.id;
  }

  public updateConversation(conversationId: Conversation['id'], conversation: Partial<Conversation>, currentConversation: Partial<Conversation> = {}) {
    const updater: Partial<ConversationUpdater> = {
      ...Object.keys(conversation).filter((key) => key !== 'createdOn').reduce((acc, key) => ({ ...acc, [key]: conversation[key] }), {}),
      updatedOn: serverTimestamp(),
    };

    if (conversation.userIds) {
      updater.userIds = conversation.userIds;

      const participants = conversation.userIds
        .filter((userId) => !currentConversation.userIds.includes(userId))
        .map((userId) => ({
          userId,
          lastMessageRead: null,
          isTyping: false,
        }));

      this.participantService.addParticipants(conversationId, participants);
    }

    return this.firebase.firestore.docUpdate(`conversations/${conversationId}`, updater);
  }

  public async sendMessage(conversationId: Conversation['id'], message: Partial<Message>): Promise<Message['id']> {
    const newMessage = {
      createdOn: serverTimestamp(),
      updatedOn: serverTimestamp(),
      sender: this.user.user.uid,
      ...message,
    };

    const messageRef = await this.firebase.firestore.docAdd(`conversations/${conversationId}/messages`, newMessage);

    this.firebase.firestore.docUpdate(`conversations/${conversationId}`, {
      lastMessage: { id: messageRef.id, ...newMessage },
      updatedOn: serverTimestamp(),
    });

    return messageRef.id;
  }

  // Get all conversations for a user from firestore at /conversations/ where participants contains the user's id
  public listenConversations(specificUserId?: string, category: ConversationCategory = 'personal'): FirebaseSubjectListener<Subject<ConversationList>> {
    const userId = specificUserId || this.user.user.uid;

    const subject = new Subject<ConversationList>();
    const unsubscribe = this.firebase.firestore.collectionListen('conversations', [
      where('userIds', 'array-contains', userId),
      where('category', '==', category),
      orderBy('updatedOn', 'desc'),
      limit(15),
    ], async (snapshots) => {
      const conversationPromises: Promise<Partial<Conversation>>[] = [];

      // MAYBE - Should we recompute the whole conversation list on every update?
      // On one side, everything is cached on the firestore side and should not require a full http request
      // for every resource. On the other side, we might be able to optimize the data structure to avoid
      // having to recompute the whole list on every update.
      snapshots.forEach(snapshot => {
        const conversation = this.mapConversationForList(snapshot.id, snapshot.data());
        conversationPromises.push(conversation);
      });

      const conversationList = await Promise.all(conversationPromises);
      subject.next(conversationList);
    });

    return createFirebaseSubjectListener(subject, unsubscribe);
  }

  public async fetchConversations(conversation: Partial<Conversation> = null, category: ConversationCategory = 'personal'): Promise<ConversationList> {
    const userId = this.user.user.uid;

    const cursor = conversation ? this.firebase.firestore.dateToTimestamp(new Date(conversation.updatedOn)) : null;
    const query: QueryConstraint[] = [
      where('userIds', 'array-contains', userId),
      where('category', '==', category),
      orderBy('updatedOn', 'desc'),
      cursor ? startAfter(cursor) : null,
      limit(15),
    ].filter(constraint => constraint != null);

    const conversationDocs = await this.firebase.firestore.collectionGet('conversations', ...query);
    const conversationPromises: Promise<Partial<Conversation>>[] = [];
    conversationDocs.forEach(async doc => {
      const conversationItem = this.mapConversationForList(doc.id, doc.data());
      conversationPromises.push(conversationItem);
    });

    const conversations = await Promise.all(conversationPromises);
    return conversations;
  }

  public fetchConversation(conversationId: string): FirebaseSubjectListener<Subject<Conversation>> {
    const subject = new BehaviorSubject<Conversation>(null);
    const unsubscribe = this.firebase.firestore.docListen(`conversations/${conversationId}`, async (snapshot) => {
      const conversation = await this.mapConversation(snapshot.id, snapshot.data());

      this.logging.log('MessagingService: Conversation Update', LoggingChannels.Conversation, conversation);
      subject.next(conversation);
    });

    return createFirebaseSubjectListener(subject, unsubscribe);
  }

  public listenToMessages(conversationId: string): FirebaseSubjectListener<Subject<Message[]>> {
    const subject = new Subject<Message[]>();
    const unsubscribe = this.firebase.firestore.collectionListen(
      `conversations/${conversationId}/messages`,
      [orderBy('createdOn', 'desc'), limit(10)],
      (snapshots) => {
        const docsData = this.getDocsData(snapshots.docs);
        const messages = this.mapMessages(docsData);

        subject.next(messages.reverse());
      }
    );

    return createFirebaseSubjectListener(subject, unsubscribe);
  }

  public async fetchMessages(conversationId: string, message: Message = null): Promise<Message[]> {

    const cursor = message ? this.firebase.firestore.dateToTimestamp(new Date(message.createdOn)) : null;
    const query: QueryConstraint[] = [
      orderBy('createdOn', 'desc'),
      cursor ? startAfter(cursor) : null,
      limit(this.MESSAGES_PAGE_SIZE),
    ].filter(constraint => constraint != null);

    const messageDocs = await this.firebase.firestore.collectionGet(`conversations/${conversationId}/messages`, ...query);
    const docsData = this.getDocsData(messageDocs.docs);
    return this.mapMessages(docsData).reverse();
  }

  public async updateLastMessageRead(conversationId: string, messageId: string, participantId: string) {
    this.participantService.updateParticipant(conversationId, participantId, { lastMessageRead: messageId });
  }

  public renameConversation(conversationId: string, name: string) {
    this.firebase.firestore.docUpdate(`conversations/${conversationId}`, { name });
  }

  public async getMessagesCountAfter(conversationId: string, messageId: string): Promise<number> {
    const messageDocs = await this.getMessagesAfter(conversationId, messageId);

    return messageDocs.size;
  }

  public async fetchLastReadMessageId(conversationId: string, userId: string): Promise<string> {
    const participantDoc = await this.firebase.firestore.docGet(`conversations/${conversationId}/participants/${userId}`);

    return participantDoc.data()?.lastMessageRead;
  }

  public async getLastMessagesWithLimit(conversationId: string, takeNum: number): Promise<QuerySnapshot<DocumentData>> {
    const messageDocs = await this.firebase.firestore.collectionGet(`conversations/${conversationId}/messages`, orderBy('createdOn', 'desc'), limit(takeNum));

    return messageDocs;
  }

  public async findConversationWithParticipant(contactId: string, category: ConversationCategory = 'personal'): Promise<string> {
    const conversations = await this.firebase.firestore.collectionGet(
      'conversations',
      where('userIds', 'array-contains', this.user.user.uid),
      where('category', '==', category),
    );

    const conversation = conversations.docs.find(doc => doc.data().userIds.includes(contactId) && doc.data().userIds.length === 2);

    if (conversation && conversation.id) {
      return conversation.id;
    }

    return this.createConversation({ userIds: [this.user.user.uid, contactId] });
  }

  public async deleteConversation(conversationId: string): Promise<void> {
    await this.firebase.firestore.docDelete(`conversations/${conversationId}`);
  }

  private async getMessagesAfter(conversationId: string, messageId: string): Promise<QuerySnapshot<DocumentData> | { size: number }> {
    const messageSnapshot = await this.firebase.firestore.docGet(`conversations/${conversationId}/messages/${messageId}`);

    try {

      return await this.firebase.firestore.collectionGet(
        `conversations/${conversationId}/messages`,
        orderBy('createdOn'),
        startAfter(messageSnapshot),
        limit(10),
      );

    } catch (e) {
      console.error('Error getting messages after', e, messageSnapshot, conversationId, messageId);
      return {
        size: 0,
      };
    }
  }

  private async mapConversationForList(id: string, conversation: any): Promise<Partial<Conversation>> {
    const {
      parentId,
      name: currentName,
      roomId = null,
      audioRoomId = null,
      lastMessage: lastMessageObject = {},
      newMessageCount = 0,
      userIds = [],
      createdOn: createdOnTimestamp,
      updatedOn: maybeUpdated,
    } = conversation;
    const lastMessage = this.mapMessage(lastMessageObject);

    const name = currentName || await this.contactsService.getDefaultConversationName(userIds);
    const createdOn = this.firebase.firestore.timestampToDate(createdOnTimestamp).getTime();
    const updatedOn = maybeUpdated ? this.firebase.firestore.timestampToDate(maybeUpdated).getTime() : Date.now();

    return {
      id,
      parentId,
      roomId,
      audioRoomId,
      name,
      userIds,
      lastMessage,
      newMessageCount,
      createdOn,
      updatedOn,
    };
  }

  private async mapConversation(id: string, conversation: any): Promise<Conversation> {
    return {
      // Defaults
      userIds: [],
      files: [],
      lastMessageId: null,
      messagesRead: [],
      newMessageCount: 0,

      // Overrides
      ...conversation,
      id,
      name: conversation.name || '',
      participants: [],
      messages: [],
    };
  }

  private getDocsData(docs: QueryDocumentSnapshot[]): any[] {
    return docs.map(doc => ({id: doc.id, ...doc.data()}));
  }

  private mapMessage(message: any): Message {
    const timestamps = {
      createdOn: Date.now(),
      updatedOn: Date.now(),
    };

    if (message?.createdOn) {
      timestamps.createdOn = this.firebase.firestore.timestampToDate(message.createdOn).getTime();
    }

    if (message?.updatedOn) {
      timestamps.updatedOn = this.firebase.firestore.timestampToDate(message.updatedOn).getTime();
    }

    return {
      id: message.id || null,
      sender: message.sender || null,
      content: message.content || '',
      ...timestamps,
    };
  }

  private mapMessages(messages: any[]): Message[] {
    return messages.map(message => this.mapMessage(message));
  }

  private async createNewVideoRoom(conversation: Partial<Conversation>): Promise<string> {
    const name = conversation.name || await this.contactsService.getDefaultConversationName(conversation.userIds);
    const { roomId } = await this.newRoomService.createNewRoom(name, {});

    return roomId;
  }

    private async createNewAudioRoom(conversation: Partial<Conversation>): Promise<string> {
      const name = conversation.name || await this.contactsService.getDefaultConversationName(conversation.userIds);
      const { roomId } = await this.newRoomService.createNewRoom(name, {audioOnly: true});

      return roomId;
  }

  private async getRoomIds(conversation: Partial<Conversation>): Promise<{ roomId: string; audioRoomId: string }> {
    if (conversation.roomId) {
      return {
        roomId: conversation.roomId,
        audioRoomId: conversation.audioRoomId,
      };
    }

    const roomId = await this.createNewVideoRoom(conversation);
    const audioRoomId = await this.createNewAudioRoom(conversation);


    return { roomId, audioRoomId };
  }
}
