import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonSlides} from '@ionic/angular';

@Component({
  selector: 'app-hidden-participants-warning',
  templateUrl: './hidden-participants-warning.component.html',
  styleUrls: ['./hidden-participants-warning.component.scss'],
})
export class HiddenParticipantsWarningComponent implements OnInit {
  @ViewChild('slides')  slides: IonSlides;
  @Input() visibleParticipantNb: number;
  isEndOfSlides = false;
  slideOpts = {
    initialSlide: 0
  };
  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  ionViewWillEnter() {
    this.slideOpts = {
      initialSlide: 0
    };
  }

  public dismissModal() {
    this.modalCtrl.dismiss();
  }

  public ionSlideChanged(slides){
    slides.getActiveIndex().then(
      (slidesIndex)=>{
        if(slidesIndex === 1){
          this.isEndOfSlides = true;
        }else{
          this.isEndOfSlides = false;
        }
      }
    );
  }

  public swipeNext(){
    this.slides.slideNext();
  }

  public swipePrevious(){
    this.slides.slidePrev();
  }
}
