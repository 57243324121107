export class Organization {
  id: string;
  name: string;
  active: boolean;
  ownerId: string;
  subscription: string;
  memberSeats: number;
  stripeCustomerId: string;
  stripeSubscriptionId: string;
  stripeItemId: string;
  marketplace: any;
}
