// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '3.1.5.0',
  baseUrl: 'https://stg.crewdle.com/',
  maxParticipants: 100,
  firebaseConfig: {
    apiKey: 'AIzaSyCgss6fKMPsdzIWXP_EWZ28gfzKtFqMjBk',
    authDomain: 'crewdle-app-stg.firebaseapp.com',
    databaseURL: 'https://crewdle-app-stg-default-rtdb.firebaseio.com',
    projectId: 'crewdle-app-stg',
    storageBucket: 'crewdle-app-stg.appspot.com',
    appId: '1:278783368371:web:ee3c3dddcf11fef6d8a4c6',
    androidId:
      '1079176422251-q1uqorvhvgu03ooqqscv9gil2d4j873o.apps.googleusercontent.com',
    measurementId: 'G-9RVRE4LERE',
  },
  recaptcha: {
    recaptchaSiteKey: '6LejBbccAAAAAPIhLWXqmS8YI0qPIGhx7NdyC-7r',
  },
  segment: {
    key: 'Hej6ui3Rw7pMkff94f6kRVfZff6fGp0E',
  },
  agora: {
    appId: '9611917d9ec64315ae6c3d4b5cfc4909',
  },
  datadog: {
    appId: '9b7673c8-7da7-45d9-ba45-c4b29378b70d',
    token: 'pub12f6369ccd7f8b17b19bb49984c0eacf',
  },
  aws: {
    endpoint: 'https://t7yyufa7yjhshkq4ssc6xfvnke.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
    authType: 'OPENID_CONNECT',
  },
  cello: {
    productId: 'stage-app.crewdle.com',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
