import { Blur, BlurGpuContext } from './blur';

const blurVertShader = `
attribute vec2 c;

void main(void) {
  gl_Position=vec4(c, 0.0, 1.0);
}
`;

const blurFragShader = `
precision lowp float;
uniform sampler2D texture0;
uniform float texWidth;
uniform float texHeight;
uniform float u_sigma;
uniform float u_direction;
uniform float u_weights[32];

vec4 blurH(vec2 texCoord, sampler2D texture, float sigma) {
  vec2 texC = texCoord;
  vec4 texCol = texture2D(texture, texC);

  vec4 gaussCol = texCol;
  vec2 var = vec2(1.0, 0.0) / texWidth;
  float sumWeight = 1.0;
  for (int i = 1; i <= 32; ++i) {
    float weight = u_weights[i - 1];
    if (weight < 1.0 / 255.0)
      break;
    texCol = texture2D(texture, texC + var * float(i));
    gaussCol += texCol * weight;
    sumWeight += weight;
    texCol = texture2D(texture, texC - var * float(i));
    gaussCol += texCol * weight;
    sumWeight += weight;
  }

  gaussCol = clamp(gaussCol / sumWeight, 0.0, 1.0);
  return gaussCol;
}

vec4 blurV(vec2 texCoord, sampler2D texture, float sigma) {
  vec2 texC = texCoord;
  vec4 texRow = texture2D(texture, texC);

  vec4 gaussRow = texRow;
  vec2 var = vec2(0.0, 1.0) / texHeight;
  float sumWeight = 1.0;
  for (int i = 1; i <= 32; ++i) {
    float weight = u_weights[i - 1];
    if (weight < 1.0 / 255.0)
      break;
    texRow = texture2D(texture, texC + var * float(i));
    gaussRow += texRow * weight;
    sumWeight += weight;
    texRow = texture2D(texture, texC - var * float(i));
    gaussRow += texRow * weight;
    sumWeight += weight;
  }

  gaussRow = clamp(gaussRow / sumWeight, 0.0, 1.0);
  return gaussRow;
}

void main(void) {
  vec2 texCoord = vec2(gl_FragCoord.x / texWidth, 1.0 - (gl_FragCoord.y / texHeight));
  if (u_direction == 0.0) {
    vec4 blur0 = blurH(texCoord, texture0, u_sigma);
    gl_FragColor = blur0;
  } else {
    vec4 blur1 = blurV(texCoord, texture0, u_sigma);
    gl_FragColor = blur1;
  }
}
`;

const blurAmount = 0.2;
const blurFilters = {
  radius2: [
    { sigma: blurAmount, direction: 0.0 },
    { sigma: blurAmount, direction: 1.0 },
  ],
  radius4: [
    { sigma: blurAmount, direction: 0.0 },
    { sigma: blurAmount, direction: 1.0 },
    { sigma: blurAmount, direction: 0.0 },
    { sigma: blurAmount, direction: 1.0 },
  ],
  radius6: [
    { sigma: blurAmount, direction: 0.0 },
    { sigma: blurAmount, direction: 1.0 },
    { sigma: blurAmount, direction: 0.0 },
    { sigma: blurAmount, direction: 1.0 },
    { sigma: blurAmount, direction: 0.0 },
    { sigma: blurAmount, direction: 1.0 },
  ],
  radius8: [
    { sigma: blurAmount, direction: 0.0 },
    { sigma: blurAmount, direction: 1.0 },
    { sigma: blurAmount, direction: 0.0 },
    { sigma: blurAmount, direction: 1.0 },
    { sigma: blurAmount, direction: 0.0 },
    { sigma: blurAmount, direction: 1.0 },
    { sigma: blurAmount, direction: 0.0 },
    { sigma: blurAmount, direction: 1.0 },
  ]
};

interface BlurGpuFilter {
  sigma: number;
  direction: number;
}

export class BlurGpu implements Blur {
  public contexts: { [key: string]: BlurGpuContext } = {};

  public blurMask(imageData: ImageData) {
    this.blur('mask', imageData, 2);
  }

  public blur(name: string, imageData: ImageData, radius: number) {
    const frameWidth = imageData.width;
    const frameHeight = imageData.height;
    const radiusKey = `radius${radius}`;
    const gl = this.contexts[name].ctx;

    if (this.contexts[name].lastWidth !== frameWidth) {
      gl.bindTexture(gl.TEXTURE_2D, this.contexts[name].texfb2);
      gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, frameWidth, frameHeight, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

      gl.bindFramebuffer(gl.FRAMEBUFFER, this.contexts[name].fb1);
      gl.framebufferTexture2D(
        gl.FRAMEBUFFER,
        gl.COLOR_ATTACHMENT0,
        gl.TEXTURE_2D,
        this.contexts[name].texfb2,
        0);
      this.contexts[name].lastWidth = frameWidth;
    }

    let currentFrameBuffer = this.contexts[name].fb1;
    let currentTexture = this.contexts[name].texfb1;
    for (let b = 0; b < blurFilters[radiusKey].length; b++) {
      const filter = blurFilters[radiusKey][b] as BlurGpuFilter;
      gl.bindFramebuffer(gl.FRAMEBUFFER, currentFrameBuffer);
      gl.viewport(0, 0, frameWidth, frameHeight);
      gl.bindTexture(gl.TEXTURE_2D, currentTexture);
      if (b === 0) {
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, frameWidth, frameHeight, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
        gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, frameWidth, frameHeight, gl.RGBA, gl.UNSIGNED_BYTE, imageData.data);
      }
      gl.uniform1f(this.contexts[name].texWidthLoc, frameWidth);
      gl.uniform1f(this.contexts[name].texHeightLoc, frameHeight);
      gl.uniform1f(this.contexts[name].sigmaLoc, filter.sigma);
      gl.uniform1f(this.contexts[name].directionLoc, filter.direction);
      if (blurFilters[radiusKey][b + 1]) {
        if (gl.getParameter(gl.FRAMEBUFFER_BINDING) === this.contexts[name].fb1) {
          currentFrameBuffer = this.contexts[name].fb2;
          currentTexture = this.contexts[name].texfb2;
        } else {
          currentFrameBuffer = this.contexts[name].fb1;
          currentTexture = this.contexts[name].texfb1;
        }
        gl.bindFramebuffer(gl.FRAMEBUFFER, currentFrameBuffer);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, currentTexture, 0);
        gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
      } else {
        if (blurFilters[radiusKey].length % 2 === 0) {
          gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);
        }
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
      }
    }
  }

  public initialize(name: string) {
    this.contexts[name] = {} as BlurGpuContext;
    const canvas = this.contexts[name].canvas = document.createElement('canvas');
    const gl = this.contexts[name].ctx = canvas.getContext('webgl2');

    const vs = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(vs, blurVertShader);
    gl.compileShader(vs);

    const fs = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fs, blurFragShader);
    gl.compileShader(fs);

    const prog = gl.createProgram();

    gl.attachShader(prog, vs);
    gl.attachShader(prog, fs);
    gl.linkProgram(prog);
    gl.useProgram(prog);

    const vb = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vb);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([-1, 1, -1, -1, 1, -1, 1, 1]), gl.STATIC_DRAW);

    const coordLoc = gl.getAttribLocation(prog, 'c');
    gl.vertexAttribPointer(coordLoc, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(coordLoc);

    gl.activeTexture(gl.TEXTURE0);
    const tex = this.contexts[name].texfb1 = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, tex);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

    gl.disable(gl.DEPTH_TEST);
    gl.enable(gl.CULL_FACE);

    const weights = [];
    for (let i = 1; i <= 32; i++) {
      const weight = this.calcGauss(i / 32, blurAmount * 0.5);
      if (weight < 1.0 / 255.0) {
        break;
      }
      weights.push(weight);
    }
    const weightsLoc = gl.getUniformLocation(prog, 'u_weights');
    gl.uniform1fv(weightsLoc, weights);

    this.contexts[name].texWidthLoc = gl.getUniformLocation(prog, 'texWidth');
    this.contexts[name].texHeightLoc = gl.getUniformLocation(prog, 'texHeight');
    this.contexts[name].sigmaLoc = gl.getUniformLocation(prog, 'u_sigma');
    this.contexts[name].directionLoc = gl.getUniformLocation(prog, 'u_direction');

    this.contexts[name].fb1 = gl.createFramebuffer();
    this.contexts[name].fb2 = gl.createFramebuffer();
    this.contexts[name].texfb2 = gl.createTexture();
  }

  private calcGauss(x: number, sigma: number) {
    return Math.exp(-0.5 * (x * x) / (sigma * sigma));
  }
}
