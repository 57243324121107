import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { User } from 'firebase/auth';
import { FirebaseService } from './services/firebase/firebase.service';

export type AuthPipeGenerator = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => any;

export const loggedIn: any = user => !!user;

@Injectable({
  providedIn: 'any'
})
export class AngularFireAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private firebase: FirebaseService,
  ) {}

  canActivate = async (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authPipeFactory = next.data.authGuardPipe as AuthPipeGenerator || (() => loggedIn);
    const user = await this.retrieveUser();
    const can = authPipeFactory(next, state)(user);
    if (typeof can === 'boolean') {
      return can;
    } else if (Array.isArray(can)) {
      return this.router.createUrlTree(can);
    } else {
      return this.router.parseUrl(can);
    }
  };

  retrieveUser(): Promise<User> {
    return new Promise((resolve, reject) => {
      const sub = this.firebase.auth.onAuthStateChanged((user) => {
        sub();
        resolve(user);
      }, () => {
        reject(null);
      });
    });
  }
}


export const redirectUnauthorizedTo: (redirect: string|any[]) => any =
  (redirect) => (user) => loggedIn(user) || redirect;
export const redirectLoggedInTo: (redirect: string|any[]) => any =
  (redirect) => (user) => loggedIn(user) && redirect || true;
