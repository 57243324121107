import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ForceUpdateService {

  ignoreUpdate: boolean;
  delayUpdate: boolean;

  constructor(
    private router: Router,
  ) {
    this.ignoreUpdate = false;
    this.delayUpdate = false;
  }

  public requiredUpdate(currentVersion: any, minimumVersion: any): boolean {
    currentVersion = currentVersion.split('.');
    minimumVersion = minimumVersion.split('.');
    if (currentVersion[0] === 'dev') {
      return false;
    }

    let needUpdate = true;
    if (currentVersion[0] > minimumVersion[0]) {
      needUpdate = false;
    } else if (currentVersion[1] > minimumVersion[1]) {
      needUpdate = false;
    } else if (currentVersion[2] > minimumVersion[2]) {
      needUpdate = false;
    } else if (currentVersion.length === 3 && currentVersion[2] === minimumVersion[2]) {
      // For production
      needUpdate = false;
    } else if (currentVersion.length > 3 && currentVersion[3] >= minimumVersion[3]) {
      // For staging and dev
      needUpdate = false;
    }

    if (this.ignoreUpdate && needUpdate) {
      this.delayUpdate = true;
      needUpdate = false;
    }

    return needUpdate;
  }

  public startIgnore() {
    this.ignoreUpdate = true;
  }

  public stopIgnore() {
    this.ignoreUpdate = false;
    if (this.delayUpdate) {
      this.delayUpdate = false;
      setTimeout(() => {
        this.router.navigate(['/upgrade']);
      }, 1000);
    }
  }
}
