import { AudioStream } from '../audio-stream/audio-stream';

export class AudioStreamInitializer {
  public static readonly AUDIO_STREAM_TERMINATED_EVENT = 'Audio Stream terminated';

  stream: MediaStream;
  volume: number;
  loaded: boolean;
  connected: boolean;

  private audioStream: AudioStream;
  private onVolumeHandler: CallableFunction;

  private loadingPromise: Promise<string>;
  private loadingSuccess: CallableFunction;
  private loadingError: CallableFunction;
  private sampleSize?: number|null;

  constructor(
    onVolumeHandler: CallableFunction,
    stream: MediaStream,
    sampleSize?: number|null,
  ) {
    this.stream = stream;
    this.volume = 0;
    this.loaded = false;
    this.connected = false;
    this.onVolumeHandler = onVolumeHandler;
    this.sampleSize = sampleSize;

    this.initPromise();
  }

  async load() {
    if (this.loaded) {
      return;
    }

    this.initPromise();
    this.loaded = true;

    if (!this.audioStream) {
      this.initAudioStream();
    }

    try {
      await this.audioStream.load();

      // Make sure we abort proceeding with the loading sequence if it was restarted midway
      if (!this.audioStream || !this.audioStream.getVolumeObserver()) {
        throw AudioStreamInitializer.AUDIO_STREAM_TERMINATED_EVENT;
      }

      this.audioStream.getVolumeObserver().subscribe(volumeInputs => {

        try {

          const avgVolume = volumeInputs.reduce((acc, volume) => acc + volume, 0.0) / volumeInputs.length;
          this.onVolumeHandler(avgVolume, volumeInputs);
        } catch (err) {
          console.error('AudioStreamInitializer: Error processing volume inputs', volumeInputs, err);
        }
      });

      this.loadingSuccess('before volume observer');

    } catch (err) {
      if (err === AudioStreamInitializer.AUDIO_STREAM_TERMINATED_EVENT) {
        return;
      }

      console.error('AudioStreamInitializer: Error loading the audio stream', err);
      this.loadingError(err);
    }
  }

  connect() {
    if (this.connected) {
      return;
    }

    this.connected = true;
    this.loadingPromise
      .then(() => {
        this.audioStream.connect();
      })
      .catch(err => {
        if (err === AudioStreamInitializer.AUDIO_STREAM_TERMINATED_EVENT) {
          return;
        }
        console.error('AudioStreamInitializer: Error connecting the audio stream', err);
      });
  }

  unload() {
    this.loaded = false;
    if (!this.audioStream) {
      return;
    }

    this.audioStream.unload();
    delete this.audioStream;

    if (this.loadingPromise) {
      delete this.loadingPromise;
      delete this.loadingError;
      delete this.loadingSuccess;
    }
  }

  disconnect() {
    this.connected = false;
    if (!this.audioStream) {
      return;
    }

    this.audioStream.disconnect();
    if (this.loadingPromise) {
      this.loadingError(AudioStreamInitializer.AUDIO_STREAM_TERMINATED_EVENT);
    }
  }

  restart() {
    this.disconnect();
    this.unload();
    this.load();
    this.connect();
  }

  getUnbufferredVolumeObserver() {
    return this.audioStream.getUnbufferredVolumeObserver();
  }

  private initAudioStream() {
    this.audioStream = new AudioStream(
      this.stream,
      this.sampleSize,
    );
  }

  private initPromise() {
    if (this.loadingPromise) {
      return;
    }

    this.loadingPromise = new Promise((resolve, reject) => {
      this.loadingSuccess = resolve;
      this.loadingError = reject;
    });
  }
}
